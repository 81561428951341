import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './events-overview-item.css';
import { useData } from '../contexts/DataContext';

const EventsOverviewItem = (props) => {
  const { userData } = useData();
  
  const id = props.id;
  const monitor = userData?.eventMonitors[id] || {};
  const status = monitor.status;

  const totalFound = monitor.totalFound || 0;
  const eventsCount = monitor.events ? Object.keys(monitor.events).length : 0;
  const eventsPercentage = totalFound > 0 ? (eventsCount / totalFound) * 100 : 0;
  
  let contacts = []

  if (monitor.events) {
    Object.keys(monitor.events).forEach((key) => {
      if(monitor.events[key].scan && monitor.events[key].joined){
        contacts = [...monitor.events[key].contacts, ...contacts];
      }
    });
  }

  return (
    <div className={`events-overview-item-monitor-overview-item ${props.rootClassName} `}>
      <div className="events-overview-item-container">
        <span className="events-overview-item-text">{monitor.name || '-'}</span>
        <div className="events-overview-item-container01">
          <span className="events-overview-item-text01">ID: </span>
          <span className="events-overview-item-text02">{id}</span>
        </div>
      </div>
      <div className="events-overview-item-container02">
        <div className="events-overview-item-container03">
          <span className="events-overview-item-text03">Events: </span>
          <span className="events-overview-item-text04">{eventsCount}</span>
        </div>
        <div className="events-overview-item-container04">
          <span className="events-overview-item-text05">Total Events: </span>
          <span className="events-overview-item-text06">{totalFound}</span>
        </div>
      </div>
      <div className="events-overview-item-container05">
        <div className="events-overview-item-container06">
          <span className="events-overview-item-text07">Process: </span>
          <span className="events-overview-item-text08">{eventsPercentage.toFixed(2)+'%'}</span>
        </div>
        <div className="events-overview-item-container07">
          <div style={{width: `${eventsPercentage}%`}} className="events-overview-item-container08 bg-gradient"></div>
        </div>
      </div>
      <div className="events-overview-item-status">
        {status === 1 && (
          <div className="events-overview-item-container09">
            <div className="events-overview-item-container10"></div>
            <span className="events-overview-item-text09">Running</span>
          </div>
        )}
        {status === 2 && (
          <div className="events-overview-item-container11">
            <div className="events-overview-item-container12"></div>
            <span className="events-overview-item-text10">Initialization</span>
          </div>
        )}
        {status === 0 && (
          <div className="events-overview-item-container13">
            <div className="events-overview-item-container14"></div>
            <span className="events-overview-item-text11">Stop</span>
          </div>
        )}
        {status === 3 && (
          <div className="events-overview-item-container15">
            <div className="events-overview-item-container16"></div>
            <span className="events-overview-item-text12">Error</span>
          </div>
        )}
        <Link to={`/events-details?id=${id}`} className="events-overview-item-navlink">
          <div className="events-overview-item-container17">
            <svg viewBox="0 0 1024 1024" className="events-overview-item-icon">
              <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  )
}

EventsOverviewItem.defaultProps = {
  rootClassName: '',
  process: '100%',
  id: '780183-bfx32',
  listName: '1354',
  eventName: 'Event Name',
  events: '724',
}

EventsOverviewItem.propTypes = {
  rootClassName: PropTypes.string,
  process: PropTypes.string,
  id: PropTypes.string,
  listName: PropTypes.string,
  eventName: PropTypes.string,
  events: PropTypes.string,
}

export default EventsOverviewItem;
