import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'; // oder 'react-router' je nach Version
import CheckBox from './check-box';
import ChannelCreatGroupContactsItem from './channel-creat-group-contacts-item';
import './create-channel-group.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const CreateChannelGroup = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory(); // Hook für die Navigation
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_groupId = queryParams.get('id');

  const notificationContacts = Object.keys(userData?.notificationContacts || {}) || [];
  const [groupName, setGroupName] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);

  useEffect(() => {
    if (query_groupId && userData.channelGroups && userData.channelGroups[query_groupId]) {
      const groupData = userData.channelGroups[query_groupId];
      setGroupName(groupData.name);
      setSelectedContacts(groupData.contacts);
    }
  }, [userData, query_groupId]);

  const handleToggleContact = (id) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((contactId) => contactId !== id)
        : [...prevSelected, id]
    );
  };

  const handleUpload = () => {
    if (!groupName || selectedContacts.length === 0) {
      if (!groupName) {
        notify('The group name is required. Please provide a name for the group before proceeding.', 3);
      }

      if (selectedContacts.length === 0) {
        if (notificationContacts.length === 0) {
          notify('No contacts have been created yet. Please create at least one contact.', 2);
        } else {
          notify('Contacts are required. Please select at least one contact before proceeding.', 3);
        }
      }
      return;
    }

    const newGroupData = {
      name: groupName,
      contacts: selectedContacts,
      created: Date.now(),
      notificationCount: 0
    };

    if (query_groupId) {
      // Update existing group
      uploadData(`channelGroups/${query_groupId}`, newGroupData)
        .then(() => {
          notify('Group updated successfully.', 1);
          history.push('/channels');
        })
        .catch((error) => {
          notify('An error occurred while updating the group. Please try again.', 3);
        });
    } else {
      // Create new group
      const newGroupId = `group_` + Math.floor(100000 + Math.random() * 900000);;
      const path = `channelGroups/${newGroupId}`;
      uploadData(path, newGroupData)
        .then(() => {
          notify('Group created successfully.', 1);
          history.push('/channels');
        })
        .catch((error) => {
          notify('An error occurred while creating the group. Please try again.', 3);
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className="create-channel-group-create-channel-group">
      <div className="create-channel-group-container">
        <div className="create-channel-group-container01">
          <span className="create-channel-group-text">Group Name</span>
          <span className="info-txt">
            Enter the name of the cookie group. The cookie group name helps to categorize and manage your cookies effectively.
          </span>
        </div>
        <div className="create-channel-group-container02">
          <input
            type="text"
            id="7000"
            placeholder="Name"
            className="create-channel-group-chanel-creat-group-group-name-name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
      </div>
      <div className="create-channel-group-container03">
        <div className="create-channel-group-container04">
          <span className="create-channel-group-text2">Contacts</span>
          <span className="info-txt">
            Select the contacts you wish to add to the notification group. These contacts will receive notifications whenever there are updates or important information related to this group. Ensure you choose the appropriate contacts to keep them informed and engaged with the relevant notifications.
          </span>
        </div>
        <div className="create-channel-group-channel-creat-group-contacts">
          <div className="create-channel-group-container05">
            <div className="create-channel-group-container06">
              <CheckBox rootClassName="check-box-root-class-name98"></CheckBox>
            </div>
            <div className="create-channel-group-container07">
              <span className="create-channel-group-text4">
                Contact / Company
              </span>
            </div>
            <div className="create-channel-group-container08">
              <span className="create-channel-group-text5">Email</span>
            </div>
          </div>
          <div className="create-channel-group-container09">
            <ul className="list">
              {notificationContacts.map((id, index) => (
                <li
                  key={index}
                  className="create-channel-group-li list-item"
                  onClick={() => handleToggleContact(id)}
                >
                  <ChannelCreatGroupContactsItem
                    id={id}
                    isSelected={selectedContacts.includes(id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="create-channel-group-container10">
        <div className="create-channel-group-container11 btn-main-white" onClick={handleCancel}>
          <svg viewBox="0 0 1024 1024" className="create-channel-group-icon">
            <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
          </svg>
          <span className="create-channel-group-text6">Cancel</span>
        </div>
        <div className="create-channel-group-container12">
          <div className="create-channel-group-container14" onClick={handleUpload}>
            <svg viewBox="0 0 1024 1024" className="create-channel-group-icon4">
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span className="create-channel-group-text8">Create Group</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateChannelGroup;
