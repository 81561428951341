import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './session-cookie-overview-cookies-create-group-item.css';
import { useData } from '../contexts/DataContext';

const SessionCookieOverviewCookiesCreateGroupItem = (props) => {
  const { userData } = useData();
  const [check, setCheck] = useState(props.isSelected || false);

  useEffect(() => {
    setCheck(props.isSelected);
  }, [props.isSelected]);

  const id = props.id;
  const cookie = userData?.cookies[id];

  return (
    <div
      onClick={() => setCheck(!check)}
      className="session-cookie-overview-cookies-create-group-item-session-cookie-overview-cookies-item"
    >
      <div className="session-cookie-overview-cookies-create-group-item-container">
        <div className="session-cookie-overview-cookies-create-group-item-container1 preference-checkBox">
          {check && (
            <div className="session-cookie-overview-cookies-create-group-item-container2">
              <svg
                viewBox="0 0 1024 1024"
                className="session-cookie-overview-cookies-create-group-item-icon"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="session-cookie-overview-cookies-create-group-item-container3">
        <div className="session-cookie-overview-cookies-create-group-item-container4">
          <span>{cookie?.firstName} {cookie?.lastName}</span>
          <div className="session-cookie-overview-cookies-create-group-item-container5">
            <span className="session-cookie-overview-cookies-create-group-item-text1">
              {cookie?.status}
            </span>
          </div>
        </div>
      </div>
      <div className="session-cookie-overview-cookies-create-group-item-container6">
        {cookie?.linkedin && (
          <div className="session-cookie-overview-cookies-create-group-item-container7">
            <span className="session-cookie-overview-cookies-create-group-item-text4">
              LinkedIn
            </span>
          </div>
        )}
        {cookie?.salesNavigator && (
          <div className="session-cookie-overview-cookies-create-group-item-container8">
            <span className="session-cookie-overview-cookies-create-group-item-text5">
              Sales Navigator
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

SessionCookieOverviewCookiesCreateGroupItem.defaultProps = {
  isSelected: false,
  rootClassName: '',
};

SessionCookieOverviewCookiesCreateGroupItem.propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  rootClassName: PropTypes.string,
};

export default SessionCookieOverviewCookiesCreateGroupItem;
