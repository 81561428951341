import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './list-overview-item.css';
import { useData } from '../contexts/DataContext';
import { confirm } from './Confirmation';
import { notify } from './Notification';


const ListOverviewItem = (props) => {
  const { userData, uploadData } = useData();

  const listId = props.id;
  const list = userData?.lists[listId] || {};
  const name = list.name || 'List Name';
  const createdOn = list.created ? new Date(parseInt(list.created)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';

  const contactIds = list.contacts.filter(contactId => userData.contacts[contactId]?.type === 'contact');
  const totalContacts = contactIds.length;

  const failedContacts = contactIds.filter(contactId => userData.contacts[contactId]?.status === 4).length;
  const lastScan = list.lastScan ? new Date(parseInt(list.lastScan)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';

  const deleteEvent = () => {
    const monitorExist = Object.values(userData.monitors || {}).some(monitor => monitor.list === listId && monitor.delete === false);
    console.log(`monitorExist: ${monitorExist}`);
    if (monitorExist) {
      notify(`Cannot delete list: ${list.name} because it is being monitored.`, 3);
      return
    }

    confirm(`Are you sure you want to delete: ${list.name}?`, () => {
      uploadData(`lists/${listId}/delete`, true)
      notify(`List: ${list.name} has been deleted.`, 1);
    },
      () => {
        console.log('cancel');
      });

  };

  return (
    <div className="list-overview-item-list-overview-item">
      <div className="list-overview-item-container">
        <span className="list-overview-item-text">{name}</span>
        <div className="list-overview-item-container01">
          <span className="list-overview-item-text01">ID: </span>
          <span className="list-overview-item-text02">{listId.replace('lis_','')}</span>
        </div>
      </div>
      <div className="list-overview-item-container02">
        <div className="list-overview-item-container03">
          <span className="list-overview-item-text03 space">Created on: </span>
          <span className="list-overview-item-text04">{createdOn}</span>
        </div>
        <div className="list-overview-item-container04">
          <span className="list-overview-item-text05 space">Contacts: </span>
          <span className="list-overview-item-text06">{totalContacts}</span>
        </div>
      </div>
      <div className="list-overview-item-container05">
        <div className="list-overview-item-container06">
          <span className="list-overview-item-text07 space">Last Scan: </span>
          <span className="list-overview-item-text08">{lastScan}</span>
        </div>
        <div className="list-overview-item-container07">
          <span className="list-overview-item-text09 space">Failed Contacts: </span>
          <span className="list-overview-item-text10">{failedContacts}</span>
        </div>
      </div>
      <div className="list-overview-item-container08">
        <div onClick={deleteEvent} className="list-overview-item-container09">
          <svg viewBox="0 0 1024 1024" className="list-overview-item-icon">
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        <Link to={`/list-details?id=${listId}`} className="list-overview-item-navlink">
          <div className="list-overview-item-container10">
            <span>View List</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

ListOverviewItem.defaultProps = {
  id: 'list_1',
  idNumber: '80183-bfx32',
};

ListOverviewItem.propTypes = {
  id: PropTypes.string,
  idNumber: PropTypes.string,
};

export default ListOverviewItem;
