import React from 'react'

import PropTypes from 'prop-types'

import './monitor-details-trigger-item.css'
import { useData } from '../contexts/DataContext'

const MonitorDetailsTriggerItem = (props) => {
  const { userData } = useData()

  const id = props.id

  const contact = userData?.contacts[id] || {}

  const lastScanTimestamp = contact.jobChange?.timestamp
  const changed = lastScanTimestamp ? new Date(parseInt(lastScanTimestamp)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';


  return (
    <div className="monitor-details-trigger-item-monitor-details-trigger-item">
      <div className="monitor-details-trigger-item-container">
        <div className="monitor-details-trigger-item-container01">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"
            className="monitor-details-trigger-item-image"
          />
        </div>
        <div className="monitor-details-trigger-item-container02">
          <span>{contact.contactName}</span>
          <span className="monitor-details-trigger-item-text1">
            {contact.companyName}
          </span>
        </div>
      </div>
      <div className="monitor-details-trigger-item-container03">
        <div className="monitor-details-trigger-item-container04">
          <span>{contact.description}</span>
          <div className="monitor-details-trigger-item-container05">
            <span className="monitor-details-trigger-item-text3">
              {contact.activity}
            </span>
            <span className="monitor-details-trigger-item-text4"> / </span>
            <span className="monitor-details-trigger-item-text5">
              {contact.position}
            </span>
          </div>
        </div>
      </div>
      <div className="monitor-details-trigger-item-container06">
        <div className="monitor-details-trigger-item-container07">
          <span>{contact.jobChange.from.companyName}</span>
          <span className="monitor-details-trigger-item-text7">
            {contact.jobChange.to.companyName}
          </span>
        </div>
      </div>
      <div className="monitor-details-trigger-item-container08">
        <div className="monitor-details-trigger-item-container09">
          <span>{changed}</span>
        </div>
      </div>
    </div>
  )
}

MonitorDetailsTriggerItem.defaultProps = {
  toCompanyName: 'Company Name',
  fromCompanyName: 'Company Name',
  fullName: 'Contact Full Name',
  activity: 'Activity',
  companyNAME: 'Company Name',
  description: 'Description\n',
  position: 'Position',
  changeDate: '--.--.----',
}

MonitorDetailsTriggerItem.propTypes = {
  toCompanyName: PropTypes.string,
  fromCompanyName: PropTypes.string,
  fullName: PropTypes.string,
  activity: PropTypes.string,
  companyNAME: PropTypes.string,
  description: PropTypes.string,
  position: PropTypes.string,
  changeDate: PropTypes.string,
}

export default MonitorDetailsTriggerItem
