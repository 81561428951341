import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChannelsGroupItem from './channels-group-item';
import './channels-group.css';
import { useData } from '../contexts/DataContext';

const ChannelsGroup = (props) => {
  const { userData } = useData();

  const groups = Object.keys(userData.channelGroups || {}) || [];
  const isEmpty = groups.length === 0;

  return (
    <div className="channels-group-channels-groups">
      <div className="channels-group-container">
        <div className="channels-group-container1">
          <span className="channels-group-text">{props.text}</span>
          <Link to="/channels-create-group" className="channels-group-navlink">
            <div className="channels-group-container2">
              <svg viewBox="0 0 1024 1024" className="channels-group-icon">
                <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
              </svg>
              <span className="channels-group-text1">{props.text1}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className={`channels-group-container3 ${isEmpty ? 'empty' : ''}`}>
        {groups.map((id, index) => (
          <div key={index}>
            <ChannelsGroupItem id={id}></ChannelsGroupItem>
          </div>
        ))}
      </div>
    </div>
  );
};

ChannelsGroup.defaultProps = {
  text: 'Channel Groups',
  text1: 'Create Group',
};

ChannelsGroup.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default ChannelsGroup;
