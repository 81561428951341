// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './style.css';
import ListContact from './views/list-contact';
import Login from './views/login';
import Channels from './views/channels';
import ChannelAddContact from './views/channel-add-contact';
import Integration from './views/integration';
import SessionCookieConnect from './views/session-cookie-connect';
import Team from './views/team';
import ListUploadList from './views/list-upload-list';
import Employees from './views/employees';
import Settings from './views/settings';
import Monitor from './views/monitor';
import ChargeWallet from './views/charge-wallet';
import MonitorDetails from './views/monitor-details';
import Construction from './views/construction';
import MonitorCreate from './views/monitor-create';
import SessionCookieCreateGroup from './views/session-cookie-create-group';
import Billing from './views/billing';
import Triggers from './views/triggers';
import Dashboard from './views/dashboard';
import SessionCookie from './views/session-cookie';
import SavingsPlans from './views/savings-plans';
import Log from './views/log';
import ChannelsCreateGroup from './views/channels-create-group';
import ListDetails from './views/list-details';
import List from './views/list';
import NotFound from './views/not-found';
import Events from './views/events'
import EventsDetails from './views/events-details'
import EventsContacts from './views/events-contacts'
import EventsCreate from './views/events-create'


import { DataProvider } from './contexts/DataContext';
import PrivateRoute from './PrivateRoute';
import ErrorBoundary from './utils/ErrorBoundary';
import HomeRedirect from './views/HomeRedirect';
import Notification from './components/Notification';
import Confirmation from './components/Confirmation'; // Hinzugefügt

const App = () => {
  return (
    <DataProvider>
      <ErrorBoundary>
        <Router>
          <Notification />
          <Confirmation /> {/* Hinzugefügt */}
          <Switch>
            <Route component={HomeRedirect} exact path="/" />
            <Route component={Login} exact path="/login" />
            <PrivateRoute component={Dashboard} exact path="/dashboard" />
            <PrivateRoute component={ListContact} exact path="/list-contact" />
            <PrivateRoute component={Channels} exact path="/channels" />
            <PrivateRoute component={ChannelAddContact} exact path="/channel-add-contact" />
            <PrivateRoute component={Integration} exact path="/integration" />
            <PrivateRoute component={SessionCookieConnect} exact path="/session-cookie-connect" />
            <PrivateRoute component={Team} exact path="/team" />
            <PrivateRoute component={ListUploadList} exact path="/list-upload-list" />
            <PrivateRoute component={Employees} exact path="/employees" />
            <PrivateRoute component={Settings} exact path="/settings" />
            <PrivateRoute component={Monitor} exact path="/monitor" />
            <PrivateRoute component={ChargeWallet} exact path="/charge-wallet" />
            <PrivateRoute component={MonitorDetails} exact path="/monitor-details" />
            <PrivateRoute component={Construction} exact path="/construction" />
            <PrivateRoute component={MonitorCreate} exact path="/monitor-create" />
            <PrivateRoute component={SessionCookieCreateGroup} exact path="/session-cookie-create-group" />
            <PrivateRoute component={Billing} exact path="/billing" />
            <PrivateRoute component={Triggers} exact path="/triggers" />
            <PrivateRoute component={SessionCookie} exact path="/session-cookie" />
            <PrivateRoute component={SavingsPlans} exact path="/savings-plans" />
            <PrivateRoute component={Log} exact path="/log" />
            <PrivateRoute component={ChannelsCreateGroup} exact path="/channels-create-group" />
            <PrivateRoute component={ListDetails} exact path="/list-details" />
            <PrivateRoute component={List} exact path="/list" />
            <PrivateRoute component={EventsDetails} exact path="/events-details" />
            <PrivateRoute component={Events} exact path="/events" />
            <PrivateRoute component={EventsContacts} exact path="/events-contacts" />
            <PrivateRoute component={EventsCreate} exact path="/events-create" />
            <Route component={NotFound} path="**" />

            <Redirect to="**" />
          </Switch>
        </Router>
      </ErrorBoundary>
    </DataProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
