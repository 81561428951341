import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import POPUP from '../components/popup'
import './integration.css'

const Integration = (props) => {
  return (
    <div className="integration-container">
      <Helmet>
        <title>Integration - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Integration - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="integration-main">
        <MenuBar></MenuBar>
        <div className="integration-content">
          <TopBar lable="Integration"></TopBar>
          <div className="integration-content1">
            <Player
              src="https://lottie.host/2473ec48-43e2-47a9-83d9-502990386d2f/WIHLBMc1Di.json"
              loop="true"
              speed="1"
              autoplay="true"
              background="transparent"
              className="integration-lottie-node"
            ></Player>
            <span className="integration-text">UNDER CONSTRUCTION</span>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Integration
