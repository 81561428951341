import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './monitor-details-contacts-item.css'
import { useData } from '../contexts/DataContext';

const MonitorDetailsContactsItem = (props) => {
  const { userData, uploadData } = useData();

  const id = props.id
  const contact = userData.contacts[id]
  const [stats, setStats] = useState(contact.status || 0)
  const created = new Date(parseInt(contact.created)).toLocaleDateString() || '--.--.----'

  return (
    <div className="monitor-details-contacts-item-monitor-details-contacts-item">
      <div className="monitor-details-contacts-item-container">
        <div className="monitor-details-contacts-item-container01">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"
            className="monitor-details-contacts-item-image"
          />
        </div>
        <div className="monitor-details-contacts-item-container02">
          <span>{contact.contactName}</span>
          <span className="monitor-details-contacts-item-text01">
            {contact.companyName}
          </span>
        </div>
      </div>
      <div className="monitor-details-contacts-item-container03">
        <div className="monitor-details-contacts-item-container04">
          <span>{contact.activity}</span>
          <div className="monitor-details-contacts-item-container05">
            <span className="monitor-details-contacts-item-text05">
              {contact.position}
            </span>
          </div>
        </div>
      </div>
      <div className="monitor-details-contacts-item-container06">
        <div className="monitor-details-contacts-item-container07">
          <span>{created}</span>
          <div className="monitor-details-contacts-item-container08">
            {stats === 0 && (
              <span className="monitor-details-contacts-item-text07">
                Pending
              </span>
            )}
            {(stats === 1 || stats === 2 || stats === 3) && (
              <span className="monitor-details-contacts-item-text08">
                Found
              </span>
            )}
            {stats === 4 && (
              <span className="monitor-details-contacts-item-text09">
                Not Found
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

MonitorDetailsContactsItem.defaultProps = {
  activity: 'Activity',
  position: 'Position',
  description: 'Description\n',
  fullName: 'Contact Full Name',
  companyNAME: 'Company Name',
  date: 'Date',
}

MonitorDetailsContactsItem.propTypes = {
  activity: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  fullName: PropTypes.string,
  companyNAME: PropTypes.string,
  date: PropTypes.string,
}

export default MonitorDetailsContactsItem
