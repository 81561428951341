// src/components/Loading.js
import React from 'react';
import './Loading.css';  // Optional: CSS-Datei für die Ladeanzeige

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        Loading...
      </div>
    </div>
  );
};

export default Loading;
