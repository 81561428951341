import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MonitorOverviewItem from './monitor-overview-item';
import './monitor-overview-list.css';
import { useData } from '../contexts/DataContext';

const MonitorOverviewList = (props) => {
  const { userData } = useData();

  let monitors = Object.keys(userData?.monitors || {})
  monitors = monitors.filter(id => userData.monitors[id].delete === false);
  const lastScanTimestamps = monitors.map(id => userData.monitors[id]?.lastScan).filter(Boolean);
  const lastScan = lastScanTimestamps.length ? new Date(Math.max(...lastScanTimestamps)).toLocaleDateString() : 'No monitor available';

  const isEmpty = monitors.length === 0;

  return (
    <div className="monitor-overview-list-monitor-overview-list">
      <div className="monitor-overview-list-container">
        <Link to="/monitor-create" className="monitor-overview-list-navlink">
          <div className="monitor-overview-list-container1">
            <svg viewBox="0 0 1024 1024" className="monitor-overview-list-icon">
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span className="monitor-overview-list-text">Create Monitor</span>
          </div>
        </Link>
        <div className="monitor-overview-list-container2">
          <div className="monitor-overview-list-container3">
            <span className="monitor-overview-list-text1">
              Total monitors:
            </span>
            <span>{monitors.length}</span>
          </div>
          <div className="monitor-overview-list-container4">
            <span className="monitor-overview-list-text3">Last Scan: </span>
            <span>{lastScan}</span>
          </div>
        </div>
      </div>
      <ul className={`list ${isEmpty ? 'empty' : ''}`}>
        {monitors.map((id, index) => (
          <li key={index} className="list-item">
            <MonitorOverviewItem id={id}></MonitorOverviewItem>
          </li>
        ))}
      </ul>
    </div>
  )
}

MonitorOverviewList.defaultProps = {
  totslContacts: '99',
  lastScan: '01.01.2024',
}

MonitorOverviewList.propTypes = {
  totslContacts: PropTypes.string,
  lastScan: PropTypes.string,
}

export default MonitorOverviewList;

