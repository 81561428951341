// src/services/authService.js
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence, signOut } from "firebase/auth";
import { ref, set, get, onValue, off } from "firebase/database";
import { auth, database } from "../firebaseConfig";

export const register = async (firstName, lastName, email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    await set(ref(database, 'clients/' + uid), {
        profile: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: '',
            linkedinUrl: ''
        },
        company: {
            name: '',
            country:'',
            cityState:'',
            postalCode:'',
            taxId:'',
            linkedinUrl:''
        },
        supports:{
            contact_1:{
                first_name:'',
                last_name:'',
                email:'',
                receive_notifications:false,
                dashboard_access:false
            },
            contact_2:{
                first_name:'',
                last_name:'',
                email:'',
                receive_notifications:false,
                dashboard_access:false
            }
        },
        wallet: {
            balance: 0,
            transactions: []
        }
    });
    return uid;
};

export const login = async (email, password) => {
    await setPersistence(auth, browserLocalPersistence);
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const uid = userCredential.user.uid;
    const userSnapshot = await get(ref(database, 'clients/' + uid));
    if (userSnapshot.exists()) {
        localStorage.setItem('user', JSON.stringify({ uid, ...userSnapshot.val() }));
        return { uid, ...userSnapshot.val() };
    }
    return null;
};

export const logout = async () => {
    await signOut(auth);
    localStorage.removeItem('user');
};

export const onAuthChange = (callback) => {
    return onAuthStateChanged(auth, callback);
};

export const subscribeToUserData = (uid, callback) => {
    const userRef = ref(database, 'clients/' + uid);
    onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });

    return () => off(userRef);
};
