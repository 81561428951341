import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './list-update-form.css';
import { fileToJSON } from '../utils/fileConverter';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const ListUploadList = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory();

  const [file, setFile] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [listName, setListName] = useState('');
  const [newContacts, setNewContacts] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_listId = queryParams.get('id');

  useEffect(() => {
    if (query_listId && userData.lists && userData.lists[query_listId]) {
      const listData = userData.lists[query_listId];
      setListName(listData.name);
      setNewContacts(listData.contacts);
      setIsUpload(true);
      setFile({ name: listData.file }); // Setze file auf das gespeicherte Dateiname
    }

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const uploadedFile = event.dataTransfer.files[0];
      setFile(uploadedFile);

      fileToJSON(uploadedFile)
        .then((json) => {
          const validationError = validateJSON(json);
          if (validationError) {
            alert(validationError);
            setIsUpload(false);
          } else {
            const contacts = json.reduce((acc, item) => {
              const existingContact = Object.values(userData?.contacts || {}).find(
                (contact) => contact.contactName === item.contactName && contact.companyName === item.companyName
              );
              if (!existingContact) {
                const randomSixDigits = Math.floor(100000 + Math.random() * 900000);
                const id = `con-${randomSixDigits}`;
                acc[id] = {
                  id: id,
                  activity: '-',
                  companyName: item.companyName,
                  contactName: item.contactName,
                  created: Date.now(),
                  delete: false,
                  description: '-',
                  imageSrc: '-',
                  isPreference: false,
                  position: '-',
                  status: 0,
                  triggered: false,
                  type: 'contact'
                };
              }
              return acc;
            }, {});
            if (Object.keys(contacts).length === 0) {
              notify('There are no new contacts in this list as the contacts already exist in the database.', 0);
              setIsUpload(false);
            } else {
              setNewContacts(contacts);
              notify('The list was successfully uploaded.', 1);
              setIsUpload(true);
            }
          }
        })
        .catch((error) => {
          notify('An error occurred while converting the file. Please check the file format and try again.', 3);
          setIsUpload(false);
        });
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, [userData, query_listId]);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    fileToJSON(uploadedFile)
      .then((json) => {
        const validationError = validateJSON(json);
        if (validationError) {
          setIsUpload(false);
        } else {
          const contacts = json.reduce((acc, item) => {
            const existingContact = Object.values(userData?.contacts || {}).find(
              (contact) => contact.contactName === item.contactName && contact.companyName === item.companyName
            );
            if (!existingContact) {
              const randomSixDigits = Math.floor(100000 + Math.random() * 900000);
              const id = `con-${randomSixDigits}`;
              acc[id] = {
                id: id,
                activity: '-',
                companyName: item.companyName,
                contactName: item.contactName,
                created: Date.now(),
                delete: false,
                description: '-',
                imageSrc: '-',
                isPreference: false,
                position: '-',
                status: 0,
                triggered: false,
                type: 'contact'
              };
            }
            return acc;
          }, {});
          if (Object.keys(contacts).length === 0) {
            notify('There are no new contacts in this list as the contacts already exist in the database.', 0);
            setIsUpload(false);
          } else {
            setNewContacts(contacts);
            notify('The list was successfully uploaded.', 1);
            setIsUpload(true);
          }
        }
      })
      .catch((error) => {
        notify('An error occurred while converting the file. Please check the file format and try again.', 3);
        setIsUpload(false);
      });
    event.target.value = null;
  };

  const handleBrowseClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleListNameChange = (event) => {
    setListName(event.target.value);
  };

  const validateJSON = (json) => {
    for (const item of json) {
      if (!item.contactName) {
        notify('"contactName" is missing in one or more entries.', 2);
        return '"contactName" fehlt in einem oder mehreren Einträgen';
      }
      if (!item.companyName) {
        notify('"companyName" is missing in one or more entries.', 2);
        return '"companyName" fehlt in einem oder mehreren Einträgen';
      }
    }
    return null;
  };

  const generateUniqueListName = (baseName) => {
    let newName = baseName;
    let counter = 1;
    while (Object.values(userData?.lists || {}).some((list) => list.name.toLowerCase() === newName.toLowerCase() && !list.delete)) {
      counter++;
      newName = `${baseName} (${counter})`;
    }
    return newName;
  };

  const handleSave = () => {
    if (!listName) {
      notify('The list name is missing. Please provide a name for the list before proceeding.', 3);
      return;
    }
    if (!isUpload) {
      notify('Please drag and drop or choose a file to upload before proceeding.', 3);
      return;
    }

    setIsSaving(true);

    const uniqueListName = generateUniqueListName(listName);

    if (query_listId) {
      // Update existing list name
      uploadData(`lists/${query_listId}/name`, uniqueListName)
        .then(() => {
          setIsSaving(false);
          notify('List name updated successfully.', 1);
          history.push('/list');
        })
        .catch((error) => {
          notify('An error occurred while updating the list name. Please try again.', 3);
          setIsSaving(false);
        });
    } else {
      const listId = 'lis_' + Math.floor(100000 + Math.random() * 900000);
      const newList = {
        id: listId,
        name: uniqueListName,
        created: Date.now(),
        lastScan: Date.now(),
        contacts: Object.keys(newContacts),
        delete: false,
        file: file.name,
      };

      // Add the list ID to each new contact
      const contactsWithListId = Object.keys(newContacts).reduce((acc, id) => {
        acc[id] = {
          ...newContacts[id],
          listId: listId,
        };
        return acc;
      }, {});

      const uploadPromises = Object.keys(contactsWithListId).map(id => uploadData(`contacts/${id}`, contactsWithListId[id]));

      Promise.all(uploadPromises)
        .then(() => uploadData(`lists/${listId}`, newList))
        .then(() => {
          setIsSaving(false);
          setIsUpload(false);
          notify('Contacts list uploaded successfully. All new contacts have been added to the database.', 1);
          history.push('/list'); // Weiterleiten zur /list Seite nach erfolgreichem Speichern
        })
        .catch((error) => {
          notify('An error occurred while uploading the data. Please try again.', 3);
          setIsSaving(false);
        });
    }
  };

  return (
    <div className="list-update-form-list-update-form">
      <div className="list-update-form-container">
        <div className="list-update-form-container01">
          <span className="list-update-form-text">List Name</span>
          <span className="info-txt">
            Enter the name of your list. The list name helps you identify and organize your uploaded files more easily.
          </span>
        </div>
        <div className="list-update-form-container02">
          <input
            type="text"
            id="3001"
            placeholder="List-001"
            className="list-update-form-monitor-create-monitor-monitor-name"
            value={listName}
            onChange={handleListNameChange}
          />
        </div>
      </div>
      <div className="list-update-form-container03">
        <div className="list-update-form-container04">
          <span className="list-update-form-text02">Upload File</span>
          <span className="info-txt">
            Upload your contact data. Please note that the fields <b>'contactName'</b> and <b>'companyName'</b> are required. This information is necessary to ensure that your contacts are correctly identified and assigned.
          </span>
        </div>
        {!isUpload && (
          <div className="list-update-form-container05">
            <span className="list-update-form-text04">
              Drag and Drop files here
            </span>
            <span className="list-update-form-text05">
              File supported: CSV, XLSX
            </span>
            <div
              onClick={handleBrowseClick}
              className="list-update-form-container06"
            >
              <span className="list-update-form-text06">Choose File</span>
            </div>
            <input
              type="file"
              id="fileInput"
              onChange={handleFileChange}
              className="list-update-form-file-input"
              style={{ display: 'none' }}
            />
          </div>
        )}
        {isUpload && (
          <div className="list-update-form-container07">
            <span className="list-update-form-text07">
              {file ? file.name : 'No file selected'}
            </span>
            {!query_listId && (
              <span
                onClick={() => setIsUpload(false)}
                className="list-update-form-text08"
              >
                Cancel
              </span>
            )}
          </div>
        )}
      </div>
      <div className="list-update-form-container08">
        <Link to="/list" className="list-update-form-navlink">
          <div className="list-update-form-container09 btn-main-white">
            <svg viewBox="0 0 1024 1024" className="list-update-form-icon">
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span className="list-update-form-text09">Cancel</span>
          </div>
        </Link>
        <button
          onClick={handleSave}
          className="list-update-form-container10"
          disabled={isSaving}
        >
          <svg viewBox="0 0 1024 1024" className="list-update-form-icon2">
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="list-update-form-text10">{isSaving ? 'wait...' : 'Save'}</span>
        </button>
      </div>
    </div>
  );
};

export default ListUploadList;
