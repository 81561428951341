import React from 'react'

import PropTypes from 'prop-types'

import './billing-overview-price-charts.css'

const BillingOverviewPriceCharts = (props) => {
  return (
    <div className="billing-overview-price-charts-billing-overview-price-charts">
      <div className="billing-overview-price-charts-container border-bottom">
        <span>{props.text}</span>
      </div>
      <div className="billing-overview-price-charts-container1">
        <span>{props.text1}</span>
      </div>
    </div>
  )
}

BillingOverviewPriceCharts.defaultProps = {
  text: 'Price charts',
  text1: 'Ext. Chart',
}

BillingOverviewPriceCharts.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
}

export default BillingOverviewPriceCharts
