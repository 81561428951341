import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './toggle-box.css'

const ToggleBox = (props) => {
  const [status, setStatus] = useState(props.value)
  return (
    <div
      onClick={() => setStatus(!status)}
      className={`toggle-box-container ${props.rootClassName} `}
    >
      {!status && (
        <div className="toggle-box-container1">
          <div className="toggle-box-container2"></div>
        </div>
      )}
      {status && (
        <div className="toggle-box-container3">
          <div className="toggle-box-container4"></div>
        </div>
      )}
    </div>
  )
}

ToggleBox.defaultProps = {
  rootClassName: '',
}

ToggleBox.propTypes = {
  rootClassName: PropTypes.string,
}

export default ToggleBox
