import React from 'react'

import './billing-overview-recent-payment.css'

const BillingOverviewRecentPayment = (props) => {
  return (
    <div className="billing-overview-recent-payment-billing-overview-recent-paument">
      <div className="billing-overview-recent-payment-container border-bottom bg-gradient">
        <span>Recent Payment</span>
      </div>
      <ul className="billing-overview-recent-payment-ul list">
        <li className="list-item">
          <div className="billing-overview-recent-payment-container1 border-bottom">
            <span>Payment Name</span>
            <span>01.01.2024</span>
            <div className="billing-overview-recent-payment-container2">
              <span className="billing-overview-recent-payment-text3">
                Paid
              </span>
            </div>
            <div className="billing-overview-recent-payment-container3">
              <div className="billing-overview-recent-payment-container4">
                <svg
                  viewBox="0 0 1024 1024"
                  className="billing-overview-recent-payment-icon"
                >
                  <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                </svg>
              </div>
              <div className="billing-overview-recent-payment-container5">
                <svg
                  viewBox="0 0 1024 1024"
                  className="billing-overview-recent-payment-icon2"
                >
                  <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"></path>
                  <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
                </svg>
                <span className="billing-overview-recent-payment-text4">
                  Export
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default BillingOverviewRecentPayment
