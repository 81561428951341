import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListDetailsListNameItem from './list-details-list-name-item';
import './dashboard-overview-preference-contacts-list.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const DashboardOverviewPreferenceContactsList = (props) => {
  const { userData } = useData();
  const [preferenceContactsId, setPreferenceContactsId] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClick = () => {
    notify('An error occurred while converting the file. Please check the file format and try again.');
  };

  useEffect(() => {
    if (userData) {
      // Retrieve monitor preferences
      const monitorPreferences = Object.values(userData?.monitors || {}).reduce((acc, monitor) => {
        monitor.preferences.forEach(preference => {
          if (!acc.includes(preference)) {
            acc.push(preference);
          }
        });
        return acc;
      }, []);
      const contacts = Object.values(userData?.contacts || {}).filter(contact => (contact.status === 1 || contact.status === 2 || contact.status === 3) && contact.delete === false);

      // Map filtered contacts to their IDs
      const filteredContactsId = contacts.map((contact) => contact.id);
      setPreferenceContactsId(filteredContactsId);
    }
  }, [userData]);

  useEffect(() => {
    const statusMapping = {
      pending: 0,
      employee: 1,
      'job changed': 2,
      'job exit': 3,
      'not found': 4
    };

    const contacts = Object.values(userData?.contacts || {}).filter(contact => (contact.status === 1 || contact.status === 2 || contact.status === 3) && contact.delete === false);

    const filteredContactsId = contacts.filter((contact) => {
      const query = searchQuery.toLowerCase();
      return (
        contact.contactName.toLowerCase().includes(query) ||
        contact.companyName.toLowerCase().includes(query) ||
        contact.activity.toLowerCase().includes(query) ||
        contact.position.toLowerCase().includes(query) ||
        String(contact.status).includes(query) ||
        Object.keys(statusMapping).some(key => key.includes(query) && statusMapping[key] === contact.status)
      );
    })
    console.log(filteredContactsId);

    setPreferenceContactsId(filteredContactsId);
  }, [searchQuery, userData]);

  return (
    <div className={`dashboard-overview-preference-contacts-list-dashboard-overview-preference-contacts ${props.rootClassName} `}>
      <div className="dashboard-overview-preference-contacts-list-container">
        <div className="dashboard-overview-preference-contacts-list-container01">
          <div className="dashboard-overview-preference-contacts-list-container02">
            <span onClick={handleClick} className="dashboard-overview-preference-contacts-list-text">
              {props.text}
            </span>
          </div>
        </div>
        <div className="dashboard-overview-preference-contacts-list-container18">
          <svg
            viewBox="0 0 1024 1024"
            className="dashboard-overview-preference-contacts-list-icon08"
          >
            <path
              d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"
              className=""
            ></path>
          </svg>
          <input
            type="text"
            id="2000"
            placeholder={props.dashboardOverviewPreferencecontactsFindPlaceholder}
            className="dashboard-overview-preference-contacts-list-dashboard-overview-preferencecontacts-find"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className="dashboard-overview-preference-contacts-list-container19">
        <div className="dashboard-overview-preference-contacts-list-container20">
          <div className="dashboard-overview-preference-contacts-list-container21">
            <span className="dashboard-overview-preference-contacts-list-text10">
              {props.text1}
            </span>
          </div>
          <div className="dashboard-overview-preference-contacts-list-container22">
            <span className="dashboard-overview-preference-contacts-list-text11">
              {props.text2}
            </span>
          </div>
          <div className="dashboard-overview-preference-contacts-list-container23">
            <span className="dashboard-overview-preference-contacts-list-text12">
              {props.text3}
            </span>
          </div>
          <div className="dashboard-overview-preference-contacts-list-container24"></div>
        </div>
        <ul className="dashboard-overview-preference-contacts-list-ul list">
          {preferenceContactsId.map((contact, index) => (
            <li key={index} className="list-item">
              <ListDetailsListNameItem id={contact.id} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DashboardOverviewPreferenceContactsList.defaultProps = {
  text: 'Preference Contacts',
  text1: 'Contact / Company',
  text2: 'Activity / Position',
  text3: 'Data / Status',
  dashboardOverviewPreferencecontactsFindPlaceholder: 'Contact, Company, Activity or Position',
  rootClassName: '',
  rootClassName1: '',
};

DashboardOverviewPreferenceContactsList.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  dashboardOverviewPreferencecontactsFindPlaceholder: PropTypes.string,
  rootClassName: PropTypes.string,
  rootClassName1: PropTypes.string,
};

export default DashboardOverviewPreferenceContactsList;
