import React from 'react'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import TriggersOverviewList from '../components/triggers-overview-list'
import POPUP from '../components/popup'
import './triggers.css'

const Triggers = (props) => {
  return (
    <div className="triggers-container">
      <Helmet>
        <title>Triggers - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Triggers - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="triggers-main">
        <MenuBar triggers={true}></MenuBar>
        <div className="triggers-content">
          <TopBar lable="Triggers" lable2="Overview"></TopBar>
          <TriggersOverviewList></TriggersOverviewList>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Triggers
