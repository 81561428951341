import React from 'react'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import SettingsOverview from '../components/settings-overview'
import POPUP from '../components/popup'
import './settings.css'

const Settings = (props) => {
  return (
    <div className="settings-container">
      <Helmet>
        <title>Settings - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Settings - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="settings-main">
        <MenuBar settings={true}></MenuBar>
        <div className="settings-content">
          <TopBar lable="Settings"></TopBar>
          <div className="settings-content1">
            <div className="settings-container1">
              <div className="settings-container2">
                <svg viewBox="0 0 1024 1024" className="settings-icon">
                  <path d="M512 0c282.857 0 512 229.143 512 512s-229.143 512-512 512-512-229.143-512-512 229.143-512 512-512zM512 73.143c-74.286 0-144.571 18.857-206.286 51.429l110.857 110.857c30.286-10.286 62.286-16 95.429-16 33.714 0 65.143 5.714 95.429 16l110.857-110.857c-61.714-32.571-132-51.429-206.286-51.429zM124.571 718.286l110.857-110.857c-10.286-30.286-16-62.286-16-95.429 0-33.714 5.714-65.143 16-95.429l-110.857-110.857c-32.571 61.714-51.429 132-51.429 206.286s18.857 144.571 51.429 206.286zM512 950.857c74.286 0 144.571-18.857 206.286-51.429l-110.857-110.857c-30.286 10.286-61.714 16-95.429 16-33.143 0-65.143-5.714-95.429-16l-110.857 110.857c61.714 32.571 132 51.429 206.286 51.429zM512 731.429c121.143 0 219.429-98.286 219.429-219.429s-98.286-219.429-219.429-219.429-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429zM788.571 607.429l110.857 110.857c32.571-61.714 51.429-132 51.429-206.286s-18.857-144.571-51.429-206.286l-110.857 110.857c10.286 30.286 16 62.286 16 95.429s-5.714 65.143-16 95.429z"></path>
                </svg>
                <span className="settings-text">
                  Support
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
            </div>
            <SettingsOverview></SettingsOverview>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Settings
