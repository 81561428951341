import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './sort-byv1-drop-down-component.css'

const SortByv1DropDownComponent = (props) => {
  const [dropDown, setDropDown] = useState(false)
  const [sortBy, setSortBy] = useState('-')
  return (
    <div
      className={`sort-byv1-drop-down-component-sort-by-drop-down-component ${props.rootClassName} `}
    >
      <div
        onClick={() => setDropDown(true)}
        className="sort-byv1-drop-down-component-container"
      >
        <span className="sort-byv1-drop-down-component-text">Sort by:</span>
        <span className="sort-byv1-drop-down-component-text1">{sortBy}</span>
      </div>
      {dropDown && (
        <div
          onMouseLeave={() => setDropDown(false)}
          className="sort-byv1-drop-down-component-container01"
        >
          <div className="sort-byv1-drop-down-component-container02">
            <div className="sort-byv1-drop-down-component-container03 border-bottom">
              <span className="">All</span>
              <div className="sort-byv1-drop-down-component-container04">
                <div
                  onClick={() => setSortBy('All')}
                  className="sort-byv1-drop-down-component-container05"
                >
                  {sortBy === 'All' && (
                    <div className="sort-byv1-drop-down-component-container06">
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sort-byv1-drop-down-component-icon"
                      >
                        <path
                          d="M384 690l452-452 60 60-512 512-238-238 60-60z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sort-byv1-drop-down-component-container07 border-bottom">
              <span className="">Pending</span>
              <div className="sort-byv1-drop-down-component-container08">
                <div
                  onClick={() => setSortBy('Pending')}
                  className="sort-byv1-drop-down-component-container09"
                >
                  {sortBy === 'Pending' && (
                    <div className="sort-byv1-drop-down-component-container10">
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sort-byv1-drop-down-component-icon2"
                      >
                        <path
                          d="M384 690l452-452 60 60-512 512-238-238 60-60z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sort-byv1-drop-down-component-container11 border-bottom">
              <span className="">Found</span>
              <div className="sort-byv1-drop-down-component-container12">
                <div
                  onClick={() => setSortBy('Found')}
                  className="sort-byv1-drop-down-component-container13"
                >
                  {sortBy === 'Found' && (
                    <div className="sort-byv1-drop-down-component-container14">
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sort-byv1-drop-down-component-icon4"
                      >
                        <path
                          d="M384 690l452-452 60 60-512 512-238-238 60-60z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="sort-byv1-drop-down-component-container15">
              <span className="">Not Found</span>
              <div className="sort-byv1-drop-down-component-container16">
                <div
                  onClick={() => setSortBy('Not Found')}
                  className="sort-byv1-drop-down-component-container17"
                >
                  {sortBy === 'Not Found' && (
                    <div className="sort-byv1-drop-down-component-container18">
                      <svg
                        viewBox="0 0 1024 1024"
                        className="sort-byv1-drop-down-component-icon6"
                      >
                        <path
                          d="M384 690l452-452 60 60-512 512-238-238 60-60z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

SortByv1DropDownComponent.defaultProps = {
  rootClassName: '',
}

SortByv1DropDownComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default SortByv1DropDownComponent
