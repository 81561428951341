import React from 'react'
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types'

import './channels-overview-contacts-item.css'

import { useData } from '../contexts/DataContext'

const ChannelsOverviewContactsItem = (props) => {
  const { userData } = useData()
  const history = useHistory();

  const id = props.id

  const contactType = userData?.notificationContacts[id]?.type || '-'
  const firstName = userData?.notificationContacts[id]?.firstName || '.....'
  const lastName = userData?.notificationContacts[id]?.lastName || '.....'
  const notificationsObj = userData?.notificationContacts[id]?.notifications;
  const notifications = notificationsObj ? Object.values(notificationsObj) : [];
  let lastNotification = notifications.length > 0 ? notifications[notifications.length - 1].date : '--.--.----';
  const email = userData?.notificationContacts[id]?.email || '.....'

  lastNotification = new Date(parseInt(lastNotification)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' });
  
  const handleEditClick = () => {
    history.push(`/channel-add-contact?id=${id}`);
  };

  return (
    <div className="channels-overview-contacts-item-session-cookie-overview-channels-item">
      <div className="channels-overview-contacts-item-container">
        <div className="channels-overview-contacts-item-container1">
          <img
            alt="image"
            src={props.imageSrc}
            className="channels-overview-contacts-item-image"
          />
        </div>
        <div className="channels-overview-contacts-item-container2">
          <span>{firstName + ' ' + lastName}</span>
          <span className="channels-overview-contacts-item-text1">
            {contactType}
          </span>
        </div>
      </div>
      <div className="channels-overview-contacts-item-container3">
        <span>{email}</span>
      </div>
      <div className="channels-overview-contacts-item-container4">
        <div className="channels-overview-contacts-item-container5">
          <span>{lastNotification}</span>
        </div>
      </div>
      <div className="channels-overview-contacts-item-container6">
        <div className="channels-overview-contacts-item-container7">
          <svg
            viewBox="0 0 1024 1024"
            className="channels-overview-contacts-item-icon"
          >
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        <div onClick={handleEditClick} className="channels-overview-contacts-item-container8">
          <span>Edit</span>
        </div>
      </div>
    </div>
  )
}

ChannelsOverviewContactsItem.defaultProps = {
  data: '01.01.2024',
  email: 'email@address.com',
  imageSrc:
    'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&ixlib=rb-4.0.3&w=200',
  company: 'Company Name',
  name: 'Contact Name',
}

ChannelsOverviewContactsItem.propTypes = {
  data: PropTypes.string,
  email: PropTypes.string,
  imageSrc: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
}

export default ChannelsOverviewContactsItem
