// src/PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useData } from './contexts/DataContext';
import Loading from './components/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isInitializing, isDataLoading } = useData();

  if (isInitializing || isDataLoading) {
    return <Loading />; // Ladeanzeige während der Initialisierung und Datenladung
  }

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
