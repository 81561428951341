import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import EventsDetailsComponent from '../components/events-details-component'
import './events-details.css'
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';
import { confirm } from '../components/Confirmation'

const EventsDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { uploadData } = useData();

  const eventDelete = () => {
    confirm(`Are you sure you want delete this monitor?`, () => {
      uploadData(`eventMonitors/${id}/delete`, true)
        .then(() => {
          notify(`Monitor has been deleted.`, 1)
          history.push('/events');
        })
        .catch(() => {
          notify(`Failed to delete monitor`)
        })
    }, () => {
      console.log('cancel');
    });
  }
  return (
    <div className="events-details-container">
      <Helmet>
        <title>Events-Details - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="Events-Details - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="events-details-main">
        <MenuBar></MenuBar>
        <div className="events-details-content">
          <TopBar lable="Event Monitor" lable2="Details"></TopBar>
          <div className="events-details-content1">
            <div className="events-details-container1">
              <div className="events-details-container2">
                <Link to="/events" className="events-details-navlink">
                  <div className="events-details-container3">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="events-details-icon"
                    >
                      <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                    </svg>
                    <span className="events-details-text">Back</span>
                  </div>
                </Link>
                <Link to="/events-create" className="events-details-navlink1">
                  <div className="events-details-container4">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="events-details-icon2"
                    >
                      <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                    </svg>
                    <span className="events-details-text1">Create Monitor</span>
                  </div>
                </Link>
              </div>
              <div onClick={eventDelete} className="events-details-container5">
                <svg viewBox="0 0 1024 1024" className="events-details-icon4">
                  <path d="M292.571 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM438.857 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM585.143 786.286v-402.286c0-10.286-8-18.286-18.286-18.286h-36.571c-10.286 0-18.286 8-18.286 18.286v402.286c0 10.286 8 18.286 18.286 18.286h36.571c10.286 0 18.286-8 18.286-18.286zM274.286 219.429h256l-27.429-66.857c-1.714-2.286-6.857-5.714-9.714-6.286h-181.143c-3.429 0.571-8 4-9.714 6.286zM804.571 237.714v36.571c0 10.286-8 18.286-18.286 18.286h-54.857v541.714c0 62.857-41.143 116.571-91.429 116.571h-475.429c-50.286 0-91.429-51.429-91.429-114.286v-544h-54.857c-10.286 0-18.286-8-18.286-18.286v-36.571c0-10.286 8-18.286 18.286-18.286h176.571l40-95.429c11.429-28 45.714-50.857 76-50.857h182.857c30.286 0 64.571 22.857 76 50.857l40 95.429h176.571c10.286 0 18.286 8 18.286 18.286z"></path>
                </svg>
                <span className="events-details-text2">Delete</span>
              </div>
            </div>
            <EventsDetailsComponent rootClassName="events-details-component-root-class-name"></EventsDetailsComponent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventsDetails
