import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TriggersOverviewListItem from './triggers-overview-list-item';
import './triggers-overview-list.css';
import { useData } from '../contexts/DataContext';
import { downloadCsv } from '../utils/csvCreator';
import { notifyPromiseStart, notifyPromiseStop } from '../components/Notification';

const TriggersOverviewList = (props) => {
  const { userData } = useData();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTriggerIds, setFilteredTriggerIds] = useState([]);

  useEffect(() => {
    if (userData) {
      const triggerIds = Object.keys(userData?.contacts || {}).filter(
        (id) => userData.contacts[id].triggered === true
      );
      setFilteredTriggerIds(triggerIds);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      const statusMapping = {
        pending: 0,
        employee: 1,
        'job changed': 2,
        'job exit': 3,
        'not found': 4
      };

      const triggerIds = Object.keys(userData?.contacts || {}).filter(
        (id) => userData.contacts[id].triggered === true
      );
      const filteredIds = triggerIds.filter(triggerId => {
        const contact = userData.contacts[triggerId];
        const query = searchQuery.toLowerCase();
        return (
          contact.contactName.toLowerCase().includes(query) ||
          contact.companyName.toLowerCase().includes(query) ||
          contact.activity.toLowerCase().includes(query) ||
          contact.position.toLowerCase().includes(query) ||
          String(contact.status).includes(query) ||
          Object.keys(statusMapping).some(key => key.includes(query) && statusMapping[key] === contact.status)
        );
      });
      setFilteredTriggerIds(filteredIds);
    }
  }, [searchQuery, userData]);

  const handleExport = async () => {
    notifyPromiseStart('Creating export file...');
    const dataToExport = filteredTriggerIds.map((id) => ({
      contactName: userData.contacts[id].contactName,
      companyName: userData.contacts[id].companyName,
      activity: userData.contacts[id].activity,
      position: userData.contacts[id].position,
      triggerDate: new Date(userData.contacts[id].triggeredOn).toLocaleDateString(),
    }));
    await downloadCsv(dataToExport, `trigger_contacts_export_${Date.now()}.csv`);
    notifyPromiseStop('Export file created successfully');
  };

  return (
    <div className="triggers-overview-list-triggers-overview-list">
      <div className="triggers-overview-list-container">
        <div className="triggers-overview-list-container01">
          <span className="triggers-overview-list-text">All triggers</span>
          <div className="triggers-overview-list-container02">
            <span className="triggers-overview-list-text1">
              Total triggers:
            </span>
            <span className="triggers-overview-list-text2">{filteredTriggerIds.length}</span>
          </div>
        </div>
        <div className="triggers-overview-list-container04">
          <div className="triggers-overview-list-container05">
            <div className="triggers-overview-list-container06">
              <svg
                viewBox="0 0 1024 1024"
                className="triggers-overview-list-icon"
              >
                <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
              </svg>
              <input
                type="text"
                id="4000"
                placeholder="Contact, Company, Activity or Position"
                className="triggers-overview-list-list-details-list-name-find"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="triggers-overview-list-container07" onClick={handleExport}>
            <svg
              viewBox="0 0 1024 1024"
              className="triggers-overview-list-icon2"
            >
              <path d="M214 768h596v86h-596v-86zM810 384l-298 298-298-298h170v-256h256v256h170z"></path>
            </svg>
            <span className="triggers-overview-list-text5">Export List</span>
          </div>
        </div>
      </div>
      <div className="triggers-overview-list-container08">
        <div className="triggers-overview-list-container09">
          <div className="triggers-overview-list-container10">
            <span className="triggers-overview-list-text6">
              Contact / Company
            </span>
          </div>
          <div className="triggers-overview-list-container11">
            <span className="triggers-overview-list-text7">
              Activity / Position
            </span>
          </div>
          <div className="triggers-overview-list-container12">
            <span className="triggers-overview-list-text8">Change from / to</span>
          </div>
          <div style={{flex:0.5}} className="triggers-overview-list-container12">
            <span className="triggers-overview-list-text8">Triggered on</span>
          </div>
          <div className="triggers-overview-list-container13"></div>
        </div>
        <ul className="triggers-overview-list-ul list">
          {filteredTriggerIds.map((id, index) => (
            <li key={index} className="list-item">
              <TriggersOverviewListItem id={id}></TriggersOverviewListItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

TriggersOverviewList.defaultProps = {
  totalTriggers: '9.999',
};

TriggersOverviewList.propTypes = {
  totalTriggers: PropTypes.string,
};

export default TriggersOverviewList;
