import React from 'react';
import PropTypes from 'prop-types';
import DashboardOverviewContactsItem from './dashboard-overview-contacts-item';
import './dashboard-overview-preference-contacts.css';
import { useData } from '../contexts/DataContext';

const DashboardOverviewPreferenceContacts = (props) => {
  const { userData } = useData();

  const monitorPreferences = Object.values(userData?.monitors || {}).reduce((acc, monitor) => {
    monitor.preferences.forEach(preference => {
      if (!acc.includes(preference)) {
        acc.push(preference);
      }
    });
    return acc;
  }, []);

  const contacts = Object.values(userData?.contacts || {}).filter(contact => (contact.status === 2 || contact.status === 3) && contact.delete === false);

  const activityCounts = {};
  const positionCounts = {};

  contacts.forEach(contact => {
    const { activity, position } = contact;

    if (activity) {
      activityCounts[activity] = (activityCounts[activity] || 0) + 1;
    }

    if (position) {
      positionCounts[position] = (positionCounts[position] || 0) + 1;
    }
  });

  const preferences = [];

  contacts.forEach(contact => {
    if (activityCounts[contact.activity] && monitorPreferences.includes(contact.activity)) {
      preferences.push({
        name: contact.activity,
        count: activityCounts[contact.activity],
        isPreference: true
      });
    } else if (positionCounts[contact.position] && monitorPreferences.includes(contact.position)) {
      preferences.push({
        name: contact.position,
        count: positionCounts[contact.position],
        isPreference: true
      });
    }
  });

  // 4
  const totalContacts = contacts.length;
  const preferenceCount = preferences.reduce((sum, pref) => sum + pref.count, 0);
  const preferencePercent = totalContacts > 0 ? ((preferenceCount / totalContacts) * 100).toFixed(2) : '0.00';


  return (
    <div className="dashboard-overview-preference-contacts-dashboard-overview-preference-contacts">
      <div className="dashboard-overview-preference-contacts-container border-bottom bg-gradient">
        <div className="dashboard-overview-preference-contacts-container1">
          <span className="dashboard-overview-preference-contacts-text">
            Quit / New employed
          </span>
          <span className="dashboard-overview-preference-contacts-text1">
            {totalContacts}
          </span>
        </div>
        <div className="dashboard-overview-preference-contacts-container2">
          <span className="dashboard-overview-preference-contacts-text2">
            Preference
          </span>
          <span className="dashboard-overview-preference-contacts-text3">
            {preferencePercent + '%'}
          </span>
        </div>
      </div>
      <div className="dashboard-overview-preference-contacts-container3">
        <ul className="dashboard-overview-preference-contacts-ul list">
          {preferences.map((item, index) => (
            <li key={index} className="list-item">
              <DashboardOverviewContactsItem item={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardOverviewPreferenceContacts;
