import React from 'react'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import BillingOverviewCosts from '../components/billing-overview-costs'
import BillingOverviewPriceCharts from '../components/billing-overview-price-charts'
import BillingOverviewRecentPayment from '../components/billing-overview-recent-payment'
import './billing.css'

const Billing = (props) => {
  return (
    <div className="billing-container">
      <Helmet>
        <title>Billing - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Billing - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="billing-main">
        <MenuBar billing={true} ></MenuBar>
        <div className="billing-content">
          <TopBar lable="Billing"></TopBar>
          <div className="billing-content1">
            <div className="billing-container1">
              <div className="billing-container2">
                <svg viewBox="0 0 1024 1024" className="billing-icon">
                  <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"></path>
                  <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
                </svg>
                <span className="billing-text">Cancel</span>
              </div>
            </div>
            <div className="billing-container3">
              <BillingOverviewCosts></BillingOverviewCosts>
              <BillingOverviewPriceCharts></BillingOverviewPriceCharts>
            </div>
            <BillingOverviewRecentPayment></BillingOverviewRecentPayment>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Billing
