import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MenuBar from '../components/menu-bar';
import TopBar from '../components/top-bar';
import SortByv1DropDownComponent from '../components/sort-byv1-drop-down-component';
import ListDetailsListNameItem from '../components/list-details-list-name-item';
import POPUP from '../components/popup';
import './list-details.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';
import { confirm } from '../components/Confirmation';

const ListDetails = (props) => {
  const { userData, uploadData } = useData();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const list = userData?.lists[id];

  // Convert timestamps to readable dates
  const createdDate = list ? new Date(parseInt(list.created)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';

  // Filter contacts to include only those with type 'contact'
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredContactIds, setFilteredContactIds] = useState([]);

  useEffect(() => {
    if (userData && list) {
      const contactIds = list.contacts.filter(contactId => userData.contacts[contactId]?.type === 'contact' && userData.contacts[contactId]?.delete === false);
      setFilteredContactIds(contactIds); // Initially set to all contact IDs
    }
  }, [userData, list]);

  useEffect(() => {
    if (userData && list) {
      const statusMapping = {
        pending: 0,
        employee: 1,
        'job changed': 2,
        'job exit': 3,
        'not found': 4
      };

      const contactIds = list.contacts.filter(contactId => userData.contacts[contactId]?.type === 'contact' && userData.contacts[contactId]?.delete === false);
      const filteredIds = contactIds.filter(contactId => {
        const contact = userData.contacts[contactId];
        const query = searchQuery.toLowerCase();
        return (
          contact.contactName.toLowerCase().includes(query) ||
          contact.companyName.toLowerCase().includes(query) ||
          contact.activity.toLowerCase().includes(query) ||
          contact.position.toLowerCase().includes(query) ||
          String(contact.status).includes(query) ||
          Object.keys(statusMapping).some(key => key.includes(query) && statusMapping[key] === contact.status)
        );
      });
      setFilteredContactIds(filteredIds);
    }
  }, [searchQuery, userData, list]);

  const totalContacts = filteredContactIds.length;

  const deleteEvent = () => {
    const monitorExist = Object.values(userData.monitors || {}).some(monitor => monitor.list === id && monitor.delete === false);
    console.log(`monitorExist: ${monitorExist}`);
    if (monitorExist) {
      notify(`Cannot delete list: ${list.name} because it is being monitored.`, 3);
      return;
    }

    confirm(`Are you sure you want delete this list?`, () => {
      uploadData(`lists/${list.id}/delete`, true)
        .then(() => {
          notify(`List: ${list.name} has been deleted.`, 1);
          history.push('/list');
        })
        .catch(() => {
          notify(`Failed to delete list: ${list.name}. Please try again.`, 3);
        });
    }, () => {
      console.log('cancel');
    });
  };

  return (
    <div className="list-details-container">
      <Helmet>
        <title>List-Details - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="List-Details - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="list-details-main">
        <MenuBar list={true}></MenuBar>
        <div className="list-details-content">
          <TopBar lable="List" lable2="Details"></TopBar>
          <div className="list-details-content1">
            <div className="list-details-container01">
              <div className="list-details-container02">
                <Link to="/list" className="list-details-navlink">
                  <div className="list-details-container03 btn-main-white">
                    <svg viewBox="0 0 1024 1024" className="list-details-icon">
                      <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                    </svg>
                    <span>Back</span>
                  </div>
                </Link>
                <Link to="/list-upload-list" className="list-details-navlink1">
                  <div className="list-details-container04">
                    <svg viewBox="0 0 1024 1024" className="list-details-icon2">
                      <path d="M214 768h596v86h-596v-86zM384 682v-256h-170l298-298 298 298h-170v256h-256z"></path>
                    </svg>
                    <span className="list-details-text01">Upload List</span>
                  </div>
                </Link>
              </div>
              <div onClick={deleteEvent} className="list-details-container05">
                <svg viewBox="0 0 1024 1024" className="list-details-icon4">
                  <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667z"></path>
                </svg>
                <span>Delete List</span>
              </div>
            </div>
            <div className="list-details-container06">
              <div className="list-details-container07">
                <span className="list-details-text03">{list.name}</span>
                <span className="list-details-text04">
                  <span> Total contacts: </span>
                  <span className="list-details-text06">{totalContacts}</span>
                </span>
                <span className="list-details-text07">
                  <span className="list-details-text08">Created on:</span>
                  <span className="list-details-text09"> {createdDate}</span>
                </span>
              </div>
              <div className="list-details-container08">
                <div className="list-details-container09">
                  <svg viewBox="0 0 1024 1024" className="list-details-icon6">
                    <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                  </svg>
                  <input
                    type="text"
                    id="4000"
                    placeholder="Contact, Company, Activity or Position"
                    className="list-details-list-details-list-name-find"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="list-details-container10">
              <div className="list-details-container11">
                <div className="list-details-container12">
                  <span className="list-details-text12">Contact / Company</span>
                </div>
                <div className="list-details-container13">
                  <span className="list-details-text13">Activity / Position</span>
                </div>
                <div className="list-details-container14">
                  <span className="list-details-text14">Data / Status</span>
                </div>
                <div className="list-details-container15"></div>
              </div>
              <ul className="list-details-ul list">
                {filteredContactIds.map((id, index) => (
                  <li key={index} className="list-item">
                    <ListDetailsListNameItem id={id}></ListDetailsListNameItem>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  );
};

export default ListDetails;
