import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MenuBar from '../components/menu-bar';
import TopBar from '../components/top-bar';
import MonitorDetailsComponent from '../components/monitor-details-component';
import POPUP from '../components/popup';
import './monitor-details.css';
import { useData } from '../contexts/DataContext';
import { confirm } from '../components/Confirmation';
import { notify } from '../components/Notification';

const MonitorDetails = (props) => {
  const { userData, uploadData } = useData();

  const history = useHistory();
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_listId = queryParams.get('id');

  const deleteEvent = () => {
    confirm(`Are you sure you want to delete: ${userData.monitors[id].monitorName}?`, () => {
      uploadData(`monitors/${id}/delete`, true)
      notify(`Monitor ${userData.monitors[id].name} deleted successfully`, 1);
      history.push('/monitor'); // Weiterleitung zur /monitor Seite nach erfolgreichem Löschen
    });
  };

  return (
    <div className="monitor-details-container">
      <Helmet>
        <title>Monitor-Details - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="Monitor-Details - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="monitor-details-main">
        <MenuBar monitor={true}></MenuBar>
        <div className="monitor-details-content">
          <TopBar lable="Monitor" lable2="Details"></TopBar>
          <div className="monitor-details-content1">
            <div className="monitor-details-container1">
              <div className="monitor-details-container2">
                <Link to="/monitor" className="monitor-details-navlink">
                  <div className="monitor-details-container3">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="monitor-details-icon"
                    >
                      <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                    </svg>
                    <span className="monitor-details-text">Back</span>
                  </div>
                </Link>
                <Link to="/monitor-create" className="monitor-details-navlink1">
                  <div className="monitor-details-container4">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="monitor-details-icon2"
                    >
                      <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                    </svg>
                    <span className="monitor-details-text1">
                      Create Monitor
                    </span>
                  </div>
                </Link>
              </div>

              <div onClick={deleteEvent} className="btn-delete">
                <svg
                  viewBox="0 0 1024 1024"
                  className="monitor-details-icon4"
                >
                  <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                </svg>
                <span className="monitor-details-text2">Delete monitor</span>
              </div>

            </div>
            <MonitorDetailsComponent rootClassName="monitor-details-component-root-class-name"></MonitorDetailsComponent>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default MonitorDetails
