import React, { useState } from 'react';
import ToggleBox from './toggle-box';
import './settings-overview.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const SettingsOverview = (props) => {
  const { userData, uploadData } = useData();
  const [firstName, setFirstName] = useState(userData?.profile?.firstName || '');
  const [lastName, setLastName] = useState(userData?.profile?.lastName || '');
  const [email, setEmail] = useState(userData?.profile?.email || '');
  const [phone, setPhone] = useState(userData?.profile?.phone || '');
  const [linkedinUrl, setLinkedinUrl] = useState(userData?.profile?.linkedinUrl || '');
  const [companyName, setCompanyName] = useState(userData?.profile?.company?.name || '');
  const [country, setCountry] = useState(userData?.profile?.company?.country || '');
  const [cityState, setCityState] = useState(userData?.profile?.company?.cityState || '');
  const [postalCode, setPostalCode] = useState(userData?.profile?.company?.postalCode || '');
  const [taxId, setTaxId] = useState(userData?.profile?.company?.taxId || '');
  const [supportContact1, setSupportContact1] = useState(userData?.profile?.supports?.contact_1 || {});
  const [supportContact2, setSupportContact2] = useState(userData?.profile?.supports?.contact_2 || {});
  const [hasNotified, setHasNotified] = useState(false);

  const inputChange = (setter) => (e) => {
    if (!hasNotified) {
      notify('Please remember to save your changes.', 2);
      setHasNotified(true);
    }
    setter(e.target.value);
  };

  const handleToggle = (contact, key) => {
    if (!hasNotified) {
      notify('Please remember to save your changes.', 2);
      setHasNotified(true);
    }
    if (contact === 1) {
      setSupportContact1((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (contact === 2) {
      setSupportContact2((prev) => ({ ...prev, [key]: !prev[key] }));
    }
  };

  const handleSave = async () => {
    const profileData = {
      firstName: firstName || userData?.profile?.firstName || '',
      lastName: lastName || userData?.profile?.lastName || '',
      email: email || userData?.profile?.email || '',
      phone: phone || userData?.profile?.phone || '',
      linkedinUrl: linkedinUrl || userData?.profile?.linkedinUrl || ''
    };

    const companyData = {
      name: companyName || userData?.profile?.company?.name || '',
      country: country || userData?.profile?.company?.country || '',
      cityState: cityState || userData?.profile?.company?.cityState || '',
      postalCode: postalCode || userData?.profile?.company?.postalCode || '',
      taxId: taxId || userData?.profile?.company?.taxId || '',
      linkedinUrl: linkedinUrl || userData?.profile?.linkedinUrl || ''
    };

    const supports = {
      contact_1: {
        firstName: supportContact1.firstName || userData?.profile?.supports?.contact_1?.firstName || '',
        lastName: supportContact1.lastName || userData?.profile?.supports?.contact_1?.lastName || '',
        email: supportContact1.email || userData?.profile?.supports?.contact_1?.email || '',
        receiveNotification: supportContact1.receiveNotification !== undefined ? supportContact1.receiveNotification : userData?.profile?.supports?.contact_1?.receiveNotification || false,
        dashboardAccess: supportContact1.dashboardAccess !== undefined ? supportContact1.dashboardAccess : userData?.profile?.supports?.contact_1?.dashboardAccess || false,
      },
      contact_2: {
        firstName: supportContact2.firstName || userData?.profile?.supports?.contact_2?.firstName || '',
        lastName: supportContact2.lastName || userData?.profile?.supports?.contact_2?.lastName || '',
        email: supportContact2.email || userData?.profile?.supports?.contact_2?.email || '',
        receiveNotification: supportContact2.receiveNotification !== undefined ? supportContact2.receiveNotification : userData?.profile?.supports?.contact_2?.receiveNotification || false,
        dashboardAccess: supportContact2.dashboardAccess !== undefined ? supportContact2.dashboardAccess : userData?.profile?.supports?.contact_2?.dashboardAccess || false,
      }
    };

    try {
      await uploadData('profile', profileData);
      await uploadData('profile/company', companyData);
      await uploadData('profile/supports', supports);
      notify('Profile data updated successfully', 1);
    } catch (error) {
      console.error('Error uploading data', error);
      notify('Error uploading data', 3);
    }
  };

  return (
    <div className="settings-overview-settings-overview-account-owner-company-info">
      <div className="settings-overview-container">
        <div className="settings-overview-container01">
          <span className="settings-overview-text">Account Owner</span>
          <span className="info-txt">
            Enter the information about the account owner. This includes details such as the owner's name, contact information, and any other relevant data required for account management.
          </span>
        </div>
        <div className="settings-overview-container02">
          <div className="settings-overview-container03">
            <input
              type="text"
              id="8000"
              placeholder="First Name"
              className="settings-overview-input"
              value={firstName}
              onChange={inputChange(setFirstName)}
            />
          </div>
          <div className="settings-overview-container04">
            <input
              type="text"
              id="8001"
              placeholder="Last name"
              className="settings-overview-input"
              value={lastName}
              onChange={inputChange(setLastName)}
            />
          </div>
          <div className="settings-overview-container05">
            <input
              type="text"
              id="8002"
              placeholder="Email"
              className="settings-overview-input"
              value={email}
              onChange={inputChange(setEmail)}
            />
          </div>
          <div className="settings-overview-container06">
            <input
              type="text"
              id="8003"
              placeholder="Phone"
              className="settings-overview-input"
              value={phone}
              onChange={inputChange(setPhone)}
            />
          </div>
          <div className="settings-overview-container07">
            <input
              type="text"
              id="8004"
              placeholder="LinkedIn Url"
              className="settings-overview-input"
              value={linkedinUrl}
              onChange={inputChange(setLinkedinUrl)}
            />
          </div>
        </div>
      </div>
      <div className="settings-overview-container08">
        <div className="settings-overview-container09">
          <span className="settings-overview-text02">Company Info</span>
          <span className="info-txt">
            Enter the information about the company. This includes details such as the company name, address, contact information, and any other relevant data needed for company identification and communication.
          </span>
        </div>
        <div className="settings-overview-container10">
          <div className="settings-overview-container11">
            <input
              type="text"
              id="8006"
              placeholder="Company Name"
              className="settings-overview-input"
              value={companyName}
              onChange={inputChange(setCompanyName)}
            />
          </div>
          <div className="settings-overview-container12">
            <input
              type="text"
              id="8008"
              placeholder="Country"
              className="settings-overview-input"
              value={country}
              onChange={inputChange(setCountry)}
            />
          </div>
          <div className="settings-overview-container13">
            <input
              type="text"
              id="8009"
              placeholder="City / State"
              className="settings-overview-input"
              value={cityState}
              onChange={inputChange(setCityState)}
            />
          </div>
          <div className="settings-overview-container14">
            <input
              type="text"
              id="8010"
              placeholder="Postal Code"
              className="settings-overview-input"
              value={postalCode}
              onChange={inputChange(setPostalCode)}
            />
          </div>
          <div className="settings-overview-container15">
            <input
              type="text"
              id="8011"
              placeholder="TAX ID"
              className="settings-overview-input"
              value={taxId}
              onChange={inputChange(setTaxId)}
            />
          </div>
          <div className="settings-overview-container16">
            <input
              type="text"
              id="8007"
              placeholder="LinkedIn Url"
              className="settings-overview-input"
              value={linkedinUrl}
              onChange={inputChange(setLinkedinUrl)}
            />
          </div>
        </div>
      </div>
      <div className="settings-overview-container17">
        <div className="settings-overview-container18">
          <span className="settings-overview-text04">Support Contact - 1</span>
          <span className="info-txt">
            Enter the information for the support contact. This includes details such as the contact name, phone number, email address, and any other relevant data required to provide support and assistance.
          </span>
        </div>
        <div className="settings-overview-container19">
          <div className="settings-overview-container20">
            <input
              type="text"
              id="8012"
              placeholder="First Name"
              className="settings-overview-input"
              value={supportContact1.firstName}
              onChange={inputChange((value) => setSupportContact1({ ...supportContact1, firstName: value }))}
            />
          </div>
          <div className="settings-overview-container21">
            <input
              type="text"
              id="8013"
              placeholder="Last name"
              className="settings-overview-input"
              value={supportContact1.lastName}
              onChange={inputChange((value) => setSupportContact1({ ...supportContact1, lastName: value }))}
            />
          </div>
          <div className="settings-overview-container22">
            <input
              type="text"
              id="8014"
              placeholder="Email"
              className="settings-overview-input"
              value={supportContact1.email}
              onChange={inputChange((value) => setSupportContact1({ ...supportContact1, email: value }))}
            />
          </div>
          <div className="settings-overview-container23">
            <div className="settings-overview-container24">
              <div className="settings-overview-container25">
                <span className="settings-overview-text06">Receive notification</span>
                <span className="info-txt">
                  Turn on this trigger if the support contact should receive system admin notifications.
                </span>
              </div>
              <div onClick={() => handleToggle(1, 'receiveNotification')}>
                <ToggleBox
                  test={'supportContact1.receiveNotification'}
                  value={supportContact1.receiveNotification}
                />
              </div>
            </div>
            <div className="settings-overview-container26">
              <div className="settings-overview-container27">
                <span className="settings-overview-text08">Dashboard Access</span>
                <span className="info-txt">
                  Turn on this trigger if the support contact should have access to the dashboard.
                </span>
              </div>
              <div onClick={() => handleToggle(1, 'dashboardAccess')}>
                <ToggleBox
                  test={'supportContact1.dashboardAccess'}
                  value={supportContact1.dashboardAccess}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-overview-container28">
        <div className="settings-overview-container29">
          <span className="settings-overview-text10">Support Contact - 2</span>
          <span className="info-txt">
            Enter the information for the support contact. This includes details such as the contact name, phone number, email address, and any other relevant data required to provide support and assistance.
          </span>
        </div>
        <div className="settings-overview-container30">
          <div className="settings-overview-container31">
            <input
              type="text"
              id="8015"
              placeholder="First Name"
              className="settings-overview-input"
              value={supportContact2.firstName}
              onChange={inputChange((value) => setSupportContact2({ ...supportContact2, firstName: value }))}
            />
          </div>
          <div className="settings-overview-container32">
            <input
              type="text"
              id="8016"
              placeholder="Last name"
              className="settings-overview-input"
              value={supportContact2.lastName}
              onChange={inputChange((value) => setSupportContact2({ ...supportContact2, lastName: value }))}
            />
          </div>
          <div className="settings-overview-container33">
            <input
              type="text"
              id="8017"
              placeholder="Email"
              className="settings-overview-input"
              value={supportContact2.email}
              onChange={inputChange((value) => setSupportContact2({ ...supportContact2, email: value }))}
            />
          </div>
          <div className="settings-overview-container34">
            <div className="settings-overview-container35">
              <div className="settings-overview-container36">
                <span className="settings-overview-text12">Receive notification</span>
                <span className="info-txt">
                  Turn on this trigger if the support contact should receive system admin notifications.
                </span>
              </div>
              <div onClick={() => handleToggle(2, 'receiveNotification')}>
                <ToggleBox
                  test={'supportContact2.receiveNotification'}
                  value={supportContact2.receiveNotification}
                />
              </div>
            </div>
            <div className="settings-overview-container37">
              <div className="settings-overview-container38">
                <span className="settings-overview-text14">Dashboard Access</span>
                <span className="info-txt">
                  Turn on this trigger if the support contact should have access to the dashboard.
                </span>
              </div>
              <div onClick={() => handleToggle(2, 'dashboardAccess')}>
                <ToggleBox
                  test={'supportContact2.dashboardAccess'}
                  value={supportContact2.dashboardAccess}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-overview-container39">
        <div className="settings-overview-container40 btn-main-white">
          <svg viewBox="0 0 1024 1024" className="settings-overview-icon">
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="settings-overview-text16">Cancel</span>
        </div>
        <div className="settings-overview-container41 btn-main-white" onClick={handleSave}>
          <svg
            viewBox="0 0 877.7142857142857 1024"
            className="settings-overview-icon2"
          >
            <path d="M219.429 877.714h438.857v-219.429h-438.857v219.429zM731.429 877.714h73.143v-512c0-10.857-9.714-34.286-17.143-41.714l-160.571-160.571c-8-8-30.286-17.143-41.714-17.143v237.714c0 30.286-24.571 54.857-54.857 54.857h-329.143c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-73.143v731.429h73.143v-237.714c0-30.286 24.571-54.857 54.857-54.857h475.429c30.286 0 54.857 24.571 54.857 54.857v237.714zM512 347.429v-182.857c0-9.714-8.571-18.286-18.286-18.286h-109.714c-9.714 0-18.286 8.571-18.286 18.286v182.857c0 9.714 8.571 18.286 18.286 18.286h109.714c9.714 0 18.286-8.571 18.286-18.286zM877.714 365.714v530.286c0 30.286-24.571 54.857-54.857 54.857h-768c-30.286 0-54.857-24.571-54.857-54.857v-768c0-30.286 24.571-54.857 54.857-54.857h530.286c30.286 0 72 17.143 93.714 38.857l160 160c21.714 21.714 38.857 63.429 38.857 93.714z"></path>
          </svg>
          <span className="settings-overview-text17">Save</span>
        </div>
      </div>
    </div>
  );
};

export default SettingsOverview;
