import React from 'react'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import DashboardOverviewTotalContacts from '../components/dashboard-overview-total-contacts'
import DashboardOverviewPreferenceContacts from '../components/dashboard-overview-preference-contacts'
import BillingOverviewCosts from '../components/billing-overview-costs'
import DashboardOverviewPreferenceContactsList from '../components/dashboard-overview-preference-contacts-list'
import POPUP from '../components/popup'
import './dashboard.css'

const Dashboard = (props) => {
  return (
    <div className="dashboard-container">
      <Helmet>
        <title>Dashboard - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Dashboard - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="dashboard-main">
        <MenuBar dashboard={true} ></MenuBar>
        <div className="dashboard-content">
          <TopBar></TopBar>
          <div className="dashboard-content1">
            <div className="dashboard-container1">
              <DashboardOverviewTotalContacts></DashboardOverviewTotalContacts>
              <DashboardOverviewPreferenceContacts></DashboardOverviewPreferenceContacts>
              <BillingOverviewCosts rootClassName="billing-overview-costs-root-class-name"></BillingOverviewCosts>
            </div>
            <DashboardOverviewPreferenceContactsList></DashboardOverviewPreferenceContactsList>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
