import React from 'react'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

import './channels-group-item.css'

import { useData } from '../contexts/DataContext'

const ChannelsGroupItem = (props) => {
  const { userData } = useData()
  const history = useHistory();

  const id = props.id

  const name = userData?.channelGroups[id]?.name || "Channel Name"
  const notification = userData?.channelGroups[id]?.notificationCount || 0
  const contacts = userData?.channelGroups[id]?.contacts || "-"
  const created = userData?.channelGroups[id]?.created ? new Date(parseInt(userData.channelGroups[id].created)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';

  const handleEditClick = () => {
    history.push(`/channels-create-group?id=${id}`);
  };

  return (
    <div className="channels-group-item-channels-overview-channel-groups-item">
      <div className="channels-group-item-container">
        <span>{name}</span>
        <svg onClick={handleEditClick} viewBox="0 0 1024 1024" className="channels-group-item-icon">
          <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
        </svg>
      </div>
      <div className="channels-group-item-container1">
        <div className="channels-group-item-container2">
          <div className="channels-group-item-container3">
            <span className="channels-group-item-text1">
              Create on:
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="channels-group-item-text2">{created}</span>
          </div>
          <div className="channels-group-item-container4">
            <span className="channels-group-item-text3">Contacts</span>
            <span className="channels-group-item-text4">{contacts.length}</span>
          </div>
        </div>
        <div className="channels-group-item-container5">
          <span className="channels-group-item-text5">Notifications: </span>
          <span className="channels-group-item-text6">
            {notification}
          </span>
        </div>
      </div>
    </div>
  )
}

ChannelsGroupItem.defaultProps = {
  name: 'Channel Name',
  notification: '296',
  contacts: '111',
  data: '01.01.2024',
}

ChannelsGroupItem.propTypes = {
  name: PropTypes.string,
  notification: PropTypes.string,
  contacts: PropTypes.string,
  data: PropTypes.string,
}

export default ChannelsGroupItem
