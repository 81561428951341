import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import EventsDetailsEventItem from './events-details-event-item';
import EventsDetailsParticipantItem from './events-details-participant-item';
import './events-details-component.css';
import { useData } from '../contexts/DataContext';

const EventsDetailsComponent = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const { userData, uploadData } = useData();

  const [viewList, setViewList] = useState(0);
  const monitor = userData.eventMonitors[id];
  const status = monitor.status;

  const totalFound = monitor.totalFound || 0;
  const eventsCount = monitor.events ? Object.keys(monitor.events).length : 0;
  const eventsPercentage = totalFound > 0 ? (eventsCount / totalFound) * 100 : 0;

  let contacts = [];
  if (monitor.events) {
    Object.keys(monitor.events).forEach((key) => {
      if(monitor.events[key].contacts){

        if (monitor.events[key].scan) {
          contacts = [...monitor.events[key].contacts, ...contacts];
        }
      }
    });
  }

  console.log(monitor);
  console.log(contacts);

  const selectedEvents = monitor.events ? Object.keys(monitor.events).filter(id => monitor.events[id].scan).length : 0;

  const totalContacts = monitor.contacts?.length || 0;
  const contactedContacts = monitor.contacts?.filter(contactId => userData.contacts[contactId].messages && userData.contacts[contactId].messages.length > 0).length;
  const contactedPercentage = totalContacts > 0 ? (contactedContacts / totalContacts) * 100 : 0;

  const totalEvents = Object.values(monitor.events || {}).filter(obj => obj.scan).length || 0;
  const joinedEvents = Object.values(monitor.events || {}).filter(obj => obj.joined).length || 0;
  const scanPercentage = totalEvents > 0 ? (joinedEvents / totalEvents) * 100 : 0;

  const stopMonitor = () => {
    uploadData(`eventMonitors/${id}/status`, 0);
  };

  const startMonitor = () => {
    uploadData(`eventMonitors/${id}/status`, 1);
  };

  return (
    <div className={`events-details-component-monitor-details-component ${props.rootClassName} `}>
      <div className="events-details-component-container">
        <div className="events-details-component-container01 border-bottom">
          <div className="events-details-component-container02">
            <div className="events-details-component-container03">
              <span className="events-details-component-text">
                {monitor.name || '-'}
              </span>
              <div className="events-details-component-container04">
                <span className="events-details-component-text01">ID: </span>
                <span className="events-details-component-text02">
                  {id}
                </span>
              </div>
            </div>
            <div className="events-details-component-container05">
              <div className="events-details-component-container06">
                <span className="events-details-component-text03">Events: </span>
                <span className="events-details-component-text04">
                  {eventsCount}
                </span>
              </div>
              <div className="events-details-component-container07">
                <div className="events-details-component-container08">
                  <span className="events-details-component-text05">
                    Total Events: 
                  </span>
                  <span className="events-details-component-text06">
                    {totalFound}
                  </span>
                </div>
              </div>
            </div>
            <div className="events-details-component-container09"></div>
            <div className="events-details-component-container10">
              {status === 1 && (
                <div className="events-details-component-container11">
                  <div className="events-details-component-container12"></div>
                  <span className="events-details-component-text07">
                    Running
                  </span>
                </div>
              )}
              {status === 2 && (
                <div className="events-details-component-container13">
                  <div className="events-details-component-container14"></div>
                  <span className="events-details-component-text08">
                    Initialization
                  </span>
                </div>
              )}
              {status === 0 && (
                <div className="events-details-component-container15">
                  <div className="events-details-component-container16"></div>
                  <span className="events-details-component-text09">Stop</span>
                </div>
              )}
              {status === 3 && (
                <div className="events-details-component-container17">
                  <div className="events-details-component-container18"></div>
                  <span className="events-details-component-text10">Error</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="events-details-component-container19">
          <span className="events-details-component-text11">
            Performance
          </span>
          <span className="events-details-component-text12">
            Scan per Event: 48s
          </span>
        </div>
        <div className="events-details-component-container20">
          <div className="events-details-component-container21">
            <div className="events-details-component-container22">
              <span className="events-details-component-text13">
                Events scanned
              </span>
              <div className="events-details-component-container23">
                <span className="events-details-component-text14">
                  {eventsPercentage.toFixed(2) + '%'}
                </span>
              </div>
            </div>
            <div className="events-details-component-container24">
              <div style={{ width: eventsPercentage.toFixed(2) + '%' }} className="events-details-component-container25"></div>
            </div>
          </div>
          <div className="events-details-component-container26">
            <div className="events-details-component-container27">
              <span className="events-details-component-text15">
                Contacted contacts
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <div className="events-details-component-container28">
                <span className="events-details-component-text16">
                  {contactedPercentage.toFixed(2) + '%'}
                </span>
              </div>
            </div>
            <div className="events-details-component-container29">
              <div style={{ width: contactedPercentage.toFixed(2) + '%' }} className="events-details-component-container30"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="events-details-component-container31">
        <div className="events-details-component-container32">
          <div className="events-details-component-container33">
            {viewList === 0 && (
              <div className="events-details-component-container34">
                <div className="events-details-component-container35">
                  <span className="events-details-component-text17">
                    Event list
                  </span>
                </div>
                <div
                  onClick={() => setViewList(1)}
                  className="events-details-component-container36"
                >
                  <span className="events-details-component-text18">
                    Contacts
                  </span>
                </div>
              </div>
            )}
            {viewList === 1 && (
              <div className="events-details-component-container37">
                <div
                  onClick={() => setViewList(0)}
                  className="events-details-component-container38"
                >
                  <span className="events-details-component-text19">
                    Event list
                  </span>
                </div>
                <div className="events-details-component-container39">
                  <span className="events-details-component-text20">
                    Contacts
                  </span>
                </div>
              </div>
            )}
            {viewList === 0 && (
              <div className="events-details-component-container40">
                <span className="events-details-component-text21">
                  {selectedEvents}
                </span>
                <span className="events-details-component-text22">
                  {' '}
                   total events
                </span>
              </div>
            )}
            {viewList === 1 && (
              <div className="events-details-component-container40">
                <span className="events-details-component-text21">
                  {contacts.length}
                </span>
                <span className="events-details-component-text22">
                  {' '}
                   total contacts
                </span>
              </div>
            )}
          </div>
          <div className="events-details-component-container41">
            <svg
              viewBox="0 0 1024 1024"
              className="events-details-component-icon"
            >
              <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
            <input
              type="text"
              placeholder="Event, Company or Date"
              className="events-details-component-monitor-details-contacts-find"
            />
          </div>
        </div>
        {viewList === 0 && (
          <div className="events-details-component-container42">
            <div className="events-details-component-container43">
              <div className="events-details-component-container44">
                <span className="events-details-component-text23">
                  Event / Company
                </span>
              </div>
              <div className="events-details-component-container45">
                <span className="events-details-component-text24">
                  Participants
                </span>
              </div>
              <div className="events-details-component-container46">
                <span className="events-details-component-text25">Date</span>
              </div>
              <div className="events-details-component-container47"></div>
            </div>
            <ul className="events-details-component-ul">
              {Object.keys(monitor.events || {}).map((_id, index) => (
                <li key={index} className="list-item">
                  <EventsDetailsEventItem id={_id} monitorId={id} rootClassName="events-details-event-item-root-class-name"></EventsDetailsEventItem>
                </li>
              ))}
            </ul>
            <div className="events-details-component-container48">
              <div className="events-details-component-container49"></div>
              <div className="events-details-component-container50"></div>
              <div className="events-details-component-container51"></div>
              <div className="events-details-component-container52"></div>
              <div className="events-details-component-container53"></div>
              <div className="events-details-component-container54"></div>
              <div className="events-details-component-container55"></div>
            </div>
          </div>
        )}
        {viewList === 1 && (
          <div className="events-details-component-container56">
            <div className="events-details-component-container57">
              <div className="events-details-component-container58">
                <span className="events-details-component-text26">
                  Contact / Company
                </span>
              </div>
              <div className="events-details-component-container59">
                <span className="events-details-component-text27">Event</span>
              </div>
              <div className="events-details-component-container60">
                <span className="events-details-component-text28">
                  Activity / Position
                </span>
              </div>
              <div className="events-details-component-container61">
              </div>
            </div>
            <ul className="events-details-component-ul1">
              {contacts.map((_id, index) => (
                <li key={index} className="list-item">
                  <EventsDetailsParticipantItem id={_id} monitorID={id} rootClassName="events-details-participant-item-root-class-name"></EventsDetailsParticipantItem>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="events-details-component-container62">
        <div className="events-details-component-container63">
          {status === 0 && (
            <div onClick={startMonitor} className="events-details-component-container64">
              <svg
                viewBox="0 0 1024 1024"
                className="events-details-component-icon02"
              >
                <path d="M342 214l468 298-468 298v-596z"></path>
              </svg>
              <span className="events-details-component-text30">
                Start monitor
              </span>
            </div>
          )}
          {status === 1 && (
            <div onClick={stopMonitor} className="events-details-component-container65">
              <svg
                viewBox="0 0 1024 1024"
                className="events-details-component-icon04"
              >
                <path d="M256 256h512v512h-512v-512z"></path>
              </svg>
              <span className="events-details-component-text31">
                Stop monitor
              </span>
            </div>
          )}
        </div>
        <div className="events-details-component-container66">
          <Link to="/construction" className="events-details-component-navlink">
            <div className="events-details-component-container67">
              <svg
                viewBox="0 0 1024 1024"
                className="events-details-component-icon06"
              >
                <path d="M969.003 417.963c-1.749-1.749-173.013-174.763-255.232-262.229-17.92-17.664-42.624-27.733-67.669-27.733-50.432 0-91.435 38.229-91.435 85.333h-341.333c-23.595 0-42.667 19.115-42.667 42.667v597.333c0 23.552 19.072 42.667 42.667 42.667h597.333c23.595 0 42.667-19.115 42.667-42.667v-257.92c61.184-62.336 114.688-116.437 115.669-117.376 16.512-16.64 16.512-43.435 0-60.075zM651.904 680.149c-0.939 1.152-2.773 2.517-5.803 2.517-3.157 0-5.163-0.981-6.144-1.707v-126.293-42.667h-42.667c-75.563 1.451-142.336 29.013-202.795 83.541 18.347-94.507 68.267-211.541 202.795-211.541h42.667v-168.875c1.195-0.896 3.029-1.792 6.144-1.792 3.84 0 6.485 2.133 6.571 2.133 61.269 65.067 172.843 178.645 226.005 232.533-10.795 10.965-185.259 188.672-226.773 232.149zM256 810.667v-512h341.333c0 14.848 0 29.653 0 42.667-198.827 0-256 207.829-256 362.667v21.333c72.149-109.995 153.6-168.661 256-170.667 0 44.587 0 121.088 0 128 0 23.509 21.845 42.667 48.768 42.667 15.531 0 28.843-6.741 37.675-16.683 22.997-24.107 52.992-55.083 84.309-87.168v189.184h-512.085z"></path>
              </svg>
              <span className="events-details-component-text32">Export</span>
            </div>
          </Link>
          <Link to="/log" className="events-details-component-navlink1">
            <div className="events-details-component-container68">
              <svg
                viewBox="0 0 1024 1024"
                className="events-details-component-icon08"
              >
                <path d="M725.333 896h-426.667c-70.571 0-128-57.429-128-128v-512c0-70.571 57.429-128 128-128h426.667c70.571 0 128 57.429 128 128v512c0 70.571-57.429 128-128 128zM298.667 213.333c-23.509 0-42.667 19.157-42.667 42.667v512c0 23.509 19.157 42.667 42.667 42.667h426.667c23.509 0 42.667-19.157 42.667-42.667v-512c0-23.509-19.157-42.667-42.667-42.667h-426.667z"></path>
                <path d="M682.667 469.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"></path>
                <path d="M682.667 341.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"></path>
                <path d="M682.667 597.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"></path>
                <path d="M682.667 725.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"></path>
              </svg>
              <span>Log</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

EventsDetailsComponent.defaultProps = {
  contactSize: '634',
  rootClassName: '',
  eventsPreferences: '100%',
  eventName: 'Event',
  totalContacts1: '8.327 ',
  idNumber: '780183-bfx32',
  totalContacts: '8.327 ',
  contactPreferences: '100%',
}

EventsDetailsComponent.propTypes = {
  contactSize: PropTypes.string,
  rootClassName: PropTypes.string,
  eventsPreferences: PropTypes.string,
  eventName: PropTypes.string,
  totalContacts1: PropTypes.string,
  idNumber: PropTypes.string,
  totalContacts: PropTypes.string,
  contactPreferences: PropTypes.string,
}

export default EventsDetailsComponent;
