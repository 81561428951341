import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './settings-cookie-group-item.css'
import { useData } from '../contexts/DataContext';

const SettingsCookieGroupItem = (props) => {
  const [check, setCheck] = useState(false)
  const { userData } = useData();

  const id = props.id
  const cookieGroups = userData.cookieGroups[id]
  return (
    <div
      onClick={() => setCheck(!check)}
      className="settings-cookie-group-item-settings-cookie-group"
    >
      <div className="settings-cookie-group-item-container">
        <div className="settings-cookie-group-item-container1 preference-checkBox">
          {check && (
            <div className="settings-cookie-group-item-container2">
              <svg
                viewBox="0 0 1024 1024"
                className="settings-cookie-group-item-icon"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="settings-cookie-group-item-container3">
        <div className="settings-cookie-group-item-container4">
          <span>{cookieGroups.name}</span>
          <div className="settings-cookie-group-item-container5">
            <span className="settings-cookie-group-item-text1">Used:</span>
            <span className="settings-cookie-group-item-text2">
              {cookieGroups.used}
            </span>
          </div>
        </div>
      </div>
      <div className="settings-cookie-group-item-container6">
        <span className="settings-cookie-group-item-text3">
          {cookieGroups.cookies?.length || '-'}
        </span>
      </div>
    </div>
  )
}

SettingsCookieGroupItem.defaultProps = {
  rootClassName: '',
  groupName: 'Group name',
  used: '99',
  cookieSize: '99',
}

SettingsCookieGroupItem.propTypes = {
  rootClassName: PropTypes.string,
  groupName: PropTypes.string,
  used: PropTypes.string,
  cookieSize: PropTypes.string,
}

export default SettingsCookieGroupItem
