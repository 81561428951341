import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import './list-contact.css'

const ListContact = (props) => {
  const location = useLocation();

  // Extrahiere den Query-Parameter `contactId`
  const queryParams = new URLSearchParams(location.search);
  const contactId = queryParams.get('contactId');

  console.log('+++');
  console.log(contactId);
  return (
    <div className="list-contact-container">
      <Helmet>
        <title>List-Contact - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="List-Contact - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="list-contact-main">
        <MenuBar list={true}></MenuBar>
        <div className="list-contact-content">
          <TopBar lable="List" lable2="Contact"></TopBar>
          <div className="list-contact-content1">
            <div className="list-contact-container01">
              <div className="list-contact-container02">
                <Link to="/list-details" className="list-contact-navlink">
                  <div className="list-contact-container03 btn-main-white">
                    <svg viewBox="0 0 1024 1024" className="list-contact-icon">
                      <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                    </svg>
                    <span>Back</span>
                  </div>
                </Link>
              </div>
              <Link to="/construction" className="list-contact-navlink1">
                <div className="list-contact-container04">
                  <svg viewBox="0 0 1024 1024" className="list-contact-icon2">
                    <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                  </svg>
                  <span>Delete Contact</span>
                </div>
              </Link>
            </div>
            <div className="list-contact-container05">
              <div className="list-contact-container06">
                <div className="list-contact-container07">
                  <span className="list-contact-text02">Profile Info</span>
                  <span className="list-contact-text03">
                    Select all the preferences you want to set on this monitor.
                  </span>
                </div>
                <div className="list-contact-container08">
                  <div className="list-contact-container09">
                    <img
                      alt="image"
                      src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"
                      className="list-contact-image"
                    />
                  </div>
                  <div className="list-contact-container10">
                    <div className="list-contact-container11">
                      <span className="list-contact-text04">
                        Activity / Position
                      </span>
                      <span className="list-contact-text05">
                        Activity / Position
                      </span>
                    </div>
                    <div className="list-contact-container12">
                      <span className="list-contact-text06">Data / Status</span>
                      <span className="list-contact-text07">Data / Status</span>
                    </div>
                    <div className="list-contact-container13">
                      <span className="list-contact-text08">Description</span>
                      <span className="list-contact-text09">
                        Profile description here...
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list-contact-container14">
                <div className="list-contact-container15">
                  <span className="list-contact-text10">Contact Details</span>
                  <span className="list-contact-text11">
                    Select all the preferences you want to set on this monitor.
                  </span>
                </div>
                <div className="list-contact-container16">
                  <div className="list-contact-container17">
                    <input
                      type="text"
                      id="4001"
                      placeholder="First Name"
                      className="list-contact-list-contact-contact-details-first-name"
                    />
                  </div>
                  <div className="list-contact-container18">
                    <input
                      type="text"
                      id="4002"
                      placeholder="Last Name"
                      className="list-contact-list-contact-contact-details-last-name"
                    />
                  </div>
                  <div className="list-contact-container19">
                    <input
                      type="text"
                      id="4003"
                      placeholder="LinkedIn Url"
                      className="list-contact-list-contact-contact-details-linkedin-url"
                    />
                  </div>
                </div>
              </div>
              <div className="list-contact-container20">
                <div className="list-contact-container21">
                  <span className="list-contact-text12">Company Details</span>
                  <span className="list-contact-text13">
                    Select all the preferences you want to set on this monitor.
                  </span>
                </div>
                <div className="list-contact-container22">
                  <div className="list-contact-container23">
                    <input
                      type="text"
                      id="4004"
                      placeholder="Company Name"
                      className="list-contact-list-contact-company-details-company-name"
                    />
                  </div>
                  <div className="list-contact-container24">
                    <input
                      type="text"
                      id="4005"
                      placeholder="Linkedin Url"
                      className="list-contact-list-contact-company-details-linkedin-url"
                    />
                  </div>
                </div>
              </div>
              <div className="list-contact-container25">
                <Link to="/list-details" className="list-contact-navlink2">
                  <div className="list-contact-container26 btn-main-white">
                    <svg viewBox="0 0 1024 1024" className="list-contact-icon4">
                      <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                    </svg>
                    <span className="list-contact-text14">Cancel</span>
                  </div>
                </Link>
                <Link to="/construction" className="list-contact-navlink3">
                  <div className="list-contact-container27">
                    <svg viewBox="0 0 1024 1024" className="list-contact-icon6">
                      <path d="M554 540l112-110 60 60-214 214-214-214 60-60 112 110v-412h84v412zM810 512h86v298q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-298h86v298h596v-298z"></path>
                    </svg>
                    <span className="list-contact-text15">Save</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="list-contact-popup">
            <div className="list-contact-container28">
              <div className="list-contact-container29 border-bottom">
                <span>Notification</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListContact
