import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ToggleBox from './toggle-box';
import './channel-add-contact-contact-info.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const ChannelAddContactContactInfo = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_contactId = queryParams.get('id');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactLevel, setContactLevel] = useState('intern'); // Default to 'intern'
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {

    console.log('query_contactId', query_contactId);
    console.log('notificationContacts', userData.notificationContacts);
    console.log('contacts[query_contactId]', userData.notificationContacts[query_contactId]);

    if (query_contactId && userData.notificationContacts && userData.notificationContacts[query_contactId]) {
      const contactData = userData.notificationContacts[query_contactId];
      setFirstName(contactData.firstName);
      setLastName(contactData.lastName);
      setEmail(contactData.email);
      setContactLevel(contactData.type);
      setIsUpdate(true);
    }
  }, [userData, query_contactId]);

  const handleUpload = () => {
    if (!firstName || !lastName || !email) {
      if (!firstName) {
        notify('First Name is required. Please provide a first name for the contact before proceeding.', 3);
      }
      if (!lastName) {
        notify('Last Name is required. Please provide a last name for the contact before proceeding.', 3);
      }
      if (!email) {
        notify('Email is required. Please provide an email address for the contact before proceeding.', 3);
      }
      return;
    }

    const newContactData = {
      firstName,
      lastName,
      email,
      type: contactLevel,
      created: Date.now().toString(),
      notification: {},
    };

    if (isUpdate) {
      // Update existing contact
      const path = `notificationContacts/${query_contactId}`;
      uploadData(path, newContactData)
        .then(() => {
          notify('Contact updated successfully.', 1);
          history.push('/channels');
        })
        .catch((error) => {
          notify('An error occurred while updating the contact. Please try again.', 3);
        });
    } else {
      // Create new contact
      const newContactId = `contact_${Date.now()}`;
      const path = `notificationContacts/${newContactId}`;
      uploadData(path, newContactData)
        .then(() => {
          notify('Contact created successfully.', 1);
          history.push('/channels');
        })
        .catch((error) => {
          notify('An error occurred while creating the contact. Please try again.', 3);
        });
    }
  };

  return (
    <div className="channel-add-contact-contact-info-channel-add-contact-contact-info">
      <div className="channel-add-contact-contact-info-container">
        <div className="channel-add-contact-contact-info-container01">
          <span className="channel-add-contact-contact-info-text">
            Contact Info
          </span>
          <span className="info-txt">
            Provide the first name, last name, and email address of the contact you want to add to the notification group. This information is essential to ensure the contact can receive notifications as part of the group.
          </span>
        </div>
        <div className="channel-add-contact-contact-info-container02">
          <div className="channel-add-contact-contact-info-container03">
            <input
              type="text"
              id="7001"
              placeholder="First Name"
              className="channel-add-contact-contact-info-channel-add-contact-contact-info-first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="channel-add-contact-contact-info-container04">
            <input
              type="text"
              id="7002"
              placeholder="Last Name"
              className="channel-add-contact-contact-info-channel-add-contact-contact-info-last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="channel-add-contact-contact-info-container05">
            <input
              type="text"
              id="7003"
              placeholder="Email"
              className="channel-add-contact-contact-info-channel-add-contact-contact-info-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="channel-add-contact-contact-info-container06">
        <div className="channel-add-contact-contact-info-container07">
          <span className="channel-add-contact-contact-info-text02">
            Contact Level
          </span>
          <span className="info-txt">
            Specify whether the contact is internal to the company or external. This helps in categorizing the contact correctly and ensuring appropriate communication and notifications.
          </span>
        </div>
        <div className="channel-add-contact-contact-info-container08">
          <div
            className="channel-add-contact-contact-info-container09"
            onClick={() => setContactLevel('intern')}
          >
            <div className="channel-add-contact-contact-info-container10">
              <div className="channel-add-contact-contact-info-container11">
                <span className="channel-add-contact-contact-info-text04">
                  Company Intern
                </span>
                <span className="info-txt">
                  Turn on this trigger to specify if the contact is an internal member.
                </span>
              </div>
              <ToggleBox
                rootClassName="toggle-box-root-class-name"
                checked={contactLevel === 'intern'}
                onChange={() => {}}
              />
            </div>
          </div>
          <div
            className="channel-add-contact-contact-info-container12"
            onClick={() => setContactLevel('extern')}
          >
            <div className="channel-add-contact-contact-info-container13">
              <span className="channel-add-contact-contact-info-text06">
                Company Extern
              </span>
              <span className="info-txt">
                Turn on this trigger to specify if the contact is an external member.
              </span>
            </div>
            <ToggleBox
              rootClassName="toggle-box-root-class-name1"
              checked={contactLevel === 'extern'}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>
      <div className="channel-add-contact-contact-info-container14">
        <Link
          to="/channels"
          className="channel-add-contact-contact-info-navlink"
        >
          <div className="channel-add-contact-contact-info-container15 btn-main-white">
            <svg
              viewBox="0 0 1024 1024"
              className="channel-add-contact-contact-info-icon"
            >
              <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
            </svg>
            <span className="channel-add-contact-contact-info-text08">
              Cancel
            </span>
          </div>
        </Link>
        <div
          className="channel-add-contact-contact-info-container16"
          onClick={handleUpload}
        >
          <svg
            viewBox="0 0 1024 1024"
            className="channel-add-contact-contact-info-icon2"
          >
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="channel-add-contact-contact-info-text09">
            {isUpdate ? 'Save' : 'Add'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChannelAddContactContactInfo;
