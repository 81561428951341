import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import LogOverviewList from '../components/log-overview-list'
import POPUP from '../components/popup'
import './log.css'

const Log = (props) => {
  return (
    <div className="log-container">
      <Helmet>
        <title>Log - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Log - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="log-main">
        <MenuBar></MenuBar>
        <div className="log-content">
          <TopBar lable="Log"></TopBar>
          <div className="log-content1">
            <div className="log-container1">
              <div className="log-container2">
                <div className="btn-main-white">
                  <svg viewBox="0 0 1024 1024" className="log-icon">
                    <path d="M889.68 166.32c-93.608-102.216-228.154-166.32-377.68-166.32-282.77 0-512 229.23-512 512h96c0-229.75 186.25-416 416-416 123.020 0 233.542 53.418 309.696 138.306l-149.696 149.694h352v-352l-134.32 134.32z"></path>
                    <path d="M928 512c0 229.75-186.25 416-416 416-123.020 0-233.542-53.418-309.694-138.306l149.694-149.694h-352v352l134.32-134.32c93.608 102.216 228.154 166.32 377.68 166.32 282.77 0 512-229.23 512-512h-96z"></path>
                  </svg>
                  <span>Reload Log</span>
                </div>
                <div className="log-container4">
                  <svg viewBox="0 0 1024 1024" className="log-icon3">
                    <path d="M214 768h596v86h-596v-86zM810 384l-298 298-298-298h170v-256h256v256h170z"></path>
                  </svg>
                  <span className="log-text1">Export Log</span>
                </div>
              </div>
              <Link to="/construction" className="log-navlink">
                <div className="log-container5">
                  <svg viewBox="0 0 1024 1024" className="log-icon5">
                    <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                  </svg>
                  <span>Clear Log</span>
                </div>
              </Link>
            </div>
            <LogOverviewList></LogOverviewList>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Log
