import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './session-cookie-overview-cookies-item.css';
import { useData } from '../contexts/DataContext';

const SessionCookieOverviewCookiesItem = (props) => {
  const { userData } = useData();
  const id = props.id;
  const history = useHistory();


  // Initialisiere Zustände basierend auf den Benutzerdaten
  const cookieData = userData?.cookies?.[id] || {};
  const ownerName = cookieData.firstName + ' ' + cookieData.lastName || 'Owner Name';
  const linkedin = cookieData.linkedin || false;
  const salesNavigator = cookieData.salesNavigator || false;
  const cookieStatus = cookieData.status || 'Cookie Status';
  const lastUsed = cookieData.lastUsed
    ? new Date(parseInt(cookieData.lastUsed, 10)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' })
    : '--.--.----';

  const handleEdit = () => {
    history.push(`/session-cookie-connect?id=${id}`);
  };

  return (
    <div className="session-cookie-overview-cookies-item-session-cookie-overview-cookies-item">
      <div className="session-cookie-overview-cookies-item-container">
        <div className="session-cookie-overview-cookies-item-container01">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"
            className="session-cookie-overview-cookies-item-image"
          />
        </div>
        <div className="session-cookie-overview-cookies-item-container02">
          <span>{ownerName}</span>
          <span className="session-cookie-overview-cookies-item-text1">
            {cookieStatus}
          </span>
        </div>
      </div>
      <div className="session-cookie-overview-cookies-item-container03">
        {linkedin && (
          <div className="session-cookie-overview-cookies-item-container04">
            <span className="session-cookie-overview-cookies-item-text2">
              LinkedIn
            </span>
          </div>
        )}
        {salesNavigator && (
          <div className="session-cookie-overview-cookies-item-container05">
            <span className="session-cookie-overview-cookies-item-text3">
              Sales Navigator
            </span>
          </div>
        )}
      </div>
      <div className="session-cookie-overview-cookies-item-container06">
        <div className="session-cookie-overview-cookies-item-container07">
          <span>{lastUsed}</span>
        </div>
      </div>
      <div className="session-cookie-overview-cookies-item-container08">
        <div className="session-cookie-overview-cookies-item-container09">
          <svg
            viewBox="0 0 1024 1024"
            className="session-cookie-overview-cookies-item-icon"
          >
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        <div onClick={handleEdit} className="session-cookie-overview-cookies-item-container10">
          <span>Edit</span>
        </div>
      </div>
    </div>
  );
}

SessionCookieOverviewCookiesItem.defaultProps = {
  id: 'cookie_1',
};

SessionCookieOverviewCookiesItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SessionCookieOverviewCookiesItem;
