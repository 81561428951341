import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CheckBox from './check-box';
import SessionCookieOverviewCookiesCreateGroupItem from './session-cookie-overview-cookies-create-group-item';
import './session-cookie-overview-cookies-create-group.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const SessionCookieOverviewCookiesCreateGroup = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_groupId = queryParams.get('id');

  const cookies = Object.keys(userData?.cookies || {}) || [];
  const [groupName, setGroupName] = useState('');
  const [selectedCookies, setSelectedCookies] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  console.log(111);
  useEffect(() => {
    console.log(1);
    console.log(query_groupId);
    console.log(userData.cookieGroups);
    console.log(userData.cookieGroups[query_groupId]);
    if (query_groupId && userData.cookieGroups && userData.cookieGroups[query_groupId]) {
      console.log(2);
      const groupData = userData.cookieGroups[query_groupId];
      setGroupName(groupData.name);
      setSelectedCookies(groupData.cookies);
      setIsUpdate(true);
    }
  }, [userData, query_groupId]);

  const handleToggleCookie = (id) => {
    setSelectedCookies((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((cookieId) => cookieId !== id)
        : [...prevSelected, id]
    );
  };

  const checkGroupNameExists = (name) => {
    return Object.values(userData?.cookieGroups || {}).some(
      (group) => group.name.toLowerCase() === name.toLowerCase() && group.id !== query_groupId
    );
  };

  const handleSave = () => {
    if (!groupName || selectedCookies.length === 0) {
      if (!groupName) {
        notify('The group name is required. Please provide a name for the group before proceeding.', 3);
      }

      if (selectedCookies.length === 0) {
        if (cookies.length === 0) {
          notify('No cookies have been created yet. Please create at least one cookie.', 2);
        } else {
          notify('Please select at least one cookie', 3);
        }
      }
      return;
    }

    const newGroupData = {
      name: groupName,
      cookies: selectedCookies,
      created: Date.now(),
      used: 0
    };

    if (isUpdate) {
      // Update existing group
      uploadData(`cookieGroups/${query_groupId}`, newGroupData)
        .then(() => {
          notify('Group updated successfully.', 1);
          history.push('/session-cookie');
        })
        .catch((error) => {
          notify('An error occurred while updating the group. Please try again.', 3);
        });
    } else {
      // Create new group
      const newGroupId = `group_`+ Math.floor(100000 + Math.random() * 900000);;
      const path = `cookieGroups/${newGroupId}`;
      uploadData(path, newGroupData)
        .then(() => {
          notify('Group created successfully.', 1);
          history.push('/session-cookie');
        })
        .catch((error) => {
          notify('An error occurred while creating the group. Please try again.', 3);
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className="session-cookie-overview-cookies-create-group-session-cookie-overview-cookies">
      <div className="session-cookie-overview-cookies-create-group-container">
        <div className="session-cookie-overview-cookies-create-group-container01">
          <span className="session-cookie-overview-cookies-create-group-text">
            Group Name
          </span>
          <span className="info-txt">
            Enter the name of the cookie group. The cookie group name helps to categorize and manage your cookies effectively.
          </span>
        </div>
        <div className="session-cookie-overview-cookies-create-group-container02">
          <input
            type="text"
            id="6004"
            placeholder="Name"
            className="session-cookie-overview-cookies-create-group-session-cookie-overview-grupu-name-name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
      </div>
      <div className="session-cookie-overview-cookies-create-group-container03">
        <div className="session-cookie-overview-cookies-create-group-container04">
          <span className="session-cookie-overview-cookies-create-group-text2">
            Cookies
          </span>
          <span className="info-txt">
            Select the cookies you want to add to this group. Choosing the appropriate cookies ensures they are categorized and managed under the correct group.
          </span>
        </div>
        <div className="session-cookie-overview-cookies-create-group-container05">
          <div className="session-cookie-overview-cookies-create-group-container06">
            <div className="session-cookie-overview-cookies-create-group-container07">
              <CheckBox rootClassName="check-box-root-class-name127"></CheckBox>
            </div>
            <div className="session-cookie-overview-cookies-create-group-container08">
              <span className="session-cookie-overview-cookies-create-group-text4">
                Owner / Status
              </span>
            </div>
            <div className="session-cookie-overview-cookies-create-group-container09">
              <span className="session-cookie-overview-cookies-create-group-text5">
                Account
              </span>
            </div>
          </div>
          <div className="session-cookie-overview-cookies-create-group-container10">
            <ul className="list">
              {cookies.map((id, index) => (
                <li
                  key={index}
                  className="session-cookie-overview-cookies-create-group-li list-item"
                  onClick={() => handleToggleCookie(id)}
                >
                  <SessionCookieOverviewCookiesCreateGroupItem
                    id={id}
                    isSelected={selectedCookies.includes(id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="session-cookie-overview-cookies-create-group-container11">
        <div className="session-cookie-overview-cookies-create-group-container12 btn-main-white" onClick={handleCancel}>
          <svg
            viewBox="0 0 1024 1024"
            className="session-cookie-overview-cookies-create-group-icon"
          >
            <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
          </svg>
          <span className="session-cookie-overview-cookies-create-group-text6">
            Cancel
          </span>
        </div>
        <div className="session-cookie-overview-cookies-create-group-container13" onClick={handleSave}>
          <svg
            viewBox="0 0 1024 1024"
            className="session-cookie-overview-cookies-create-group-icon2"
          >
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="session-cookie-overview-cookies-create-group-text7">
            {isUpdate ? 'Save Group' : 'Create Group'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SessionCookieOverviewCookiesCreateGroup;
