import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './settings-notification-channel-item.css'
import { useData } from '../contexts/DataContext'

const SettingsNotificationChannelItem = (props) => {
  const [check, setCheck] = useState(false)
  const { userData } = useData()

  const id = props.id
  const channel = userData.channelGroups[id] || {}
  console.log(channel);

  return (
    <div
      onClick={() => setCheck(!check)}
      className="settings-notification-channel-item-settings-notification-channel-item"
    >
      <div className="settings-notification-channel-item-container">
        <div className="settings-notification-channel-item-container1 preference-checkBox">
          {check && (
            <div className="settings-notification-channel-item-container2">
              <svg
                viewBox="0 0 1024 1024"
                className="settings-notification-channel-item-icon"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="settings-notification-channel-item-container3">
        <div className="settings-notification-channel-item-container4">
          <span>{channel.name}</span>
        </div>
      </div>
      <div className="settings-notification-channel-item-container5">
        <span className="settings-notification-channel-item-text1">
          {channel.contacts?.length || '-'}
        </span>
      </div>
    </div>
  )
}

SettingsNotificationChannelItem.defaultProps = {
  rootClassName: '',
  channelName: 'Channel name',
  contactSize: '99',
}

SettingsNotificationChannelItem.propTypes = {
  rootClassName: PropTypes.string,
  channelName: PropTypes.string,
  contactSize: PropTypes.string,
}

export default SettingsNotificationChannelItem
