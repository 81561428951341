import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './list-details-list-name-item.css';
import { useData } from '../contexts/DataContext';
import { notify } from './Notification';
import { confirm } from './Confirmation';


const ListDetailsListNameItem = (props) => {
  const { userData, uploadData } = useData();

  const contact = userData.contacts[props.id] || {};
  const [favorite, setFavorite] = useState(false);
  const [stats, setStats] = useState(contact.status || 0);

  useEffect(() => {
    setStats(contact.status);
  }, [contact.status]);

  const deleteEvent = () => {
    confirm(`Are you sure you want delete ${contact.contactName}?`, () => {
      console.log('confirm');
      uploadData(`contacts/${contact.id}/delete`,true)
      .then(()=>{
        notify(`Contact ${contact.contactName} has been deleted.`,1)
      })
      .catch(()=>{
        notify(`Failed to delete ${contact.contactName}`)
      })
    }, () => {
      console.log('cancel');
    });
  };

  return (
    <div className="list-details-list-name-item-list-details-list-name-item">
      <div className="list-details-list-name-item-container">
        <div className="list-details-list-name-item-container01">
          <img
            alt="image"
            src={"https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"}
            className="list-details-list-name-item-image"
          />
        </div>
        <div className="list-details-list-name-item-container02">
          <span>{contact.contactName || 'Contact Full Name'}</span>
          <span className="list-details-list-name-item-text01">
            {contact.companyName || 'Company Name'}
          </span>
        </div>
      </div>
      <div className="list-details-list-name-item-container03">
        <div className="list-details-list-name-item-container04">
          <span>{contact.activity || '-'}</span>
          <div className="list-details-list-name-item-container05">
            <span className="list-details-list-name-item-text05">
              {contact.position || '-'}
            </span>
          </div>
        </div>
      </div>
      <div className="list-details-list-name-item-container06">
        <div className="list-details-list-name-item-container07">
          <span className="list-details-list-name-item-text06">
            {
              contact.lastScan ?
                new Date(parseInt(contact.lastScan)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) :
                '--.--.----'
            }
          </span>
          <div className="list-details-list-name-item-container08">
            {stats === 0 && (
              <span className="list-details-list-name-item-text07">
                Pending
              </span>
            )}
            {stats === 1 && (
              <span className="list-details-list-name-item-text08">
                Employed
              </span>
            )}
            {stats === 2 && (
              <span className="list-details-list-name-item-text09">
                Job changed
              </span>
            )}
            {stats === 3 && (
              <span className="list-details-list-name-item-text10">
                Job exit
              </span>
            )}
            {stats === 4 && (
              <span className="list-details-list-name-item-text11">
                Not found
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="list-details-list-name-item-container09">
        <div onClick={deleteEvent} className="list-details-list-name-item-container10">
          <svg
            viewBox="0 0 1024 1024"
            className="list-details-list-name-item-icon"
          >
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        <div
          onClick={() => setFavorite(!favorite)}
          className="list-details-list-name-item-container11"
        >
          {favorite && (
            <div className="list-details-list-name-item-container12">
              <span className="list-details-list-name-item-text12">
                Favorite
              </span>
            </div>
          )}
          {!favorite && (
            <div className="list-details-list-name-item-container13">
              <span className="list-details-list-name-item-text13">
                Favorite
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ListDetailsListNameItem.defaultProps = {
  id: 'contact_1',
  description: 'Description\n',
  position: 'Position',
  date: '01.01.2024',
  activity: 'Activity',
  companyName: 'Company Name',
  fullName: 'Contact Full Name',
};

ListDetailsListNameItem.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  position: PropTypes.string,
  date: PropTypes.string,
  activity: PropTypes.string,
  companyName: PropTypes.string,
  fullName: PropTypes.string,
};

export default ListDetailsListNameItem;
