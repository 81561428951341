// src/components/TopBar.js
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useData } from '../contexts/DataContext';

import './top-bar.css';

const TopBar = (props) => {
  const [profilDropDown, setProfilDropDown] = useState(false);
  const history = useHistory(); // Verwende useHistory Hook für die Navigation
  const { logout, userData } = useData();


  const firstName = userData?.profile?.firstName || "-";
  const lastName =  userData?.profile?.lastName|| "-";
  const companyName = userData?.profile?.company?.name || "-";
  const balance = userData?.wallet?.balance || "0";

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login'); // Leite zur Login-Seite weiter
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <div className="top-bar-top-bar border-bottom">
      <div className="top-bar-container">
        <span className="top-bar-text">{props.lable}</span>
        <div className="top-bar-container01"></div>
        <span className="top-bar-text01">{props.lable2}</span>
      </div>
      <div className="top-bar-container02">
        <Link to="/charge-wallet" className="top-bar-navlink">
          <div className="top-bar-container03">
            <span className="top-bar-text02">Charge Wallet</span>
          </div>
        </Link>
        <div className="top-bar-container04">
          <div className="top-bar-container05">
            <span className="top-bar-text03">{balance}</span>
            <div className="top-bar-container06">
              <span className="top-bar-text04">Wallet</span>
              <span className="top-bar-text05">EURO</span>
            </div>
          </div>
        </div>
        <div
          onClick={() => setProfilDropDown(true)}
          className="top-bar-container07"
        >
          <div className="top-bar-container08">
            <img alt="image" src={props.imageSrc} className="top-bar-image" />
          </div>
          <div className="top-bar-container09">
            <span className="top-bar-text06">{firstName+' '+lastName}</span>
            <span className="top-bar-text07">{companyName}</span>
          </div>
        </div>
        {profilDropDown && (
          <div
            onMouseLeave={() => setProfilDropDown(false)}
            className="top-bar-container10"
          >
            <div className="top-bar-container11">
              <div className="top-bar-container12 bg-gradient">
                <span className="top-bar-text08">Client-ID:</span>
                <span className="top-bar-text09">123456</span>
              </div>
              <div className="top-bar-container13 border-bottom">
                <div className="top-bar-container14">
                  <span className="top-bar-text10">1</span>
                </div>
                <span>Notification</span>
              </div>
              <div className="top-bar-container15" onClick={handleLogout}>
                <span>Logout</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TopBar.defaultProps = {
  lable2: 'Overview',
  clientCompany: 'Durepoxi GmbH',
  imageSrc:
    'https://images.unsplash.com/photo-1528892952291-009c663ce843?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI3fHxwcm9maWx8ZW58MHx8fHwxNzE3MjE2NTQ3fDA&ixlib=rb-4.0.3&w=200',
  clientName: 'Sebastian S.',
  wallet: '100',
  lable: 'Dashboard',
};

TopBar.propTypes = {
  lable2: PropTypes.string,
  clientCompany: PropTypes.string,
  imageSrc: PropTypes.string,
  clientName: PropTypes.string,
  wallet: PropTypes.string,
  lable: PropTypes.string,
};

export default TopBar;
