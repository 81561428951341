import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import './charge-wallet-item.css'
import { useData } from '../contexts/DataContext';

const ChargeWalletItem = (props) => {
  const { user, userData } = useData();

  const id = props.id
  const transaction = userData?.wallet?.transaction[id]
  const [status, setStatus] = useState(transaction.status || 0)

  useEffect(() => {
    setStatus(transaction.status)
  }, [transaction.status]);
  return (
    <div className="charge-wallet-item-charge-wallet-item border-bottom">
      <div className="charge-wallet-item-container">
        <span>{(transaction.value / 100).toFixed(2).replace('.', ',')}€</span>
      </div>
      <div className="charge-wallet-item-container1">
        <span>{new Date(transaction.timestamp * 1000).toLocaleString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(',', ' -')}</span>
      </div>
      <div className="charge-wallet-item-container2">
        {status === 0 && (
          <div className="charge-wallet-item-container3">
            <span className="charge-wallet-item-text2">{props.text2}</span>
          </div>
        )}
        {status === 1 && (
          <div className="charge-wallet-item-container4">
            <span className="charge-wallet-item-text3">{props.text3}</span>
          </div>
        )}
        {status === 2 && (
          <div className="charge-wallet-item-container5">
            <span className="charge-wallet-item-text4">{props.text4}</span>
          </div>
        )}
      </div>
      <div className="charge-wallet-item-container6">
        <div className="charge-wallet-item-container7">
          <svg viewBox="0 0 1024 1024" className="charge-wallet-item-icon">
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        <div className="charge-wallet-item-container8">
          <svg viewBox="0 0 1024 1024" className="charge-wallet-item-icon2">
            <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416z"></path>
            <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
          </svg>
          <span className="charge-wallet-item-text5">{props.text5}</span>
        </div>
      </div>
    </div>
  )
}

ChargeWalletItem.defaultProps = {
  text1: '01.01.2024',
  text4: 'Not paid',
  text2: 'Panding',
  text: 'Charge Value',
  text5: 'Export',
  text3: 'Paid',
}

ChargeWalletItem.propTypes = {
  text1: PropTypes.string,
  text4: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
  text5: PropTypes.string,
  text3: PropTypes.string,
}

export default ChargeWalletItem
