import React from 'react'

import { Helmet } from 'react-helmet'

import LoginComponent from '../components/login-component'
import './login.css'

const Login = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="login-container1">
        <div className="login-container2">
          <div className="login-top-bar"></div>
          <div className="login-container3">
            <div className="login-container4">
              <div className="login-container5 bg-gradient">
                <div className="login-container6">
                  <img
                    alt="image"
                    src="/Logo/logo_white1-500w.png"
                    className="login-image"
                  />
                  <span>Traffls Dashboard</span>
                </div>
              </div>
              <LoginComponent rootClassName="login-component-root-class-name"></LoginComponent>
            </div>
          </div>
          <div className="login-botton-bar">
            <span className="login-text1">© Unitled Traffls 2024</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
