// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCOKc1fP83dCTr6d-MCqZ4liLbigxfipJE",
  authDomain: "traffls.firebaseapp.com",
  databaseURL: "https://traffls-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "traffls",
  storageBucket: "traffls.appspot.com",
  messagingSenderId: "388346702084",
  appId: "1:388346702084:web:39b1ab61d6600bafcc0851",
  measurementId: "G-8TDK6N9RX4"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
