import React from 'react'
import { Link } from 'react-router-dom'

import SessionCookieOverviewCookiesItem from './session-cookie-overview-cookies-item'
import './session-cookie-overview-cookies.css'
import { useData } from '../contexts/DataContext';

const SessionCookieOverviewCookies = (props) => {
  const { userData } = useData();
  const cookies = Object.keys(userData?.cookies || {}) || [];

  return (
    <div className="session-cookie-overview-cookies-session-cookie-overview-cookies">
      <div className="session-cookie-overview-cookies-container">
        <div className="session-cookie-overview-cookies-container01">
          <span className="session-cookie-overview-cookies-text">Cookies</span>
          <Link
            to="/session-cookie-connect"
            className="session-cookie-overview-cookies-navlink"
          >
            <div className="btn-main-lila">
              <svg
                viewBox="0 0 1024 1024"
                className="session-cookie-overview-cookies-icon"
              >
                <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
              </svg>
              <span className="session-cookie-overview-cookies-text1">
                Connect Cookie
              </span>
            </div>
          </Link>
        </div>
        <div className="btn-main-white">
          <svg
            viewBox="0 0 1024 1024"
            className="session-cookie-overview-cookies-icon2"
          >
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span>Invite Contact</span>
        </div>
      </div>
      <div className="session-cookie-overview-cookies-container04">
        <div className="session-cookie-overview-cookies-container05">
          <div className="session-cookie-overview-cookies-container06">
            <span className="session-cookie-overview-cookies-text3">
              Owner / Status
            </span>
          </div>
          <div className="session-cookie-overview-cookies-container07">
            <span className="session-cookie-overview-cookies-text4">
              Account
            </span>
          </div>
          <div className="session-cookie-overview-cookies-container08">
            <span className="session-cookie-overview-cookies-text5">
              Last update
            </span>
          </div>
          <div className="session-cookie-overview-cookies-container09"></div>
        </div>
        <ul className="session-cookie-overview-cookies-ul">
          {cookies.map((id, index) => (
            <li key={index} className="list-item">
              <SessionCookieOverviewCookiesItem id={id} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SessionCookieOverviewCookies
