import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';
import './events-create-form.css';

const EventsCreateForm = (props) => {
  const { userData, uploadData } = useData();
  const [eventName, setEventName] = useState('');
  const history = useHistory();

  const checkIfNameExists = (name) => {
    return Object.values(userData.eventMonitors || {}).some(event => event.name === name && !event.delete);
  }

  const handleUpload = () => {
    if (!eventName) {
      notify('The Event Name field is required.', 3);
      return;
    }

    const nameExists = checkIfNameExists(eventName);
    if (nameExists) {
      notify('The Event Name already exists.', 3);
      return;
    }

    const newEventMonitorId = `mon_` + Math.floor(100000 + Math.random() * 900000);

    const newEventMonitorData = {
      name: eventName,
      status: 2,
      delete: false,
      scan: false
    }

    uploadData(`eventMonitors/${newEventMonitorId}`, newEventMonitorData);
    notify('Event Monitor successfully created!', 1);
    history.push('/events');
  }

  return (
    <div className={`events-create-form-monitor-create ${props.rootClassName} `}>
      <div className="events-create-form-container">
        <div className="events-create-form-container1">
          <span className="events-create-form-text">
            Event
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="events-create-form-text1">
            Select all the preferences you want to set on this monitor.
          </span>
        </div>
        <div className="events-create-form-container2">
          <input
            type="text"
            id="3001"
            placeholder="Type the event here..."
            className="events-create-form-monitor-create-monitor-monitor-name"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
        </div>
      </div>
      <div className="events-create-form-container3">
        <Link to="/events" className="events-create-form-navlink">
          <div className="events-create-form-container4 btn-main-white">
            <svg viewBox="0 0 1024 1024" className="events-create-form-icon">
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span className="events-create-form-text2">Cancel</span>
          </div>
        </Link>
        <div onClick={handleUpload} className="events-create-form-container5">
          <svg viewBox="0 0 1024 1024" className="events-create-form-icon2">
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="events-create-form-text3">
            Create Event Monitor
          </span>
        </div>
      </div>
    </div>
  )
}

EventsCreateForm.defaultProps = {
  rootClassName: '',
}

EventsCreateForm.propTypes = {
  rootClassName: PropTypes.string,
}

export default EventsCreateForm;
