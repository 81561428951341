import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './channel-creat-group-contacts-item.css';
import { useData } from '../contexts/DataContext';

const ChannelCreatGroupContactsItem = (props) => {
  const { userData } = useData();
  const [check, setCheck] = useState(props.isSelected || false);

  useEffect(() => {
    setCheck(props.isSelected);
  }, [props.isSelected]);

  const id = props.id;
  const contact = userData?.notificationContacts[id];

  return (
    <div
      onClick={() => setCheck(!check)}
      className="channel-creat-group-contacts-item-channel-creat-group-contacts-item"
    >
      <div className="channel-creat-group-contacts-item-container">
        <div className="channel-creat-group-contacts-item-container1 preference-checkBox">
          {check && (
            <div className="channel-creat-group-contacts-item-container2">
              <svg
                viewBox="0 0 1024 1024"
                className="channel-creat-group-contacts-item-icon"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="channel-creat-group-contacts-item-container3">
        <div className="channel-creat-group-contacts-item-container4">
          <span>{contact?.firstName}</span>
          <span className="channel-creat-group-contacts-item-text1">
            {contact?.type}
          </span>
        </div>
      </div>
      <div className="channel-creat-group-contacts-item-container5">
        <span>{contact?.email}</span>
      </div>
    </div>
  );
};

ChannelCreatGroupContactsItem.defaultProps = {
  email: 'email@address.com\n',
  company: 'Company Name',
  name: 'Contact  Name',
  rootClassName: '',
};

ChannelCreatGroupContactsItem.propTypes = {
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  email: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default ChannelCreatGroupContactsItem;
