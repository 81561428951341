import { saveAs } from 'file-saver';

export const downloadCsv = async (data, filename) => {
  if (!data || !data.length) {
    return;
  }

  const keys = Object.keys(data[0]);
  const csvContent = [
    keys.join(','), // Header row
    ...data.map(row => keys.map(key => row[key]).join(',')) // Data rows
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  await saveAs(blob, filename);
};