import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import MonitorDetailsContactsItem from './monitor-details-contacts-item';
import MonitorDetailsTriggerItem from './monitor-details-trigger-item';
import './monitor-details-component.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const MonitorDetailsComponent = (props) => {
  const { userData, uploadData } = useData();

  const [statsContacts, setStatsContacts] = useState(0);
  const [viewList, setViewList] = useState(0);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [status, setStatus] = useState(userData?.monitors[id]?.status || 0);
  const listId = userData?.monitors[id]?.list || [];
  const list = userData?.lists[listId] || {};
  const triggers = list.contacts.filter(contactID => userData?.contacts[contactID]?.triggered === true);
  const monitorName = userData?.monitors[id]?.monitorName || props.monitorName;
  const lastScanTimestamp = userData?.monitors[id]?.lastScan || props.lastScan;
  const lastScan = lastScanTimestamp ? new Date(parseInt(lastScanTimestamp)).toLocaleDateString() : '--.--.----';

  const cookiesGroupId = userData?.monitors[id]?.cookieGroup || [];
  const cookies = userData?.cookieGroups[cookiesGroupId]?.cookies || [];
  const preferences = userData?.monitors[id]?.preferences || [];

  // Calculate scanPercentage
  const contacts = list.contacts || [];
  const totalContacts = contacts.length;
  const scannedContacts = contacts.filter(contactId => userData.contacts[contactId]?.scanned).length;
  const scanPercentage = totalContacts > 0 ? ((scannedContacts / totalContacts) * 100).toFixed(2) : '0.00';

  // Calculate preferencesPercentage
  const preferenceContacts = contacts.filter(contactId => {
    const contact = userData.contacts[contactId];
    return contact.scanned && (preferences.includes(contact.activity) || preferences.includes(contact.position));
  }).length;
  const preferencesPercentage = totalContacts > 0 ? ((preferenceContacts / totalContacts) * 100).toFixed(2) : '0.00';

  const handleStartMonitor = () => {
    setStatus(2);
    uploadData(`monitors/${id}/status`, 2);
    notify('Monitor started', 1);
    // You can add additional logic here if needed, e.g., calling an API to start the monitor
  };

  const handleStopMonitor = () => {
    setStatus(0);
    uploadData(`monitors/${id}/status`, 0);
    notify('Monitor stopped', 0);
    // You can add additional logic here if needed, e.g., calling an API to stop the monitor
  };


  return (
    <div
      className={`monitor-details-component-monitor-details-component ${props.rootClassName} `}
    >
      <div className="monitor-details-component-container">
        <div className="monitor-details-component-container01 border-bottom">
          <div className="monitor-details-component-container02">
            <div className="monitor-details-component-container03">
              <span className="monitor-details-component-text">
                {monitorName}
              </span>
              <div className="monitor-details-component-container04">
                <span className="monitor-details-component-text01">ID: </span>
                <span className="monitor-details-component-text02">
                  {id}
                </span>
              </div>
            </div>
            <div className="monitor-details-component-container05">
              <div className="monitor-details-component-container06">
                <span className="monitor-details-component-text03">
                  Contacts: 
                </span>
                <span className="monitor-details-component-text04">
                  {list?.contacts?.length || '-'}
                </span>
              </div>
              <div className="monitor-details-component-container07">
                <div className="monitor-details-component-container08">
                  <span className="monitor-details-component-text05">
                    List: 
                  </span>
                  <span className="monitor-details-component-text06">
                    {list.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="monitor-details-component-container09">
              <div className="monitor-details-component-container10">
                <span className="monitor-details-component-text07">
                  Last Scan: 
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="monitor-details-component-text08">
                  {lastScan}
                </span>
              </div>
              <div className="monitor-details-component-container11">
                <span className="monitor-details-component-text09">
                  Trigger: 
                </span>
                <span className="monitor-details-component-text10">
                  {triggers.length}
                </span>
              </div>
            </div>
            <div className="monitor-details-component-container12">
              {status === 1 && (
                <div className="monitor-details-component-container13">
                  <div className="monitor-details-component-container14"></div>
                  <span className="monitor-details-component-text11">
                    Running
                  </span>
                </div>
              )}
              {status === 2 && (
                <div className="monitor-details-component-container15">
                  <div className="monitor-details-component-container16"></div>
                  <span className="monitor-details-component-text12">
                    Initialization
                  </span>
                </div>
              )}
              {status === 0 && (
                <div className="monitor-details-component-container17">
                  <div className="monitor-details-component-container18"></div>
                  <span className="monitor-details-component-text13">Stop</span>
                </div>
              )}
              {status === 3 && (
                <div className="monitor-details-component-container19">
                  <div className="monitor-details-component-container20"></div>
                  <span className="monitor-details-component-text14">
                    Error
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="monitor-details-component-container21">
            <div className="monitor-details-component-container22">
              <svg
                viewBox="0 0 1024 1024"
                className="monitor-details-component-icon"
              >
                <path
                  d="M512 810q124 0 211-87t87-211-87-211-211-87-211 87-87 211 87 211 211 87zM894 470h88v84h-88q-14 126-114 226t-226 114v88h-84v-88q-126-14-226-114t-114-226h-88v-84h88q14-126 114-226t226-114v-88h84v88q126 14 226 114t114 226z"
                  className=""
                ></path>
              </svg>
              <div className="monitor-details-component-container23">
                <span className="monitor-details-component-text15">
                  Session Cookies: 
                </span>
                <span className="monitor-details-component-text16">
                  {cookies.length}
                </span>
              </div>
            </div>
            <div className="monitor-details-component-container24">
              <svg
                viewBox="0 0 731.4285714285713 1024"
                className="monitor-details-component-icon02"
              >
                <path
                  d="M731.429 799.429c0 83.429-54.857 151.429-121.714 151.429h-488c-66.857 0-121.714-68-121.714-151.429 0-150.286 37.143-324 186.857-324 46.286 45.143 109.143 73.143 178.857 73.143s132.571-28 178.857-73.143c149.714 0 186.857 173.714 186.857 324zM585.143 292.571c0 121.143-98.286 219.429-219.429 219.429s-219.429-98.286-219.429-219.429 98.286-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"
                  className=""
                ></path>
              </svg>
              <div className="monitor-details-component-container25">
                <span className="monitor-details-component-text17">
                  Preferences: 
                </span>
                <span className="monitor-details-component-text18">
                  {preferences.length}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="monitor-details-component-container26">
          <span className="monitor-details-component-text19">
            Scan Performance
          </span>
          <span className="monitor-details-component-text20">
            Scan per contact: 48s
          </span>
        </div>
        <div className="monitor-details-component-container27">
          <div className="monitor-details-component-container28">
            <div className="monitor-details-component-container29">
              <span className="monitor-details-component-text21">
                Total contact scan
              </span>
              <div className="monitor-details-component-container30">
                <span className="monitor-details-component-text22">
                  {scanPercentage + '%'}
                </span>
              </div>
            </div>
            <div className="monitor-details-component-container31">
              <div style={{ width: `${scanPercentage}%` }} className="monitor-details-component-container32"></div>
            </div>
          </div>
          <div className="monitor-details-component-container33">
            <div className="monitor-details-component-container34">
              <span className="monitor-details-component-text23">
                Preference includes
              </span>
              <div className="monitor-details-component-container35">
                <span className="monitor-details-component-text24">
                  {preferencesPercentage + '%'}
                </span>
              </div>
            </div>
            <div className="monitor-details-component-container36">
              <div style={{ width: `${preferencesPercentage}%` }} className="monitor-details-component-container37"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="monitor-details-component-container38">
        <div className="monitor-details-component-container39">
          <div className="monitor-details-component-container40">
            {viewList === 0 && (
              <div className="monitor-details-component-container41">
                <div className="monitor-details-component-container42">
                  <span className="monitor-details-component-text25">
                    Contact list
                  </span>
                </div>
                <div
                  onClick={() => setViewList(1)}
                  className="monitor-details-component-container43"
                >
                  <span className="monitor-details-component-text26">
                    Trigger list
                  </span>
                </div>
              </div>
            )}
            {viewList === 1 && (
              <div className="monitor-details-component-container44">
                <div
                  onClick={() => setViewList(0)}
                  className="monitor-details-component-container45"
                >
                  <span className="monitor-details-component-text27">
                    Contact list
                  </span>
                </div>
                <div className="monitor-details-component-container46">
                  <span className="monitor-details-component-text28">
                    Trigger list
                  </span>
                </div>
              </div>
            )}
            <div className="monitor-details-component-container47">
              <span className="monitor-details-component-text29">
                {viewList === 0 ? list?.contacts?.length || '-' : triggers?.length || '-'}

              </span>
              <span className="monitor-details-component-text30">
                {' '}
                {viewList === 0 ? 'total contacts' : 'total triggers'}
              </span>
            </div>
          </div>
          <div className="monitor-details-component-container48">
            <svg
              viewBox="0 0 1024 1024"
              className="monitor-details-component-icon04"
            >
              <path
                d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"
                className=""
              ></path>
            </svg>
            <input
              type="text"
              placeholder="Contact, Company, Activity or Position"
              className="monitor-details-component-monitor-details-contacts-find"
            />
          </div>
        </div>
        {viewList === 0 && (
          <div className="monitor-details-component-container49">
            <div className="monitor-details-component-container50">
              <div className="monitor-details-component-container51">
                <span className="monitor-details-component-text31">
                  Contact / Company
                </span>
              </div>
              <div className="monitor-details-component-container52">
                <span className="monitor-details-component-text32">
                  Activity / Position
                </span>
              </div>
              <div className="monitor-details-component-container53">
                <span className="monitor-details-component-text33">
                  Data / Status
                </span>
              </div>
            </div>
            <ul className="monitor-details-component-ul">
              {list?.contacts?.map((id, index) => (
                <li key={index} className="list-item">
                  <MonitorDetailsContactsItem id={id}></MonitorDetailsContactsItem>
                </li>
              ))}
            </ul>
            <div className="monitor-details-component-container55">
              <div className="monitor-details-component-container56"></div>
              <div className="monitor-details-component-container57"></div>
              <div className="monitor-details-component-container58"></div>
              <div className="monitor-details-component-container59"></div>
              <div className="monitor-details-component-container60"></div>
              <div className="monitor-details-component-container61"></div>
              <div className="monitor-details-component-container62"></div>
            </div>
          </div>
        )}
        {viewList === 1 && (
          <div className="monitor-details-component-container63">
            <div className="monitor-details-component-container64">
              <div className="monitor-details-component-container65">
                <span className="monitor-details-component-text34">
                  Contact / Company
                </span>
              </div>
              <div className="monitor-details-component-container66">
                <span className="monitor-details-component-text35">
                  Activity / Position
                </span>
              </div>
              <div className="monitor-details-component-container67">
                <span className="monitor-details-component-text36">
                  Change from / to
                </span>
              </div>
              <div className="monitor-details-component-container68">
                <span className="monitor-details-component-text37">
                  Change date
                </span>
              </div>
            </div>
            <ul className="monitor-details-component-ul1">
              {triggers.map((id, index) => (
                <li key={index} className="list-item">
                  <MonitorDetailsTriggerItem id={id}></MonitorDetailsTriggerItem>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="monitor-details-component-container69">
        <div className="monitor-details-component-container70">
          {(status === 0 || status === 3) && (
            <div className="monitor-details-component-container71" onClick={handleStartMonitor}>
              <svg
                viewBox="0 0 1024 1024"
                className="monitor-details-component-icon06"
              >
                <path d="M342 214l468 298-468 298v-596z" className=""></path>
              </svg>
              <span className="monitor-details-component-text38">
                Start monitor
              </span>
            </div>
          )}
          {(status === 1 || status === 2) && (
            <div className="monitor-details-component-container72" onClick={handleStopMonitor}>
              <svg
                viewBox="0 0 1024 1024"
                className="monitor-details-component-icon08"
              >
                <path d="M256 256h512v512h-512v-512z" className=""></path>
              </svg>
              <span className="monitor-details-component-text39">
                Stop monitor
              </span>
            </div>
          )}
        </div>
        <div className="monitor-details-component-container73">
          <Link
            to="/construction"
            className="monitor-details-component-navlink"
          >
            <div className="monitor-details-component-container74">
              <svg
                viewBox="0 0 1024 1024"
                className="monitor-details-component-icon10"
              >
                <path
                  d="M969.003 417.963c-1.749-1.749-173.013-174.763-255.232-262.229-17.92-17.664-42.624-27.733-67.669-27.733-50.432 0-91.435 38.229-91.435 85.333h-341.333c-23.595 0-42.667 19.115-42.667 42.667v597.333c0 23.552 19.072 42.667 42.667 42.667h597.333c23.595 0 42.667-19.115 42.667-42.667v-257.92c61.184-62.336 114.688-116.437 115.669-117.376 16.512-16.64 16.512-43.435 0-60.075zM651.904 680.149c-0.939 1.152-2.773 2.517-5.803 2.517-3.157 0-5.163-0.981-6.144-1.707v-126.293-42.667h-42.667c-75.563 1.451-142.336 29.013-202.795 83.541 18.347-94.507 68.267-211.541 202.795-211.541h42.667v-168.875c1.195-0.896 3.029-1.792 6.144-1.792 3.84 0 6.485 2.133 6.571 2.133 61.269 65.067 172.843 178.645 226.005 232.533-10.795 10.965-185.259 188.672-226.773 232.149zM256 810.667v-512h341.333c0 14.848 0 29.653 0 42.667-198.827 0-256 207.829-256 362.667v21.333c72.149-109.995 153.6-168.661 256-170.667 0 44.587 0 121.088 0 128 0 23.509 21.845 42.667 48.768 42.667 15.531 0 28.843-6.741 37.675-16.683 22.997-24.107 52.992-55.083 84.309-87.168v189.184h-512.085z"
                  className=""
                ></path>
              </svg>
              <span className="monitor-details-component-text40">Export</span>
            </div>
          </Link>
          <Link to="/log" className="monitor-details-component-navlink1">
            <div className="monitor-details-component-container75">
              <svg
                viewBox="0 0 1024 1024"
                className="monitor-details-component-icon12"
              >
                <path
                  d="M725.333 896h-426.667c-70.571 0-128-57.429-128-128v-512c0-70.571 57.429-128 128-128h426.667c70.571 0 128 57.429 128 128v512c0 70.571-57.429 128-128 128zM298.667 213.333c-23.509 0-42.667 19.157-42.667 42.667v512c0 23.509 19.157 42.667 42.667 42.667h426.667c23.509 0 42.667-19.157 42.667-42.667v-512c0-23.509-19.157-42.667-42.667-42.667h-426.667z"
                  className=""
                ></path>
                <path
                  d="M682.667 469.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"
                  className=""
                ></path>
                <path
                  d="M682.667 341.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"
                  className=""
                ></path>
                <path
                  d="M682.667 597.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"
                  className=""
                ></path>
                <path
                  d="M682.667 725.333h-341.333c-11.776 0-21.333-9.557-21.333-21.333s9.557-21.333 21.333-21.333h341.333c11.776 0 21.333 9.557 21.333 21.333s-9.557 21.333-21.333 21.333z"
                  className=""
                ></path>
              </svg>
              <span className="">Log</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

MonitorDetailsComponent.defaultProps = {
  lastScan: '01.01.2024',
  listName: ' List Name',
  percentPreferences: '100%',
  sessionCookies: '06',
  totalContacts: '8.327 ',
  idNumber: '780183-bfx32',
  monitorName: 'Monitor Name',
  triggers: '361',
  preferences: '09',
  rootClassName: '',
}

MonitorDetailsComponent.propTypes = {
  lastScan: PropTypes.string,
  listName: PropTypes.string,
  percentPreferences: PropTypes.string,
  sessionCookies: PropTypes.string,
  totalContacts: PropTypes.string,
  idNumber: PropTypes.string,
  monitorName: PropTypes.string,
  triggers: PropTypes.string,
  preferences: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default MonitorDetailsComponent
