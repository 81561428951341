// src/contexts/DataContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthChange, login, register, logout as authLogout, subscribeToUserData } from "../services/authService";
import { get, ref, set } from "firebase/database";
import { database } from "../firebaseConfig";
import { useHistory } from 'react-router-dom';
import Loading from '../components/Loading';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.uid) {
        setUser(user);
        const unsubscribeFromUserData = subscribeToUserData(user.uid, (data) => {
          setUserData(data);
          localStorage.setItem('user', JSON.stringify({ uid: user.uid, ...data }));
          setIsDataLoading(false);
        });
        setIsInitializing(false);
        return () => {
          unsubscribeFromUserData();
        };
      } else {
        localStorage.removeItem('user');
        if (history) history.push('/login');
        setIsInitializing(false);
        setIsDataLoading(false);
      }
    } else {
      setIsInitializing(false);
      setIsDataLoading(false);
    }

    const unsubscribe = onAuthChange(async (user) => {
      if (user) {
        const userData = await getUserData(user.uid);
        if (userData) {
          setUser(userData);
          localStorage.setItem('user', JSON.stringify(userData));
          const unsubscribeFromUserData = subscribeToUserData(user.uid, (data) => {
            setUserData(data);
            localStorage.setItem('user', JSON.stringify({ uid: user.uid, ...data }));
            setIsDataLoading(false);
          });
          setIsInitializing(false);
          return () => {
            unsubscribeFromUserData();
          };
        } else {
          handleAuthError();
        }
      } else {
        handleAuthError();
      }
    });
    return () => unsubscribe();
  }, [history]);

  const handleAuthError = () => {
    setUser(null);
    setUserData(null);
    localStorage.removeItem('user');
    if (history) history.push('/login');
    setIsInitializing(false);
    setIsDataLoading(false);
  };

  const getUserData = async (uid) => {
    const userSnapshot = await get(ref(database, 'clients/' + uid));
    if (userSnapshot.exists()) {
      return { uid, ...userSnapshot.val() };
    }
    return null;
  };

  const uploadData = async (subPath, data) => {
    if (!user || !user.uid) {
      throw new Error('Benutzer ist nicht authentifiziert');
    }
    const fullPath = `clients/${user.uid}/${subPath}`;
    const dataRef = ref(database, fullPath);
    await set(dataRef, data);
  };

  const logout = () => {
    authLogout().then(() => {
      setUser(null);
      setUserData(null);
      localStorage.removeItem('user');
      history.push('/login');
    }).catch((error) => {
      console.error("Logout Fehler: ", error);
    });
  };

  return (
    <DataContext.Provider value={{ user, userData, login, register, logout, isInitializing, isDataLoading, uploadData }}>
      {(isInitializing || isDataLoading) && <Loading />}
      {!isInitializing && !isDataLoading && children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
