import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './settings-contact-list-item.css'
import { useData } from '../contexts/DataContext';

const SettingsContactListItem = (props) => {
  const [check, setCheck] = useState(false)
  const { userData } = useData();

  const id = props.id
  const list = userData?.lists[id] || {}
  const created = list.created ? new Date(parseInt(list.created)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';
  
  return (
    <div
      onClick={() => setCheck(!check)}
      className="settings-contact-list-item-settings-contact-list-item"
    >
      <div className="settings-contact-list-item-container">
        <div className="settings-contact-list-item-container1 preference-checkBox">
          {check && (
            <div className="settings-contact-list-item-container2">
              <svg
                viewBox="0 0 1024 1024"
                className="settings-contact-list-item-icon"
              >
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="settings-contact-list-item-container3">
        <div className="settings-contact-list-item-container4">
          <span>{list.name}</span>
          <span className="settings-contact-list-item-text1">
            {created}
          </span>
        </div>
      </div>
      <div className="settings-contact-list-item-container5">
        <span className="settings-contact-list-item-text2">
          {list.contacts?.length || '-'}
        </span>
      </div>
    </div>
  )
}

SettingsContactListItem.defaultProps = {
  contactSize: '99',
  listName: 'List name',
  createdOn: '--.--.----',
}

SettingsContactListItem.propTypes = {
  contactSize: PropTypes.string,
  listName: PropTypes.string,
  createdOn: PropTypes.string,
}

export default SettingsContactListItem
