import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListDetailsListNameItem from './list-details-list-name-item';
import './employee-overview-list.css';
import { useData } from '../contexts/DataContext';
import { downloadCsv } from '../utils/csvCreator';
import { notifyPromiseStart, notifyPromiseStop } from '../components/Notification';

const EmployeeOverviewList = (props) => {
  const { userData } = useData();
  
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredContactIds, setFilteredContactIds] = useState([]);

  useEffect(() => {
    if (userData) {
      const employeeIds = Object.keys(userData?.contacts || {}).filter(
        (id) => userData.contacts[id].type === 'employee'
      );
      setFilteredContactIds(employeeIds);
    }
  }, [userData]);

  useEffect(() => {
    if (userData) {
      const statusMapping = {
        pending: 0,
        employee: 1,
        'job changed': 2,
        'job exit': 3,
        'not found': 4
      };

      const employeeIds = Object.keys(userData?.contacts || {}).filter(
        (id) => userData.contacts[id].type === 'employee'
      );
      const filteredIds = employeeIds.filter(contactId => {
        const contact = userData.contacts[contactId];
        const query = searchQuery.toLowerCase();
        return (
          contact.contactName.toLowerCase().includes(query) ||
          contact.companyName.toLowerCase().includes(query) ||
          contact.activity.toLowerCase().includes(query) ||
          contact.position.toLowerCase().includes(query) ||
          String(contact.status).includes(query) ||
          Object.keys(statusMapping).some(key => key.includes(query) && statusMapping[key] === contact.status)
        );
      });
      setFilteredContactIds(filteredIds);
    }
  }, [searchQuery, userData]);

  const handleExport = async () => {
    notifyPromiseStart('Creating export file...');
    const dataToExport = filteredContactIds.map((id) => ({
      contactName: userData.contacts[id].contactName,
      companyName: userData.contacts[id].companyName,
      activity: userData.contacts[id].activity,
      position: userData.contacts[id].position,
      date: new Date(userData.contacts[id].created).toLocaleDateString(),
      status: userData.contacts[id].status
    }));
    await downloadCsv(dataToExport, `employee_contacts_export_${Date.now()}.csv`);
    notifyPromiseStop('Export file created successfully');
  };

  return (
    <div className="employee-overview-list-employee-overview-list">
      <div className="employee-overview-list-container">
        <div className="employee-overview-list-container01">
          <span className="employee-overview-list-text">All employees</span>
          <div className="employee-overview-list-container02">
            <span className="employee-overview-list-text1">
              Total employees:
            </span>
            <span className="employee-overview-list-text2">{filteredContactIds.length}</span>
          </div>
        </div>
        <div className="employee-overview-list-container04">
          <div className="employee-overview-list-container05">
            <svg
              viewBox="0 0 1024 1024"
              className="employee-overview-list-icon"
            >
              <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
            <input
              type="text"
              id="5000"
              placeholder="Contact, Company, Activity or Position"
              className="employee-overview-list-employees-overview-find"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div
            className="employee-overview-list-navlink"
            onClick={handleExport}
          >
            <div className="employee-overview-list-container06">
              <svg
                viewBox="0 0 1024 1024"
                className="employee-overview-list-icon2"
              >
                <path d="M214 768h596v86h-596v-86zM810 384l-298 298-298-298h170v-256h256v256h170z"></path>
              </svg>
              <span className="employee-overview-list-text5">Export List</span>
            </div>
          </div>
        </div>
      </div>
      <div className="employee-overview-list-container07">
        <div className="employee-overview-list-container08">
          <div className="employee-overview-list-container09">
            <span className="employee-overview-list-text6">
              Contact / Company
            </span>
          </div>
          <div className="employee-overview-list-container10">
            <span className="employee-overview-list-text7">
              Activity / Position
            </span>
          </div>
          <div className="employee-overview-list-container11">
            <span className="employee-overview-list-text8">Date / Status</span>
          </div>
          <div className="employee-overview-list-container12"></div>
        </div>
        <ul className="employee-overview-list-ul list">
          {filteredContactIds.map((id, index) => (
            <li key={index} className="list-item">
              <ListDetailsListNameItem id={id}></ListDetailsListNameItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EmployeeOverviewList;
