import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import MenuBar from '../components/menu-bar';
import TopBar from '../components/top-bar';
import ToggleBox from '../components/toggle-box';
import POPUP from '../components/popup';
import './session-cookie-connect.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const SessionCookieConnect = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query_cookieId = queryParams.get('id');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [cookieValue, setCookieValue] = useState('');
  const [cookieExpired, setCookieExpired] = useState(false);
  const [cookieInUse, setCookieInUse] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (query_cookieId && userData.cookies && userData.cookies[query_cookieId]) {
      const cookieData = userData.cookies[query_cookieId];
      setFirstName(cookieData.firstName);
      setLastName(cookieData.lastName);
      setEmail(cookieData.email);
      setCookieValue(cookieData.cookie);
      setCookieExpired(cookieData.cookieExpired);
      setCookieInUse(cookieData.cookieInUse);
      setIsUpdate(true);
    }
  }, [userData, query_cookieId]);

  const handleUpload = () => {
    if (!firstName || !lastName || !email || !cookieValue) {
      if (!firstName) {
        notify('The First Name field is required.', 3);
      }
      if (!lastName) {
        notify('The Last Name field is required.', 3);
      }
      if (!email) {
        notify('The Email field is required.', 3);
      }
      if (!cookieValue) {
        notify('The Cookie Value field is required.', 3);
      }
      return;
    }

    let newCookieData = {
      firstName,
      lastName,
      email,
      cookie: cookieValue,
      status: 'Pending', // or any other default status
      linkedin: true, // or other default value
      salesNavigator: true, // or other default value
      cookieExpired,
      cookieInUse,
      lastUsed: Date.now().toString(),
    };

    if (isUpdate) {
      // Update existing cookie
      const path = `cookies/${query_cookieId}`;
      uploadData(path, newCookieData)
        .then(() => {
          notify('Cookie updated successfully.', 1);
          history.push('/session-cookie');
        })
        .catch((error) => {
          notify('An error occurred while updating the cookie. Please try again.', 3);
        });
    } else {
      // Create new cookie
      const newCookieId = 'coo_' + Math.floor(100000 + Math.random() * 900000)
      const path = `cookies/${newCookieId}`;
      newCookieData.id = newCookieId
      uploadData(path, newCookieData)
        .then(() => {
          notify('Cookie created successfully.', 1);
          history.push('/session-cookie');
        })
        .catch((error) => {
          notify('An error occurred while creating the cookie. Please try again.', 3);
        });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className="session-cookie-connect-container">
      <Helmet>
        <title>Session-Cookie-Connect - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="Session-Cookie-Connect - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="session-cookie-connect-main">
        <MenuBar sessionCookie={true}></MenuBar>
        <div className="session-cookie-connect-content">
          <TopBar lable="Session Cookie"></TopBar>
          <div className="session-cookie-connect-content1">
            <div className="session-cookie-connect-container01">
              <div className="btn-main-white" onClick={handleCancel}>
                <svg
                  viewBox="0 0 1024 1024"
                  className="session-cookie-connect-icon"
                >
                  <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                </svg>
                <span className="session-cookie-connect-text">Cancel</span>
              </div>
              <div className="btn-main-lila" onClick={() => history.push('/construction')}>
                <svg
                  viewBox="0 0 1024 1024"
                  className="session-cookie-connect-icon3"
                >
                  <path d="M512 0c282.857 0 512 229.143 512 512s-229.143 512-512 512-512-229.143-512-512 229.143-512 512-512zM512 73.143c-74.286 0-144.571 18.857-206.286 51.429l110.857 110.857c30.286-10.286 62.286-16 95.429-16 33.714 0 65.143 5.714 95.429 16l110.857-110.857c-61.714-32.571-132-51.429-206.286-51.429zM124.571 718.286l110.857-110.857c-10.286-30.286-16-62.286-16-95.429 0-33.714 5.714-65.143 16-95.429l-110.857-110.857c-32.571 61.714-51.429 132-51.429 206.286s18.857 144.571 51.429 206.286zM512 950.857c74.286 0 144.571-18.857 206.286-51.429l-110.857-110.857c-30.286 10.286-61.714 16-95.429 16-33.143 0-65.143-5.714-95.429-16l-110.857 110.857c61.714 32.571 132 51.429 206.286 51.429zM512 731.429c121.143 0 219.429-98.286 219.429-219.429s-98.286-219.429-219.429-219.429-219.429 98.286-219.429 219.429 98.286 219.429 219.429 219.429zM788.571 607.429l110.857 110.857c32.571-61.714 51.429-132 51.429-206.286s-18.857-144.571-51.429-206.286l-110.857 110.857c10.286 30.286 16 62.286 16 95.429s-5.714 65.143-16 95.429z"></path>
                </svg>
                <span className="session-cookie-connect-text01">Support</span>
              </div>
            </div>
            <div className="session-cookie-connect-container04">
              <div className="session-cookie-connect-container05">
                <div className="session-cookie-connect-container06">
                  <span className="session-cookie-connect-text02">Owner</span>
                  <span className="info-txt">
                    Enter the owner's information for the cookie. Please provide the first name, last name, and email address. This data is required to identify and contact the cookie owner.
                  </span>
                </div>
                <div className="session-cookie-connect-container07">
                  <div className="session-cookie-connect-container08">
                    <input
                      type="text"
                      id="6000"
                      placeholder="First Name"
                      className="session-cookie-connect-session-cookie-overview-owner-name-first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="session-cookie-connect-container09">
                    <input
                      type="text"
                      id="6001"
                      placeholder="Last Name"
                      className="session-cookie-connect-session-cookie-overview-owner-name-last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="session-cookie-connect-container10">
                    <input
                      type="text"
                      id="6002"
                      placeholder="Email"
                      className="session-cookie-connect-session-cookie-overview-owner-name-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="session-cookie-connect-container11">
                <div className="session-cookie-connect-container12">
                  <span className="session-cookie-connect-text04">
                    Cookie Detail
                  </span>
                  <span className="info-txt">
                    Enter the LinkedIn session cookie value. This cookie is needed to enable faster processing for the monitors.
                  </span>
                </div>
                <div className="session-cookie-connect-container13">
                  <input
                    type="text"
                    id="6003"
                    placeholder="Cookie Value"
                    className="session-cookie-connect-session-cookie-overview-details-cookie-value"
                    value={cookieValue}
                    onChange={(e) => setCookieValue(e.target.value)}
                  />
                </div>
              </div>
              <div className="session-cookie-connect-container22">
                <div className="btn-main-white" onClick={handleCancel}>
                  <svg
                    viewBox="0 0 1024 1024"
                    className="session-cookie-connect-icon5"
                  >
                    <path d="M672 256l-160 160-160-160-96 96 160 160-160 160 96 96 160-160 160 160 96-96-160-160 160-160z"></path>
                  </svg>
                  <span className="session-cookie-connect-text12">Cancel</span>
                </div>
                <div
                  className="session-cookie-connect-container24"
                  onClick={handleUpload}
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="session-cookie-connect-icon7"
                  >
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                  <span className="session-cookie-connect-text13">
                    {isUpdate ? 'Save Cookie' : 'Connect Cookie'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  );
};

export default SessionCookieConnect;
