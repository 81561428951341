import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChargeWalletItem from './charge-wallet-item';
import { paylink } from '../utils/stripePay';
import './charge-wallet-component.css';
import { useData } from '../contexts/DataContext';
import { notify} from '../components/Notification';

const ChargeWalletComponent = (props) => {
  const { user, userData, uploadData } = useData();
  const [chargeValue, setChargeValue] = useState('');
  const [createPayLink, setCreatePayLink] = useState(false);
  const [loadingText, setLoadingText] = useState('Create pay link');

  let transaction = Object.keys(userData?.wallet?.transaction || {});

  transaction = transaction.sort((a, b) => {
    const timestampA = userData.wallet.transaction[a].timestamp;
    const timestampB = userData.wallet.transaction[b].timestamp;
    return timestampB - timestampA; // Absteigende Sortierung
  });


  useEffect(() => {
    let interval;
    if (createPayLink) {
      interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev === 'Create pay link...') return 'Create pay link';
          return prev + '.';
        });
      }, 500);
    } else {
      setLoadingText('Create pay link');
    }
    return () => clearInterval(interval);
  }, [createPayLink]);

  const handlePayWithCard = async () => {
    if (!chargeValue) {
      notify('Charge value is required!', 3); // Status 0 für keine Ikone
      return;
    }
    setCreatePayLink(true);
    try {
      const newTransactionId = 'tra_' + Math.floor(100000 + Math.random() * 900000);
      const amountInCents = parseFloat(chargeValue.replace('€', '').trim()) * 100;

      const session = await paylink(amountInCents, { uid: user.uid, transactionId: newTransactionId });
      
      uploadData(`wallet/transaction/${newTransactionId}`, {
        status: 0,
        timestamp: await session.created,
        type: 'charge',
        value: await session.amount_total
      })
      window.open(await session.url, '_blank'); // Öffne den Zahlungslink in einem neuen Tab
      setCreatePayLink(false); // Stoppe die Animation und setze den Button zurück
    } catch (error) {
      console.error('Fehler beim Erstellen des Zahlungslinks:', error);
      setCreatePayLink(false);
    }
  };

  return (
    <div className={`charge-wallet-component-charge-wallet ${props.rootClassName} `}>
      <div className="charge-wallet-component-container">
        <div className="charge-wallet-component-container1">
          <div className="charge-wallet-component-container2">
            <span className="charge-wallet-component-text">Charge Value</span>
            <span className="charge-wallet-component-text1">
              Select all the preferences you want to set on this monitor.
            </span>
          </div>
          <div className="charge-wallet-component-container3">
            <div className="charge-wallet-component-container4">
              <input
                type="text"
                id="3001"
                placeholder="100€"
                className="charge-wallet-component-monitor-create-monitor-monitor-name"
                value={chargeValue}
                onChange={(e) => setChargeValue(e.target.value)}
              />
            </div>
            {!createPayLink && (
              <div
                className="charge-wallet-component-navlink"
                onClick={handlePayWithCard}
              >
                <div className="charge-wallet-component-container5">
                  <svg
                    viewBox="0 0 1097.142857142857 1024"
                    className="charge-wallet-component-icon"
                  >
                    <path d="M1005.714 73.143c50.286 0 91.429 41.143 91.429 91.429v694.857c0 50.286-41.143 91.429-91.429 91.429h-914.286c-50.286 0-91.429-41.143-91.429-91.429v-694.857c0-50.286 41.143-91.429 91.429-91.429h914.286zM91.429 146.286c-9.714 0-18.286 8.571-18.286 18.286v128h950.857v-128c0-9.714-8.571-18.286-18.286-18.286h-914.286zM1005.714 877.714c9.714 0 18.286-8.571 18.286-18.286v-347.429h-950.857v347.429c0 9.714 8.571 18.286 18.286 18.286h914.286zM146.286 804.571v-73.143h146.286v73.143h-146.286zM365.714 804.571v-73.143h219.429v73.143h-219.429z"></path>
                  </svg>
                  <span className="charge-wallet-component-text2">
                    Pay with card
                  </span>
                </div>
              </div>
            )}
            {createPayLink && (
              <div className="charge-wallet-component-navlink1">
                <div className="charge-wallet-component-container6">
                  <svg
                    viewBox="0 0 1097.142857142857 1024"
                    className="charge-wallet-component-icon2"
                  >
                    <path d="M1005.714 73.143c50.286 0 91.429 41.143 91.429 91.429v694.857c0 50.286-41.143 91.429-91.429 91.429h-914.286c-50.286 0-91.429-41.143-91.429-91.429v-694.857c0-50.286 41.143-91.429 91.429-91.429h914.286zM91.429 146.286c-9.714 0-18.286 8.571-18.286 18.286v128h950.857v-128c0-9.714-8.571-18.286-18.286-18.286h-914.286zM1005.714 877.714c9.714 0 18.286-8.571 18.286-18.286v-347.429h-950.857v347.429c0 9.714 8.571 18.286 18.286 18.286h914.286zM146.286 804.571v-73.143h146.286v73.143h-146.286zM365.714 804.571v-73.143h219.429v73.143h-219.429z"></path>
                  </svg>
                  <span className="charge-wallet-component-text3">
                    {loadingText}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="charge-wallet-component-container7">
        <div className="charge-wallet-component-container8 border-bottom bg-gradient">
          <span className="charge-wallet-component-text4">Recent Charged</span>
        </div>
        <ul className="charge-wallet-component-ul list">
          {transaction.map((id, index) => (
            <li key={index} className="list-item">
              <ChargeWalletItem id={id}></ChargeWalletItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ChargeWalletComponent.defaultProps = {
  rootClassName: '',
};

ChargeWalletComponent.propTypes = {
  rootClassName: PropTypes.string,
};

export default ChargeWalletComponent;
