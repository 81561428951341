import Papa from 'papaparse';
import { read, utils } from 'xlsx';

export const fileToJSON = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      
      try {
        if (fileExtension === 'csv') {
          Papa.parse(event.target.result, {
            header: true,
            skipEmptyLines: true, // Leere Zeilen überspringen
            complete: (results) => {
              // Filtere die Daten, um vollständig leere Zeilen zu entfernen
              const filteredData = results.data.filter(row => 
                Object.values(row).some(value => value !== null && value !== '')
              );
              resolve(filteredData);
            },
            error: (error) => {
              reject(error);
            }
          });
        } else if (fileExtension === 'xlsx') {
          const workbook = read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet, { blankrows: false }); // Leere Zeilen überspringen
          const filteredData = json.filter(row => 
            Object.values(row).some(value => value !== null && value !== '')
          );
          resolve(filteredData);
        } else {
          reject(new Error('Unsupported file type'));
        }
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(file);
  });
};
