import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SettingsContactListItem from './settings-contact-list-item';
import CheckBox from './check-box';
import SettingsCookieGroupItem from './settings-cookie-group-item';
import SettingsNotificationChannelItem from './settings-notification-channel-item';
import ToggleBox from './toggle-box';
import './monitor-create-form.css';
import { useData } from '../contexts/DataContext';
import { notify } from '../components/Notification';

const MonitorCreateForm = (props) => {
  const { userData, uploadData } = useData();
  const history = useHistory();

  const contactLists = Object.keys(userData?.lists || {});
  const cookieGroups = Object.keys(userData?.cookieGroups || {});
  const notificationChannels = Object.keys(userData?.channelGroups || {});
  const activities = ['Admin', 'BizDev', 'Business Intelligence', 'Controlling', 'Customer Care', 'Design', 'Finance', 'HR', 'IT', 'Legal', 'Marketing', 'Operations', 'Procurement', 'Product', 'Sales', 'Supply Chain'];
  const positions = ['C-Level', 'Coach', 'Co-Founder', 'Consultant', 'Director', 'Employee', 'Freelance', 'Head of', 'Intern', 'Investor', 'Manager', 'Partner', 'Student', 'Team Lead', 'VP'];

  const [monitorName, setMonitorName] = useState('');
  const [selectedContactList, setSelectedContactList] = useState('');
  const [selectedCookieGroups, setSelectedCookieGroups] = useState([]);
  const [selectedNotificationChannels, setSelectedNotificationChannels] = useState([]);
  const [preferencesActivities, setPreferencesActivities] = useState([]);
  const [preferencesPositions, setPreferencesPositions] = useState([]);
  const [budgetLimit, setBudgetLimit] = useState('10');
  const [notificationTypes, setNotificationTypes] = useState({
    whenTriggered: false,
    scanCompletion: false,
    weeklyReport: false,
    budget50: false,
    budget80: false,
    budget100: false,
  });

  const handleUpload = () => {
    if (!monitorName || !selectedContactList || !budgetLimit || selectedCookieGroups.length === 0 || selectedNotificationChannels.length === 0 || preferencesActivities.length === 0 || preferencesPositions.length === 0 || !Object.values(notificationTypes).some(type => type)) {
      if (!monitorName) {
        notify('The Monitor Name field is required.', 3);
      }
      if (!selectedContactList) {
        notify('The Contact List field is required.', 3);
      }
      if (!budgetLimit) {
        notify('The Budget Limit field is required.', 3);
      }
      if (selectedCookieGroups.length === 0) {
        notify('The Cookie Group field is required.', 3);
      }
      if (selectedNotificationChannels.length === 0) {
        notify('The Notification Channel field is required.', 3);
      }
      if (preferencesActivities.length === 0) {
        notify('The Preferences Activities field is required.', 3);
      }
      if (preferencesPositions.length === 0) {
        notify('The Preferences Positions field is required.', 3);
      }
      if (!Object.values(notificationTypes).some(type => type)) {
        notify('At least one Notification Type is required.', 3);
      }
      return;
    }

    const newMonitorId = `mon_`+ Math.floor(100000 + Math.random() * 900000);;

    const newMonitorData = {
      monitorName,
      list: selectedContactList,
      cookieGroup: selectedCookieGroups[0],
      channelGroup: selectedNotificationChannels[0],
      lastScan: Date.now().toString(),
      triggers: [],
      preferences: [...preferencesActivities, ...preferencesPositions],
      status: 2,
      budgetLimit,
      notificationTypes,
      delete:false,
      monitorId: newMonitorId,
    };

    const path = `monitors/${newMonitorId}`;
    uploadData(path, newMonitorData);

    history.push('/monitor');
  };

  const handleToggle = (type) => {
    setNotificationTypes((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleSelectContactList = (id) => {
    setSelectedContactList(id);
  };

  const handleSelectCookieGroup = (id) => {
    setSelectedCookieGroups((prev) =>
      prev.includes(id) ? prev.filter((group) => group !== id) : [...prev, id]
    );
  };

  const handleSelectNotificationChannel = (id) => {
    setSelectedNotificationChannels((prev) =>
      prev.includes(id) ? prev.filter((channel) => channel !== id) : [...prev, id]
    );
  };

  const handleToggleActivity = (activity) => {
    setPreferencesActivities((prev) =>
      prev.includes(activity) ? prev.filter((a) => a !== activity) : [...prev, activity]
    );
  };

  const handleTogglePosition = (position) => {
    setPreferencesPositions((prev) =>
      prev.includes(position) ? prev.filter((p) => p !== position) : [...prev, position]
    );
  };

  return (
    <div className="monitor-create-form-monitor-create">
      <div className="monitor-create-form-container">
        <div className="monitor-create-form-container01">
          <span className="monitor-create-form-text">Monitor name</span>
          <span className="info-txt">
            Enter the name of the monitor. This name helps to identify and manage the monitor effectively.
          </span>
        </div>
        <div className="monitor-create-form-container02">
          <input
            type="text"
            id="3001"
            placeholder="Monitor-001"
            className="monitor-create-form-monitor-create-monitor-monitor-name"
            value={monitorName}
            onChange={(e) => setMonitorName(e.target.value)}
          />
        </div>
      </div>
      <div className="monitor-create-form-container03">
        <div className="monitor-create-form-container04">
          <div className="monitor-create-form-container05">
            <span className="monitor-create-form-text02">Contact list</span>
            <span className="info-txt">
              Select one of the uploaded contact lists. The monitor will use this list to scan and search for the specified contacts. This helps in efficiently identifying and tracking the contacts you are looking for.
            </span>
          </div>
          <div className="monitor-create-form-container06">
            <span className="monitor-create-form-text04">(Single select)</span>
          </div>
        </div>
        <div className="monitor-create-form-container07">
          <div className="monitor-create-form-container08">
            <div className="monitor-create-form-container09"></div>
            <div className="monitor-create-form-container10">
              <span className="monitor-create-form-text05">Name / Data</span>
            </div>
            <div className="monitor-create-form-container11">
              <span className="monitor-create-form-text06">Contacts</span>
            </div>
          </div>
          <div className="monitor-create-form-container12">
            <ul className="list">
              {contactLists.map((id, index) => (
                <li key={index} className="monitor-create-form-li list-item">
                  <div onClick={() => handleSelectContactList(id)}>
                    <SettingsContactListItem id={id} isSelected={selectedContactList === id} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container13">
        <div className="monitor-create-form-container14">
          <span className="monitor-create-form-text07">
            <span className="monitor-create-form-text08">
              <span>
                Preferences -
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <span className="monitor-create-form-text11">Activities</span>
          </span>
          <span className="monitor-create-form-text12">
            Select all the preferences you want to set on this monitor1111.
          </span>
        </div>
        <div className="monitor-create-form-container15">
          <span className="monitor-create-form-text13">
            Preferences - Activities
          </span>
          <span className="info-txt">
            Select the preferences that should trigger an action when there are changes in activities. These settings allow you to define specific actions and notifications for different areas within the organization, ensuring an optimal response to changes in activities.
          </span>
        </div>
        <div className="monitor-create-form-container16">
          <div className="monitor-create-form-container17">
            {activities.map((activity, index) => (
              <div key={index} className="preference-checkBox-container">
                <div onClick={() => handleToggleActivity(activity)}>
                  <CheckBox checked={preferencesActivities.includes(activity)} />
                </div>
                <span className="monitor-create-form-text16">{activity}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container32">
        <div className="monitor-create-form-container33">
          <span className="monitor-create-form-text31">
            Preferences - Positions
          </span>
          <span className="info-txt">
            Select the preferences that should trigger an action when there are changes in positions. These preferences help you define targeted actions and notifications for various roles within the organization, ensuring that relevant triggers are activated when there are changes in positions.
          </span>
        </div>
        <div className="monitor-create-form-container34">
          <div className="monitor-create-form-container35">
            {positions.map((position, index) => (
              <div key={index} className="preference-checkBox-container">
                <div onClick={() => handleTogglePosition(position)}>
                  <CheckBox checked={preferencesPositions.includes(position)} />
                </div>
                <span className="monitor-create-form-text34">{position}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container50">
        <div className="monitor-create-form-container51">
          <span className="monitor-create-form-text47">Budget limit</span>
          <span className="info-txt">
            Specify the maximum budget that this monitor is allowed to use. This ensures that the monitor operates within the defined financial constraints and helps in effective budget management.
          </span>
        </div>
        <div className="monitor-create-form-container52">
          <div className="monitor-create-form-container53">
            <div className="monitor-create-form-container54">
              <span className="monitor-create-form-text49">Maximal  Value</span>
              <span className="monitor-create-form-text50">
                Specify your maximum budget.
              </span>
            </div>
            <div className="monitor-create-form-container55">
              <div className="monitor-create-form-container56">
                <span className="monitor-create-form-text51">€</span>
                <input
                  type="text"
                  id="3003"
                  placeholder="100"
                  className="monitor-create-form-monitor-create-monitor-budget-limit"
                  value={budgetLimit}
                  onChange={(e) => setBudgetLimit(e.target.value)}
                />
              </div>
              <span className="monitor-create-form-text52">
                Click the value to edit
              </span>
            </div>
            <div className="monitor-create-form-container57">
              <svg viewBox="0 0 1024 1024" className="monitor-create-form-icon">
                <path d="M928 128h-832c-52.8 0-96 43.2-96 96v576c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-576c0-52.8-43.2-96-96-96zM96 192h832c17.346 0 32 14.654 32 32v96h-896v-96c0-17.346 14.654-32 32-32zM928 832h-832c-17.346 0-32-14.654-32-32v-288h896v288c0 17.346-14.654 32-32 32zM128 640h64v128h-64zM256 640h64v128h-64zM384 640h64v128h-64z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container58">
        <div className="monitor-create-form-container59">
          <div className="monitor-create-form-container60">
            <span className="monitor-create-form-text53">Cookie Group</span>
            <span className="info-txt">
            Select the group of cookies that this monitor is allowed to use. This ensures the monitor operates with the specified set of cookies for proper functionality.
            </span>
          </div>
          <div className="monitor-create-form-container61">
            <span className="monitor-create-form-text55">(Multi select)</span>
          </div>
        </div>
        <div className="monitor-create-form-container62">
          <div className="monitor-create-form-container63">
            <div className="monitor-create-form-container64">
              <CheckBox rootClassName="check-box-root-class-name128"></CheckBox>
            </div>
            <div className="monitor-create-form-container65">
              <span className="monitor-create-form-text56">Group / Used</span>
            </div>
            <div className="monitor-create-form-container66">
              <span className="monitor-create-form-text57">Cookie size</span>
            </div>
          </div>
          <div className="monitor-create-form-container67">
            <ul className="list">
              {cookieGroups.map((id, index) => (
                <li key={index} className="monitor-create-form-li08 list-item">
                  <div onClick={() => handleSelectCookieGroup(id)}>
                    <SettingsCookieGroupItem id={id} isSelected={selectedCookieGroups.includes(id)} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container68">
        <div className="monitor-create-form-container69">
          <div className="monitor-create-form-container70">
            <span className="monitor-create-form-text58">
              Channel group
            </span>
            <span className="info-txt">
            Select the notification group that contains the contacts to be notified of any changes. This ensures that the relevant contacts receive updates and alerts as specified.
            </span>
          </div>
          <div className="monitor-create-form-container71">
            <span className="monitor-create-form-text60">(Multi select)</span>
          </div>
        </div>
        <div className="monitor-create-form-container72">
          <div className="monitor-create-form-container73">
            <div className="monitor-create-form-container74">
              <CheckBox rootClassName="check-box-root-class-name164"></CheckBox>
            </div>
            <div className="monitor-create-form-container75">
              <span className="monitor-create-form-text61">Channel</span>
            </div>
            <div className="monitor-create-form-container76">
              <span className="monitor-create-form-text62">Contact size</span>
            </div>
          </div>
          <div className="monitor-create-form-container77">
            <ul className="list">
              {notificationChannels.map((id, index) => (
                <li key={index} className="monitor-create-form-li09 list-item">
                  <div onClick={() => handleSelectNotificationChannel(id)}>
                    <SettingsNotificationChannelItem id={id} isSelected={selectedNotificationChannels.includes(id)} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container78">
        <div className="monitor-create-form-container79">
          <span className="monitor-create-form-text63">
            Notifications types
          </span>
          <span className="info-txt">
          Select the types of notifications you want to enable. This allows you to choose how you will be alerted about updates and changes, ensuring you receive information in the most suitable format.
          </span>
        </div>
        <div className="monitor-create-form-container80">
          <div className="monitor-create-form-container81">
            <div className="monitor-create-form-container82">
              <div className="monitor-create-form-container83">
                <span className="monitor-create-form-text65">
                  When triggered
                </span>
                <span className="monitor-create-form-text66">
                  Receive a notification as soon as a trigger becomes active.
                </span>
              </div>
              <div onClick={() => handleToggle('whenTriggered')}>
                <ToggleBox checked={notificationTypes.whenTriggered} />
              </div>
            </div>
            <div className="monitor-create-form-container84">
              <div className="monitor-create-form-container85">
                <span className="monitor-create-form-text67">
                  Scan completion
                </span>
                <span className="monitor-create-form-text68">
                  Receive a notification as soon as the scan is complete.
                </span>
              </div>
              <div onClick={() => handleToggle('scanCompletion')}>
                <ToggleBox checked={notificationTypes.scanCompletion} />
              </div>
            </div>
          </div>
          <div className="monitor-create-form-container86">
            <div className="monitor-create-form-container87">
              <div className="monitor-create-form-container88">
                <span className="monitor-create-form-text69">
                  Weekly report
                </span>
                <span className="monitor-create-form-text70">
                  Receive a report notification every week.
                </span>
              </div>
              <div onClick={() => handleToggle('weeklyReport')}>
                <ToggleBox checked={notificationTypes.weeklyReport} />
              </div>
            </div>
            <div className="monitor-create-form-container89">
              <div className="monitor-create-form-container90">
                <span className="monitor-create-form-text71">Budget 50%</span>
                <span className="monitor-create-form-text72">
                  You will be notified as soon as your budget reaches 50%
                </span>
              </div>
              <div onClick={() => handleToggle('budget50')}>
                <ToggleBox checked={notificationTypes.budget50} />
              </div>
            </div>
          </div>
          <div className="monitor-create-form-container91">
            <div className="monitor-create-form-container92">
              <div className="monitor-create-form-container93">
                <span className="monitor-create-form-text73">Budget 80%</span>
                <span className="monitor-create-form-text74">
                  You will be notified as soon as your budget reaches 80%
                </span>
              </div>
              <div onClick={() => handleToggle('budget80')}>
                <ToggleBox checked={notificationTypes.budget80} />
              </div>
            </div>
            <div className="monitor-create-form-container94">
              <div className="monitor-create-form-container95">
                <span className="monitor-create-form-text75">Budget 100%</span>
                <span className="monitor-create-form-text76">
                  You will be notified as soon as your budget reaches 100%
                </span>
              </div>
              <div onClick={() => handleToggle('budget100')}>
                <ToggleBox checked={notificationTypes.budget100} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="monitor-create-form-container96">
        <Link to="/monitor" className="monitor-create-form-navlink">
          <div className="monitor-create-form-container97 btn-main-white">
            <svg viewBox="0 0 1024 1024" className="monitor-create-form-icon2">
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span className="monitor-create-form-text77">Cancel</span>
          </div>
        </Link>
        <div className="monitor-create-form-container98" onClick={handleUpload}>
          <svg viewBox="0 0 1024 1024" className="monitor-create-form-icon4">
            <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
          </svg>
          <span className="monitor-create-form-text78">Create Monitor</span>
        </div>
      </div>
    </div>
  );
};

export default MonitorCreateForm;
