import React from 'react';
import PropTypes from 'prop-types';
import DashboardOverviewContactsItem from './dashboard-overview-contacts-item';
import './dashboard-overview-total-contacts.css';
import { useData } from '../contexts/DataContext';

const DashboardOverviewTotalContacts = (props) => {
  const { userData } = useData();

  const monitorPreferences = Object.values(userData?.monitors || {}).reduce((acc, monitor) => {
    monitor.preferences.forEach(preference => {
      if (!acc.includes(preference)) {
        acc.push(preference);
      }
    });
    return acc;
  }, []);

  const contacts = Object.values(userData?.contacts || {}).filter(contact => (contact.status === 1 || contact.status === 2 || contact.status === 3) && contact.delete === false);


  const activityCounts = {};
  const positionCounts = {};

  contacts.forEach(contact => {
    const { activity, position } = contact;

    if (activity) {
      activityCounts[activity] = (activityCounts[activity] || 0) + 1;
    }

    if (position) {
      positionCounts[position] = (positionCounts[position] || 0) + 1;
    }
  });

  const preferences = [];

  contacts.forEach(contact => {
    if (activityCounts[contact.activity] && monitorPreferences.includes(contact.activity)) {
      preferences.push({
        name: contact.activity,
        count: activityCounts[contact.activity],
        isPreference: true
      });
    } else if (positionCounts[contact.position] && monitorPreferences.includes(contact.position)) {
      preferences.push({
        name: contact.position,
        count: positionCounts[contact.position],
        isPreference: true
      });
    }
  });

  const totalContacts = contacts.length;
  const preferenceCount = preferences.reduce((sum, pref) => sum + pref.count, 0);
  const preferencePercent = totalContacts > 0 ? ((preferenceCount / totalContacts) * 100).toFixed(2) : '0.00';

  return (
    <div className="dashboard-overview-total-contacts-dashboard-obreview-total-contacts">
      <div className="dashboard-overview-total-contacts-container border-bottom bg-gradient">
        <div className="dashboard-overview-total-contacts-container1">
          <span className="dashboard-overview-total-contacts-text">
            Total contacts
          </span>
          <span className="dashboard-overview-total-contacts-text1">
            {Object.keys(contacts).length}
          </span>
        </div>
        <div className="dashboard-overview-total-contacts-container2">
          <span className="dashboard-overview-total-contacts-text2">
            Preference
          </span>
          <span className="dashboard-overview-total-contacts-text3">
            {preferencePercent + '%'}
          </span>
        </div>
      </div>
      <div className="dashboard-overview-total-contacts-container3">
        <ul className="dashboard-overview-total-contacts-ul list">
          {preferences.map((item, index) => (
            <li key={index} className="list-item">
              <DashboardOverviewContactsItem item={item}></DashboardOverviewContactsItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

DashboardOverviewTotalContacts.defaultProps = {
  preferencePercent: '15.91%',
  numberContacts: '1889',
}

DashboardOverviewTotalContacts.propTypes = {
  preferencePercent: PropTypes.string,
  numberContacts: PropTypes.string,
}

export default DashboardOverviewTotalContacts
