import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import POPUP from '../components/popup'
import './events-contacts.css'

const EventsContacts = (props) => {
  return (
    <div className="events-contacts-container">
      <Helmet>
        <title>Events-Contacts - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="Events-Contacts - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="events-contacts-main">
        <MenuBar></MenuBar>
        <div className="events-contacts-content">
          <TopBar lable="Event" lable2="Contacts"></TopBar>
          <div className="events-contacts-employee-overview-list">
            <div className="events-contacts-container01">
              <div className="events-contacts-container02">
                <span className="events-contacts-text">Contacts</span>
                <div className="events-contacts-container03">
                  <span className="events-contacts-text01">
                    Total contacts:
                  </span>
                  <span className="events-contacts-text02">0</span>
                </div>
              </div>
              <div className="events-contacts-container04">
                <div className="events-contacts-container05">
                  <svg viewBox="0 0 1024 1024" className="events-contacts-icon">
                    <path d="M684.416 676.523c-1.451 1.109-2.859 2.347-4.224 3.712s-2.56 2.731-3.712 4.224c-53.675 51.755-126.677 83.541-207.147 83.541-82.475 0-157.099-33.365-211.2-87.467s-87.467-128.725-87.467-211.2 33.365-157.099 87.467-211.2 128.725-87.467 211.2-87.467 157.099 33.365 211.2 87.467 87.467 128.725 87.467 211.2c0 80.469-31.787 153.472-83.584 207.189zM926.165 865.835l-156.8-156.8c52.523-65.707 83.968-149.035 83.968-239.701 0-106.027-43.008-202.069-112.469-271.531s-165.504-112.469-271.531-112.469-202.069 43.008-271.531 112.469-112.469 165.504-112.469 271.531 43.008 202.069 112.469 271.531 165.504 112.469 271.531 112.469c90.667 0 173.995-31.445 239.701-83.968l156.8 156.8c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                  </svg>
                  <input
                    type="text"
                    id="5000"
                    placeholder="Contact, Company, Activity or Position"
                    className="events-contacts-employees-overview-find"
                  />
                </div>
                <Link
                  to="/list-upload-list"
                  className="events-contacts-navlink"
                >
                  <div className="events-contacts-container06">
                    <svg
                      viewBox="0 0 1024 1024"
                      className="events-contacts-icon2"
                    >
                      <path d="M214 768h596v86h-596v-86zM810 384l-298 298-298-298h170v-256h256v256h170z"></path>
                    </svg>
                    <span className="events-contacts-text03">Export List</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="events-contacts-container07">
              <div className="events-contacts-container08">
                <div className="events-contacts-container09">
                  <span className="events-contacts-text04">
                    Contact / Company
                  </span>
                </div>
                <div className="events-contacts-container10">
                  <span className="events-contacts-text05">
                    Activity / Position
                  </span>
                </div>
                <div className="events-contacts-container11">
                  <span className="events-contacts-text06">Event / Status</span>
                </div>
                <div className="events-contacts-container12"></div>
              </div>
              <ul className="events-contacts-ul list">
                <li className="list-item">
                  <div className="events-contacts-list-details-list-name-item">
                    <div className="events-contacts-container13">
                      <div className="events-contacts-container14">
                        <img
                          alt="image"
                          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"
                          className="events-contacts-image"
                        />
                      </div>
                      <div className="events-contacts-container15">
                        <span>Contact Full Name</span>
                        <span className="events-contacts-text08">
                          Company Name
                        </span>
                      </div>
                    </div>
                    <div className="events-contacts-container16">
                      <div className="events-contacts-container17">
                        <span>Description</span>
                        <div className="events-contacts-container18">
                          <span className="events-contacts-text10">
                            Activity
                          </span>
                          <span className="events-contacts-text11"> / </span>
                          <span className="events-contacts-text12">
                            Position
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="events-contacts-container19">
                      <div className="events-contacts-container20">
                        <span className="events-contacts-text13">NIS2</span>
                        <div className="events-contacts-container21">
                          <span className="events-contacts-text14">
                            Not contacted
                          </span>
                          <span className="events-contacts-text15">
                            Contacted
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="events-contacts-container22">
                      <div className="events-contacts-container23">
                        <svg
                          viewBox="0 0 1024 1024"
                          className="events-contacts-icon4"
                        >
                          <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                        </svg>
                      </div>
                      <div className="events-contacts-container24">
                        <span className="events-contacts-text16">LinkedIn</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default EventsContacts
