import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './check-box.css'

const CheckBox = (props) => {
  const [check, setCheck] = useState(false)
  return (
    <div
      onClick={() => setCheck(!check)}
      className={`check-box-container preference-checkBox ${props.rootClassName} `}
    >
      {check && (
        <div className="check-box-container1">
          <svg viewBox="0 0 1024 1024" className="check-box-icon">
            <path
              d="M384 690l452-452 60 60-512 512-238-238 60-60z"
              className=""
            ></path>
          </svg>
        </div>
      )}
    </div>
  )
}

CheckBox.defaultProps = {
  rootClassName: '',
}

CheckBox.propTypes = {
  rootClassName: PropTypes.string,
}

export default CheckBox
