import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './monitor-overview-item.css'
import { useData } from '../contexts/DataContext'

const MonitorOverviewItem = (props) => {
  const { userData } = useData()

  const id = props.id;
  const [status, setStatus] = useState(userData?.monitors[id]?.status || 0);
  const monitorName = userData?.monitors[id]?.monitorName || ".....";
  const listId = userData?.monitors[id]?.list || [];
  const list = userData?.lists[listId] || {};
  const lastScanTimestamp = userData?.monitors[id]?.lastScan;
  const lastScan = lastScanTimestamp ? new Date(parseInt(lastScanTimestamp)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) : '--.--.----';
  const triggers = list.contacts.filter(contactID => userData?.contacts[contactID]?.triggered === true)

  
  return (
    <div className="monitor-overview-item-monitor-overview-item">
      <div className="monitor-overview-item-container">
        <span className="monitor-overview-item-text">{monitorName}</span>
        <div className="monitor-overview-item-container01">
          <span className="monitor-overview-item-text01">ID: </span>
          <span className="monitor-overview-item-text02">{id}</span>
        </div>
      </div>
      <div className="monitor-overview-item-container02">
        <div className="monitor-overview-item-container03">
          <span className="monitor-overview-item-text03">Contacts: </span>
          <span className="monitor-overview-item-text04">{list.contacts.length}</span>
        </div>
        <div className="monitor-overview-item-container04">
          <span className="monitor-overview-item-text05">List: </span>
          <span className="monitor-overview-item-text06">{list.name}</span>
        </div>
      </div>
      <div className="monitor-overview-item-container05">
        <div className="monitor-overview-item-container06">
          <span className="monitor-overview-item-text07">Last Scan: </span>
          <span className="monitor-overview-item-text08">{lastScan}</span>
        </div>
        <div className="monitor-overview-item-container07">
          <span className="monitor-overview-item-text09">Triggers: </span>
          <span className="monitor-overview-item-text10">{triggers.length}</span>
        </div>
      </div>
      <div className="monitor-overview-item-status">
        {status === 1 && (
          <div className="monitor-overview-item-container08">
            <div className="monitor-overview-item-container09"></div>
            <span className="monitor-overview-item-text11">Running</span>
          </div>
        )}
        {status === 2 && (
          <div className="monitor-overview-item-container10">
            <div className="monitor-overview-item-container11"></div>
            <span className="monitor-overview-item-text12">Initialization</span>
          </div>
        )}
        {status === 0 && (
          <div className="monitor-overview-item-container12">
            <div className="monitor-overview-item-container13"></div>
            <span className="monitor-overview-item-text13">Stop</span>
          </div>
        )}
        {status === 3 && (
          <div className="monitor-overview-item-container14">
            <div className="monitor-overview-item-container15"></div>
            <span className="monitor-overview-item-text14">Error</span>
          </div>
        )}
        <Link to={`/monitor-details?id=${id}`} className="monitor-overview-item-navlink">
          <div className="monitor-overview-item-container16">
            <svg viewBox="0 0 1024 1024" className="monitor-overview-item-icon">
              <path d="M426 256l256 256-256 256-60-60 196-196-196-196z"></path>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  )
}

MonitorOverviewItem.defaultProps = {
  contacts: '8.327',
  monitorName: 'Monitor Name',
  listName: 'List Name',
  id: '780183-bfx32',
  lastScan: '01.01.2024',
  trigger: '99',
}

MonitorOverviewItem.propTypes = {
  contacts: PropTypes.string,
  monitorName: PropTypes.string,
  listName: PropTypes.string,
  id: PropTypes.string,
  lastScan: PropTypes.string,
  trigger: PropTypes.string,
}

export default MonitorOverviewItem
