import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './menu-bar.css'

const MenuBar = (props) => {
  const [btnLog, setBtnLog] = useState(props.log || false)
  const [btnEventsContact, setBtnEventsContact] = useState(props.eventsContact || false)
  const [btnSettings, setBtnSettings] = useState(props.settings || false)
  const [btnBilling, setBtnBilling] = useState(props.billing || false)
  const [btnChannels, setBtnChannels] = useState(props.channels || false)
  const [btnList, setBtnList] = useState(props.list || false)
  const [btnDashboard, setBtnDashboard] = useState(props.dashboard || false)
  const [btnMonitor, setBtnMonitor] = useState(props.monitor || false)
  const [btnEmployees, setBtnEmployees] = useState(props.employees || false)
  const [btnTriggers, setBtnTriggers] = useState(props.triggers || false)
  const [btnSavingsPlans, setBtnSavingsPlans] = useState(props.savingsPlans || false)
  const [btnEventsMonitor, setBtnEventsMonitor] = useState(props.eventsMonitor || false)
  const [btnSessionCookie, setBtnSessionCookie] = useState(props.sessionCookie || false)

  return (
    <div className="menu-bar-menu-bar bg-gradient">
      <div className="menu-bar-menu-bar-top border-bottom-light bg-white-transparent">
        <img
          alt="image"
          src="/Logo/logo_white1-200w.png"
          className="menu-bar-image"
        />
        <span className="menu-bar-text">Traffls</span>
      </div>
      <div className="menu-bar-container">
        <div className="menu-bar-container01 border-bottom-light">
          <div className="menu-bar-container02">
            {btnDashboard && (
              <Link to="/dashboard" className="menu-bar-navlink">
                <div className="menu-bar-container03 btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Dashboard</span>
                </div>
              </Link>
            )}
            {!btnDashboard && (
              <Link to="/dashboard" className="menu-bar-navlink01">
                <div className="menu-bar-container04 btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon02">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Dashboard</span>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="menu-bar-container05 border-bottom-light">
          <span className="menu-bar-text03">JOB CHANGE</span>
          <Link to="/list" className="menu-bar-navlink02">
            <div className="menu-bar-container06">
              {btnList && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon04">
                    <path d="M298 640h86v86h-86v-86zM298 470h86v84h-86v-84zM298 298h86v86h-86v-86zM470 640h256v86h-256v-86zM470 470h256v84h-256v-84zM470 298h256v86h-256v-86zM858 128q14 0 26 11t12 27v692q0 14-12 26t-26 12h-692q-16 0-27-12t-11-26v-692q0-38 38-38h692zM810 214h-596v596h596v-596z"></path>
                  </svg>
                  <span>List</span>
                </div>
              )}
              {!btnList && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon06">
                    <path d="M298 640h86v86h-86v-86zM298 470h86v84h-86v-84zM298 298h86v86h-86v-86zM470 640h256v86h-256v-86zM470 470h256v84h-256v-84zM470 298h256v86h-256v-86zM858 128q14 0 26 11t12 27v692q0 14-12 26t-26 12h-692q-16 0-27-12t-11-26v-692q0-38 38-38h692zM810 214h-596v596h596v-596z"></path>
                  </svg>
                  <span>List</span>
                </div>
              )}
            </div>
          </Link>

          <Link to="/monitor" className="menu-bar-navlink04">
            <div className="menu-bar-container15">
              {btnMonitor && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon16">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Job Monitor</span>
                </div>
              )}
              {!btnMonitor && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon18">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Job Monitor</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/employees" className="menu-bar-navlink05">
            <div className="menu-bar-container18">
              {btnEmployees && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon20">
                    <path d="M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z"></path>
                  </svg>
                  <span>Employees</span>
                </div>
              )}
              {!btnEmployees && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon22">
                    <path d="M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z"></path>
                  </svg>
                  <span>Employees</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/triggers" className="menu-bar-navlink06">
            <div className="menu-bar-container21">
              {btnTriggers && (
                <div className="btn-menu-activ btn-menu">
                  <svg
                    viewBox="0 0 512.5851428571428 1024"
                    className="menu-bar-icon24"
                  >
                    <path d="M505.714 323.429c6.286 6.857 8 16.571 4 25.143l-308.571 661.143c-4.571 8.571-13.714 14.286-24 14.286-2.286 0-5.143-0.571-8-1.143-12.571-4-20-16-17.143-28l112.571-461.714-232 57.714c-2.286 0.571-4.571 0.571-6.857 0.571-6.286 0-13.143-2.286-17.714-6.286-6.857-5.714-9.143-14.286-7.429-22.286l114.857-471.429c2.857-10.857 13.143-18.286 25.143-18.286h187.429c14.286 0 25.714 10.857 25.714 24 0 3.429-1.143 6.857-2.857 10.286l-97.714 264.571 226.286-56c2.286-0.571 4.571-1.143 6.857-1.143 7.429 0 14.286 3.429 19.429 8.571z"></path>
                  </svg>
                  <span>Triggers</span>
                </div>
              )}
              {!btnTriggers && (
                <div className="btn-menu">
                  <svg
                    viewBox="0 0 512.5851428571428 1024"
                    className="menu-bar-icon26"
                  >
                    <path d="M505.714 323.429c6.286 6.857 8 16.571 4 25.143l-308.571 661.143c-4.571 8.571-13.714 14.286-24 14.286-2.286 0-5.143-0.571-8-1.143-12.571-4-20-16-17.143-28l112.571-461.714-232 57.714c-2.286 0.571-4.571 0.571-6.857 0.571-6.286 0-13.143-2.286-17.714-6.286-6.857-5.714-9.143-14.286-7.429-22.286l114.857-471.429c2.857-10.857 13.143-18.286 25.143-18.286h187.429c14.286 0 25.714 10.857 25.714 24 0 3.429-1.143 6.857-2.857 10.286l-97.714 264.571 226.286-56c2.286-0.571 4.571-1.143 6.857-1.143 7.429 0 14.286 3.429 19.429 8.571z"></path>
                  </svg>
                  <span>Triggers</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/integration" className="menu-bar-navlink07">
            <div className="menu-bar-container24 btn-menu">
              <svg
                viewBox="0 0 585.1428571428571 1024"
                className="menu-bar-icon28"
              >
                <path d="M164.571 841.143c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM164.571 182.857c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM530.286 256c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM585.143 256c0 40.571-22.286 76-54.857 94.857-1.714 206.286-148 252-245.143 282.857-90.857 28.571-120.571 42.286-120.571 97.714v14.857c32.571 18.857 54.857 54.286 54.857 94.857 0 60.571-49.143 109.714-109.714 109.714s-109.714-49.143-109.714-109.714c0-40.571 22.286-76 54.857-94.857v-468.571c-32.571-18.857-54.857-54.286-54.857-94.857 0-60.571 49.143-109.714 109.714-109.714s109.714 49.143 109.714 109.714c0 40.571-22.286 76-54.857 94.857v284c29.143-14.286 60-24 88-32.571 106.286-33.714 166.857-58.857 168-178.286-32.571-18.857-54.857-54.286-54.857-94.857 0-60.571 49.143-109.714 109.714-109.714s109.714 49.143 109.714 109.714z"></path>
              </svg>
              <span>Integration</span>
            </div>
          </Link>
        </div>
        <div className="menu-bar-container25 border-bottom-light">
          <span className="menu-bar-text17">EVENT SCAN</span>
          <Link to="/events" className="menu-bar-navlink08">
            <div className="menu-bar-container26">
              {btnEventsMonitor && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon30">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Event Monitor</span>
                </div>
              )}
              {!btnEventsMonitor && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon32">
                    <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
                  </svg>
                  <span>Event Monitor</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/events-contacts" className="menu-bar-navlink09">
            <div className="menu-bar-container29">
              {btnEventsContact && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon34">
                    <path d="M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z"></path>
                  </svg>
                  <span>Contacts</span>
                </div>
              )}
              {!btnEventsContact && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon36">
                    <path d="M896 896v-85.333c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464h-341.333c-58.88 0-112.299 23.936-150.869 62.464s-62.464 91.989-62.464 150.869v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-85.333c0-35.371 14.293-67.285 37.504-90.496s55.125-37.504 90.496-37.504h341.333c35.371 0 67.285 14.293 90.496 37.504s37.504 55.125 37.504 90.496v85.333c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667zM725.333 298.667c0-58.88-23.936-112.299-62.464-150.869s-91.989-62.464-150.869-62.464-112.299 23.936-150.869 62.464-62.464 91.989-62.464 150.869 23.936 112.299 62.464 150.869 91.989 62.464 150.869 62.464 112.299-23.936 150.869-62.464 62.464-91.989 62.464-150.869zM640 298.667c0 35.371-14.293 67.285-37.504 90.496s-55.125 37.504-90.496 37.504-67.285-14.293-90.496-37.504-37.504-55.125-37.504-90.496 14.293-67.285 37.504-90.496 55.125-37.504 90.496-37.504 67.285 14.293 90.496 37.504 37.504 55.125 37.504 90.496z"></path>
                  </svg>
                  <span>Contacts</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/integration" className="menu-bar-navlink10">
            <div className="menu-bar-container32 btn-menu">
              <svg
                viewBox="0 0 585.1428571428571 1024"
                className="menu-bar-icon38"
              >
                <path d="M164.571 841.143c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM164.571 182.857c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM530.286 256c0-30.286-24.571-54.857-54.857-54.857s-54.857 24.571-54.857 54.857 24.571 54.857 54.857 54.857 54.857-24.571 54.857-54.857zM585.143 256c0 40.571-22.286 76-54.857 94.857-1.714 206.286-148 252-245.143 282.857-90.857 28.571-120.571 42.286-120.571 97.714v14.857c32.571 18.857 54.857 54.286 54.857 94.857 0 60.571-49.143 109.714-109.714 109.714s-109.714-49.143-109.714-109.714c0-40.571 22.286-76 54.857-94.857v-468.571c-32.571-18.857-54.857-54.286-54.857-94.857 0-60.571 49.143-109.714 109.714-109.714s109.714 49.143 109.714 109.714c0 40.571-22.286 76-54.857 94.857v284c29.143-14.286 60-24 88-32.571 106.286-33.714 166.857-58.857 168-178.286-32.571-18.857-54.857-54.286-54.857-94.857 0-60.571 49.143-109.714 109.714-109.714s109.714 49.143 109.714 109.714z"></path>
              </svg>
              <span>Integration</span>
            </div>
          </Link>
        </div>
        <div className="menu-bar-container33 border-bottom-light">
          <span className="menu-bar-text23">ACCOUNT</span>
          <Link to="/channels" className="menu-bar-navlink03">
            <div className="menu-bar-container12">
              {btnChannels && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon12">
                    <path d="M448 128v-16c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576zM256 256v-128h128v128h-128zM832 432c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-576v128h576v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h192v-128h-192v-16zM640 576v-128h128v128h-128zM448 752c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576v-16zM256 896v-128h128v128h-128z"></path>
                  </svg>
                  <span>Channels</span>
                </div>
              )}
              {!btnChannels && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon14">
                    <path d="M448 128v-16c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576zM256 256v-128h128v128h-128zM832 432c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-576v128h576v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h192v-128h-192v-16zM640 576v-128h128v128h-128zM448 752c0-26.4-21.6-48-48-48h-160c-26.4 0-48 21.6-48 48v16h-192v128h192v16c0 26.4 21.6 48 48 48h160c26.4 0 48-21.6 48-48v-16h576v-128h-576v-16zM256 896v-128h128v128h-128z"></path>
                  </svg>
                  <span>Channels</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/session-cookie" className="menu-bar-navlink03">
            <div className="menu-bar-container09">
              {btnSessionCookie && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon08">
                    <path d="M928 128h-832c-52.8 0-96 43.2-96 96v576c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-576c0-52.8-43.2-96-96-96zM96 192h832c17.346 0 32 14.654 32 32v96h-896v-96c0-17.346 14.654-32 32-32zM928 832h-832c-17.346 0-32-14.654-32-32v-288h896v288c0 17.346-14.654 32-32 32zM128 640h64v128h-64zM256 640h64v128h-64zM384 640h64v128h-64z"></path>
                  </svg>
                  <span>Session Cookie</span>
                </div>
              )}
              {!btnSessionCookie && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon10">
                    <path d="M928 128h-832c-52.8 0-96 43.2-96 96v576c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-576c0-52.8-43.2-96-96-96zM96 192h832c17.346 0 32 14.654 32 32v96h-896v-96c0-17.346 14.654-32 32-32zM928 832h-832c-17.346 0-32-14.654-32-32v-288h896v288c0 17.346-14.654 32-32 32zM128 640h64v128h-64zM256 640h64v128h-64zM384 640h64v128h-64z"></path>
                  </svg>
                  <span>Session Cookie</span>
                </div>
              )}
            </div>
          </Link>
          <Link to="/settings" className="menu-bar-navlink11">
            <div className="menu-bar-container34">
              {btnSettings && (
                <div className="btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon40">
                    <path d="M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z"></path>
                  </svg>
                  <span>Settings</span>
                </div>
              )}
              {!btnSettings && (
                <div className="btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon42">
                    <path d="M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z"></path>
                  </svg>
                  <span>Settings</span>
                </div>
              )}
            </div>
          </Link>
          <div className="menu-bar-container37">
            {btnBilling && (
              <Link to="/billing">
                <div className="menu-bar-container38 btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon44">
                    <path d="M480 64c-265.096 0-480 214.904-480 480s214.904 480 480 480c265.098 0 480-214.902 480-480s-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.076 0 384 171.922 384 384s-171.924 384-384 384z"></path>
                    <path d="M670.824 644.34c-15.27-8.884-34.862-3.708-43.75 11.57-17.256 29.662-49.088 48.090-83.074 48.090h-128c-41.716 0-77.286-26.754-90.496-64h154.496c17.672 0 32-14.326 32-32s-14.328-32-32-32h-160v-64h160c17.672 0 32-14.328 32-32s-14.328-32-32-32h-154.496c13.21-37.246 48.78-64 90.496-64h128c33.986 0 65.818 18.426 83.074 48.090 8.888 15.276 28.478 20.456 43.752 11.568 15.276-8.888 20.456-28.476 11.568-43.752-28.672-49.288-81.702-79.906-138.394-79.906h-128c-77.268 0-141.914 55.056-156.78 128h-35.22c-17.672 0-32 14.328-32 32s14.328 32 32 32h32v64h-32c-17.672 0-32 14.326-32 32s14.328 32 32 32h35.22c14.866 72.944 79.512 128 156.78 128h128c56.692 0 109.72-30.62 138.394-79.91 8.888-15.276 3.708-34.864-11.57-43.75z"></path>
                  </svg>
                  <span>Billing</span>
                </div>
              </Link>
            )}
            {!btnBilling && (
              <Link to="/billing">
                <div className="menu-bar-container39 btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon47">
                    <path d="M480 64c-265.096 0-480 214.904-480 480s214.904 480 480 480c265.098 0 480-214.902 480-480s-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.076 0 384 171.922 384 384s-171.924 384-384 384z"></path>
                    <path d="M670.824 644.34c-15.27-8.884-34.862-3.708-43.75 11.57-17.256 29.662-49.088 48.090-83.074 48.090h-128c-41.716 0-77.286-26.754-90.496-64h154.496c17.672 0 32-14.326 32-32s-14.328-32-32-32h-160v-64h160c17.672 0 32-14.328 32-32s-14.328-32-32-32h-154.496c13.21-37.246 48.78-64 90.496-64h128c33.986 0 65.818 18.426 83.074 48.090 8.888 15.276 28.478 20.456 43.752 11.568 15.276-8.888 20.456-28.476 11.568-43.752-28.672-49.288-81.702-79.906-138.394-79.906h-128c-77.268 0-141.914 55.056-156.78 128h-35.22c-17.672 0-32 14.328-32 32s14.328 32 32 32h32v64h-32c-17.672 0-32 14.326-32 32s14.328 32 32 32h35.22c14.866 72.944 79.512 128 156.78 128h128c56.692 0 109.72-30.62 138.394-79.91 8.888-15.276 3.708-34.864-11.57-43.75z"></path>
                  </svg>
                  <span>Billing</span>
                </div>
              </Link>
            )}
          </div>
          <div className="menu-bar-container40">
            {btnSavingsPlans && (
              <Link to="/savings-plans">
                <div className="menu-bar-container41 btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon50">
                    <path d="M640 384v-170h-426v170h426zM512 810q52 0 90-38t38-90-38-90-90-38-90 38-38 90 38 90 90 38zM726 128l170 170v512q0 34-26 60t-60 26h-596q-36 0-61-25t-25-61v-596q0-36 25-61t61-25h512z"></path>
                  </svg>
                  <span>Savings Plans</span>
                </div>
              </Link>
            )}
            {!btnSavingsPlans && (
              <Link to="/savings-plans">
                <div className="menu-bar-container42 btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon52">
                    <path d="M640 384v-170h-426v170h426zM512 810q52 0 90-38t38-90-38-90-90-38-90 38-38 90 38 90 90 38zM726 128l170 170v512q0 34-26 60t-60 26h-596q-36 0-61-25t-25-61v-596q0-36 25-61t61-25h512z"></path>
                  </svg>
                  <span>Savings Plans</span>
                </div>
              </Link>
            )}
          </div>
          <div className="menu-bar-container43">
            {btnLog && (
              <Link to="/log">
                <div className="menu-bar-container44 btn-menu-activ btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon54">
                    <path d="M128 554v-84h768v84h-768zM128 256h768v86h-768v-86zM128 768v-86h512v86h-512z"></path>
                  </svg>
                  <span>Log</span>
                </div>
              </Link>
            )}
            {!btnLog && (
              <Link to="/log">
                <div className="menu-bar-container45 btn-menu">
                  <svg viewBox="0 0 1024 1024" className="menu-bar-icon56">
                    <path d="M128 554v-84h768v84h-768zM128 256h768v86h-768v-86zM128 768v-86h512v86h-512z"></path>
                  </svg>
                  <span>Log</span>
                </div>
              </Link>
            )}
          </div>
          <Link to="/team" className="menu-bar-navlink18">
            <div className="menu-bar-container46 btn-menu">
              <svg viewBox="0 0 1152 1024" className="menu-bar-icon58">
                <path d="M768 770.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
                <path d="M327.196 795.328c55.31-36.15 124.080-63.636 199.788-80.414-15.054-17.784-28.708-37.622-40.492-59.020-30.414-55.234-46.492-116.058-46.492-175.894 0-86.042 0-167.31 30.6-233.762 29.706-64.504 83.128-104.496 159.222-119.488-16.914-76.48-61.94-126.75-181.822-126.75-192 0-192 128.942-192 288 0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h279.006c14.518-12.91 30.596-25.172 48.19-36.672z"></path>
              </svg>
              <span>Team</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MenuBar
