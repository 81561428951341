import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import EventsOverviewItem from '../components/events-overview-item'
import POPUP from '../components/popup'
import './events.css'
import { useData } from '../contexts/DataContext'

const Events = (props) => {
  const { userData } = useData()
  let monitors = Object.keys(userData?.eventMonitors || {})
  monitors = monitors.filter(id => userData.eventMonitors[id].delete === false)

  const isEmpty = monitors.length === 0

  const totalEvents = monitors.reduce((total, id) => {
    return total + (Object.keys(userData.eventMonitors[id]?.events || {}).length || 0)
  }, 0)

  const totalContacts = monitors.reduce((total, id) => {
    return total + (userData.eventMonitors[id].contacts?.length || 0)
  }, 0)

  return (
    <div className="events-container">
      <Helmet>
        <title>Events - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Events - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="events-main">
        <MenuBar></MenuBar>
        <div className="events-content">
          <TopBar lable="Events"></TopBar>
          <div className="events-event-overview-list">
            <div className="events-container1">
              <Link to="/events-create" className="events-navlink">
                <div className="events-container2">
                  <svg viewBox="0 0 1024 1024" className="events-icon">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                  <span className="events-text">Create Event Monitor</span>
                </div>
              </Link>
              <div className="events-container3">
                <div className="events-container4">
                  <span className="events-text1">Total Monitors: </span>
                  <span>{monitors.length}</span>
                </div>
                <div className="events-container4">
                  <span className="events-text1">Total Events: </span>
                  <span>{totalEvents}</span>
                </div>
                <div className="events-container5">
                  <span className="events-text3">Total Contacts: </span>
                  <span>{totalContacts}</span>
                </div>
              </div>
            </div>
            <ul className={`list ${isEmpty ? 'empty' : ''}`}>
              {monitors.map((id, index) => (
                <li key={index} className="list-item">
                  <EventsOverviewItem id={id} rootClassName="events-overview-item-root-class-name"></EventsOverviewItem>
                </li>
              ))}
            </ul>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Events
