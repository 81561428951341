import React from 'react'

import LogOverviewListItem1 from './log-overview-list-item1'
import './log-overview-list.css'
import { useData } from '../contexts/DataContext';

const LogOverviewList = (props) => {
  const { userData } = useData();

  const log = Object.keys(userData?.log || {}) || []
  return (
    <div className="log-overview-list-log-overview-list">
      <div className="log-overview-list-container bg-gradient"></div>
      <ul className="log-overview-list-ul list">
        {log?.map((id, index) => (
          <li key={index} className="list-item">
            <LogOverviewListItem1 id={id}></LogOverviewListItem1>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LogOverviewList
