import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import POPUP from '../components/popup'
import './savings-plans.css'

const SavingsPlans = (props) => {
  return (
    <div className="savings-plans-container">
      <Helmet>
        <title>Savings-Plans - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:title"
          content="Savings-Plans - Traffls - Dashboard"
        />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="savings-plans-main">
        <MenuBar></MenuBar>
        <div className="savings-plans-content">
          <TopBar lable="Savings Plans"></TopBar>
          <POPUP></POPUP>
          <div className="savings-plans-content1">
            <Player
              src="https://lottie.host/2473ec48-43e2-47a9-83d9-502990386d2f/WIHLBMc1Di.json"
              loop="true"
              speed="1"
              autoplay="true"
              background="transparent"
              className="savings-plans-lottie-node"
            ></Player>
            <span className="savings-plans-text">UNDER CONSTRUCTION</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SavingsPlans
