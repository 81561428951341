import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import ChannelsOverviewContactsItem from './channels-overview-contacts-item'
import './channels-overview-list.css'
import { useData } from '../contexts/DataContext'

const ChannelsOverviewList = (props) => {
  const { userData } = useData()

  const contact = Object.keys(userData?.notificationContacts || {}) || []

  return (
    <div className="channels-overview-list-channels-overview-list">
      <div className="channels-overview-list-container">
        <span className="channels-overview-list-text">{props.text}</span>
        <Link
          to="/channel-add-contact"
          className="channels-overview-list-navlink"
        >
          <div className="btn-main-lila">
            <svg
              viewBox="0 0 1024 1024"
              className="channels-overview-list-icon"
            >
              <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
            </svg>
            <span>{props.text1}</span>
          </div>
        </Link>
      </div>
      <div className="channels-overview-list-container2">
        <div className="channels-overview-list-container3">
          <div className="channels-overview-list-container4">
            <span className="channels-overview-list-text2">{props.text2}</span>
          </div>
          <div className="channels-overview-list-container5">
            <span className="channels-overview-list-text3">{props.text3}</span>
          </div>
          <div className="channels-overview-list-container6">
            <span className="channels-overview-list-text4">{props.text4}</span>
          </div>
          <div className="channels-overview-list-container7"></div>
        </div>
        <ul className="channels-overview-list-ul">
          {contact.map((id, index) => (
            <li className="list-item" key={index}>
              <ChannelsOverviewContactsItem id={id} ></ChannelsOverviewContactsItem>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

ChannelsOverviewList.defaultProps = {
  text3: 'Email',
  text4: 'Last Notification',
  text1: 'Add Contact',
  text2: 'Contact / Company',
  text: 'Notification Contacts',
}

ChannelsOverviewList.propTypes = {
  text3: PropTypes.string,
  text4: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text: PropTypes.string,
}

export default ChannelsOverviewList
