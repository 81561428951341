import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './triggers-overview-list-item.css';
import { useData } from '../contexts/DataContext';

const TriggersOverviewListItem = (props) => {
  const { userData } = useData();
  
  const contact = userData.contacts[props.id] || {};
  const [favorite, setFavorite] = useState(true);
  const [stats, setStats] = useState(contact.status || 0);

  useEffect(() => {
    setStats(contact.status);
  }, [contact.status]);

  return (
    <div className="triggers-overview-list-item-triggers-overview-list-item">
      <div className="triggers-overview-list-item-container">
        <div className="triggers-overview-list-item-container01">
          <img
            alt="image"
            src={contact.imageSrc.includes('http') || TriggersOverviewListItem.defaultProps.imageSrc}
            className="triggers-overview-list-item-image"
          />
        </div>
        <div className="triggers-overview-list-item-container02 ">
          <span>{contact.contactName || 'Contact Full Name'}</span>
          <span className="triggers-overview-list-item-text01 item-s-txt">
            {contact.companyName || 'Company Name'}
          </span>
        </div>
      </div>
      <div className="triggers-overview-list-item-container03">
        <div className="triggers-overview-list-item-container04">
          <span>{contact.description || 'Description'}</span>
          <div className="triggers-overview-list-item-container05 item-s-txt">
            <span className="triggers-overview-list-item-text03">
              {contact.activity || 'Activity'}
            </span>
            <span className="triggers-overview-list-item-text04"> / </span>
            <span className="triggers-overview-list-item-text05">
              {contact.position || 'Position'}
            </span>
          </div>
        </div>
      </div>
      <div className="triggers-overview-list-item-container03">
        <div className="triggers-overview-list-item-container04">
          <span>{contact?.jobChange?.from?.companyName || '-'}</span>
          <div className="triggers-overview-list-item-container05 item-s-txt">
            <span className="triggers-overview-list-item-text03">
              {contact?.jobChange?.to?.companyName || '-'}
            </span>
          </div>
        </div>
      </div>
      <div className="triggers-overview-list-item-container06">
        <div className="triggers-overview-list-item-container07">
          <span className="triggers-overview-list-item-text06">
            {new Date(parseInt(contact.lastScan)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) || '--.--.----'}
          </span>
        </div>
      </div>
      <div className="triggers-overview-list-item-container08">
        <div className="triggers-overview-list-item-container09">
          <svg
            viewBox="0 0 1024 1024"
            className="triggers-overview-list-item-icon"
          >
            <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
          </svg>
        </div>
        
      </div>
    </div>
  );
};

TriggersOverviewListItem.defaultProps = {
  id: 'trigger_1',
  companyName: 'Company Name',
  position: 'Position',
  imageSrc: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&ixlib=rb-4.0.3&w=200',
  description: 'Description',
  contactFullName: 'Contact Full Name',
  activity: 'Activity',
  date: '01.01.2024',
};

TriggersOverviewListItem.propTypes = {
  id: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  position: PropTypes.string,
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  contactFullName: PropTypes.string,
  activity: PropTypes.string,
  date: PropTypes.string,
};

export default TriggersOverviewListItem;
