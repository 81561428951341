import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './events-details-event-item.css'
import { useData } from '../contexts/DataContext';

const EventsDetailsEventItem = (props) => {
  const { userData, uploadData } = useData();
  const id = props.id
  const monitorId = props.monitorId

  const event = userData.eventMonitors[monitorId]?.events[id]
  const eventScan = () => {
    uploadData(`eventMonitors/${monitorId}/events/${id}/scan`,!event.scan)
  }


  return (
    <div
      className={`events-details-event-item-monitor-details-contacts-item ${props.rootClassName} `}
    >
      <div className="events-details-event-item-container">
        <div className="events-details-event-item-container02">
          <span>{event.name}</span>
          <span className="events-details-event-item-text1">
            {event.company || '-'}
          </span>
        </div>
      </div>
      <div className="events-details-event-item-container03">
        <div className="events-details-event-item-container04">
          <span>{event.participants || '-'}</span>
        </div>
      </div>
      <div className="events-details-event-item-container05">
        <div className="events-details-event-item-container06">
          <span>{new Date(parseInt(event.timestamp)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) || '--.--.----'}</span>
        </div>
      </div>
      <div className="events-details-event-item-container07">
        <div className="events-details-event-item-container08">
          {!event.scan && (
            <div onClick={eventScan} className="events-details-event-item-container09">
              <span className="events-details-event-item-text4">
                Add to scan
              </span>
            </div>
          )}
          {event.scan && (
            <div onClick={eventScan} className="events-details-event-item-container10">
              <span>Remove from scan</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

EventsDetailsEventItem.defaultProps = {
  fullName: 'Contact Full Name',
  companyName: 'Company Name',
  description: 'Description\n',
  date: '--.--.----\n',
  rootClassName: '',
}

EventsDetailsEventItem.propTypes = {
  fullName: PropTypes.string,
  companyName: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default EventsDetailsEventItem
