// src/components/Notification.js
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Notification.css';  // Optional: CSS-Datei für benutzerdefinierte Stile

const Notification = () => {
    return <ToastContainer />;
};

export const notify = (message, status) => {
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 7000, // Benachrichtigung schließt sich nach 7 Sekunden automatisch
    };

    switch (status) {
        case 1:
            toast.success(<div className="toast-message"><i className="material-icons"></i>{message}</div>, options);
            break;
        case 2:
            toast.warn(<div className="toast-message"><i className="material-icons"></i>{message}</div>, options);
            break;
        case 3:
            toast.error(<div className="toast-message"><i className="material-icons"></i>{message}</div>, options);
            break;
        case 9:
            toast(<div className="toast-message"><i className="material-icons"></i>{message}</div>, options);
            break;
        default:
            toast.info(<div className="toast-message">{message}</div>, options);
    }
};

let toastId = null;

export const notifyPromiseStart = (message) => {
    toastId = toast.loading(
        <div className="toast-message">
            {message}
        </div>,
        {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: false, // Läuft nicht automatisch ab
        }
    );
};

export const notifyPromiseStop = (message) => {
    if (toastId) {
        toast.update(toastId, {
            render: <div className="toast-message">{message}</div>,
            type: toast.TYPE.SUCCESS,
            autoClose: 7000,
            isLoading: false
        });
        toastId = null;
    }
};

export const notifyPromiseFail = (message) => {
    if (toastId) {
        toast.update(toastId, {
            render: <div className="toast-message">{message}</div>,
            type: toast.TYPE.ERROR,
            autoClose: 7000,
            isLoading: false
        });
        toastId = null;
    }
};

export default Notification;
