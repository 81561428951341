// src/components/Confirmation.js
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Confirmation.css';  // Optional: CSS-Datei für benutzerdefinierte Stile

const Confirmation = () => {
    return <ToastContainer />;
};

export const confirm = (message, onConfirm, onCancel) => {
    const options = {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: true, // Läuft nicht automatisch ab
        closeOnClick: false,
        draggable: false,
        closeButton: false,
        
    };

    const ConfirmationContent = () => (
        <div className="toast-message confirmationBox-Main">
            <div>{message}</div>
            <div className='btn-container'>
                <button className='btn-cancel' onClick={() => { onCancel(); toast.dismiss(); }}>Cancel</button>
                <button className='btn-delete' onClick={() => { onConfirm(); toast.dismiss(); }}>Delete</button>
            </div>
        </div>
    );

    toast.error(<ConfirmationContent />, options);
};

export default Confirmation;
