import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListOverviewItem from './list-overview-item';
import './list-overview-list.css';
import { useData } from '../contexts/DataContext';

const ListOverviewList = (props) => {
  const { userData } = useData();

  const totalContacts = Object.keys(userData?.contacts || {}) || [];
  let lists = Object.entries(userData?.lists || []);

  lists = lists.filter(([id, list]) => list.delete === false);

  // Sort the lists by the 'created' date in descending order
  if (lists.length > 0) {
    lists = lists.sort((a, b) => {
      const createdA = parseInt(userData?.lists[a[0]]?.created);
      const createdB = parseInt(userData?.lists[b[0]]?.created);
      return createdB - createdA;
    });
  }

  const lastScanTimestamp = lists.reduce((latest, list) => {
    const listTimestamp = parseInt(list[1].lastScan);
    return listTimestamp > latest ? listTimestamp : latest;
  }, 0);

  const lastScanDate = lastScanTimestamp
    ? new Date(lastScanTimestamp).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' })
    : 'No scans available';

  const isEmpty = lists.length === 0;

  return (
    <div className="list-overview-list-list-overview-list">
      <div className="list-overview-list-container">
        <Link to="/list-upload-list" className="list-overview-list-navlink">
          <div className="list-overview-list-container1">
            <svg viewBox="0 0 1024 1024" className="list-overview-list-icon">
              <path d="M214 768h596v86h-596v-86zM384 682v-256h-170l298-298 298 298h-170v256h-256z"></path>
            </svg>
            <span className="list-overview-list-text">Upload List</span>
          </div>
        </Link>
        <div className="list-overview-list-container2">
          <div className="list-overview-list-container3">
            <span className="list-overview-list-text1 space">Total Contacts: </span>
            <span>{totalContacts.length}</span>
          </div>
          <div className="list-overview-list-container4">
            <span className="list-overview-list-text3 space">Last Scan: </span>
            <span>{lastScanDate}</span>
          </div>
        </div>
      </div>
      <ul className={`list ${isEmpty ? 'empty' : ''}`}>
        {lists.map(([id, list], index) => (
          <li key={index} className="list-item">
            <ListOverviewItem id={id} />
          </li>
        ))}
      </ul>
    </div>
  );
};

ListOverviewList.defaultProps = {
  lastScan: '01.01.2024',
  totalContacts: '99',
};

ListOverviewList.propTypes = {
  lastScan: PropTypes.string,
  totalContacts: PropTypes.string,
};

export default ListOverviewList;
