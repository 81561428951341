import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import PropTypes from 'prop-types';

import CheckBox from './check-box';
import './login-component.css';
import { notify, notifyPromiseStart, notifyPromiseStop, notifyPromiseFail } from '../components/Notification';

const LoginComponent = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Login');

  const { login, register } = useData();
  const history = useHistory();

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev === 'Login...') return 'Login';
          return prev + '.';
        });
      }, 500);
    } else {
      setLoadingText('Login');
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  const handleLogin = async () => {
    if (!email || !password) {
      if (!email) notify('Please enter your email address.', 2);
      if (!password) notify('Please enter your password.', 2);
      return;
    }

    try {
      notifyPromiseStart('Logging in...');
      setIsLoading(true);
      await login(email, password);
      notifyPromiseStop('Logged in successfully.', 1);
      setError(false);
      setIsLoading(false);
      history.push('/dashboard');
    } catch (err) {
      notifyPromiseFail('Login failed. Please check your credentials and try again.', 1);
      // notify('Login failed. Please check your credentials and try again.', 3);
      setIsLoading(false);
    }
  };

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      notify('Passwords do not match. Please ensure both passwords are identical and try again.', 3);
      return;
    }
    try {
      await register(name, lastname, email, password);
      setError(false);
      setPage(4); // Go to confirmation page
    } catch (err) {
      setError(true);
      setErrorMessage(err.message);
    }
  };

  return (
    <div className={`login-component-container ${props.rootClassName} `}>
      {page === 0 && (
        <div className="login-component-container01">
          <div className="login-component-container02">
            <span className="login-component-text">Login</span>
            <span className="login-component-text01">Please enter your details.</span>
          </div>
          <div className="login-component-container03">
            <div className="login-component-d1">
              <div className="login-component-email">
                <span className="login-component-text02">Email</span>
                <div className="login-component-container04">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="login-component-textinput"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-password">
                <div className="login-component-container05">
                  <span className="login-component-text03">Password</span>
                  <span onClick={() => setPage(2)} className="login-component-text04">
                    Forgot password
                  </span>
                </div>
                <div className="login-component-container06">
                  <input
                    type="password"
                    placeholder="Enter your password"
                    className="login-component-textinput1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-container07">
                <CheckBox rootClassName="check-box-root-class-name162" className="" />
                <span className="login-component-text05">Remember for 30 days</span>
              </div>
            </div>
            <div className="login-component-d2">
              <div onClick={handleLogin} className="login-component-container08 btn-purple">
                <span className="">{isLoading ? loadingText : 'Login'}</span>
              </div>
              <div className="login-component-container09">
                <span className="">Google</span>
              </div>
              <div className="login-component-container10">
                <span className="login-component-text08">Don&apos;t have an account?</span>
                <span onClick={() => setPage(1)} className="login-component-text09">
                  Sign up
                </span>
              </div>
            </div>
          </div>
          {error && (
            <div className="login-component-container11">
              <span className="login-component-text10">{errorMessage}</span>
            </div>
          )}
        </div>
      )}
      {page === 1 && (
        <div className="login-component-container12">
          <div className="login-component-container13">
            <span className="login-component-text11">Registration</span>
            <span className="login-component-text12">Please enter your details...</span>
          </div>
          <div className="login-component-container14">
            <div className="login-component-d11">
              <div className="login-component-email1">
                <span className="login-component-text13">Name</span>
                <div className="login-component-container15">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    className="login-component-textinput2"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-password1">
                <span className="login-component-text14">Lastname</span>
                <div className="login-component-container16">
                  <input
                    type="text"
                    placeholder="Enter your lastname"
                    className="login-component-textinput3"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-email2">
                <span className="login-component-text15">Email</span>
                <div className="login-component-container17">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    className="login-component-textinput4"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-email3">
                <span className="login-component-text16">Password</span>
                <div className="login-component-container18">
                  <input
                    type="password"
                    placeholder="Enter your password"
                    className="login-component-textinput5"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-component-email4">
                <span className="login-component-text17">Confirm Password</span>
                <div className="login-component-container19">
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    className="login-component-textinput6"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="login-component-d21">
              <div onClick={handleRegister} className="btn-purple">
                <span className="">Register</span>
              </div>
              <div onClick={() => setPage(0)} className="login-component-container21">
                <span className="">Back</span>
              </div>
            </div>
          </div>
          {error && (
            <div className="login-component-container22">
              <span className="">{errorMessage}</span>
            </div>
          )}
        </div>
      )}
      {page === 2 && (
        <div className="login-component-container23">
          <div className="login-component-container24">
            <span className="login-component-text21">Passwort Recovery</span>
            <span className="login-component-text22">Welcome back! Please enter your details.</span>
          </div>
          <div className="login-component-container25">
            <div className="login-component-d12">
              <div className="login-component-email5">
                <span className="login-component-text23">Email</span>
                <div className="login-component-container26">
                  <input
                    type="text"
                    placeholder="Enter your email to recover your password"
                    className="login-component-textinput7"
                  />
                </div>
              </div>
            </div>
            <div className="login-component-d22">
              <div onClick={() => setPage(3)} className="btn-purple">
                <span className="">Send</span>
              </div>
              <div onClick={() => setPage(0)} className="login-component-container28">
                <span className="">Back</span>
              </div>
              <div className="login-component-container29">
                <span className="login-component-text26">Don&apos;t have an account?</span>
                <span onClick={() => setPage(1)} className="login-component-text27">
                  Sign up
                </span>
              </div>
            </div>
          </div>
          {error && (
            <div className="login-component-container30">
              <span className="">{props.errorMessage}</span>
            </div>
          )}
        </div>
      )}
      {page === 3 && (
        <div className="login-component-container31">
          <div className="login-component-container32">
            <span className="login-component-text29">Recovery email sent</span>
            <span className="login-component-text30">Please check your email inbox</span>
          </div>
          <div className="login-component-container33">
            <div onClick={() => setPage(0)} className="btn-purple">
              <span className="">Login</span>
            </div>
          </div>
          {error && (
            <div className="login-component-container35">
              <span className="">{props.errorMessage}</span>
            </div>
          )}
        </div>
      )}
      {page === 4 && (
        <div className="login-component-container36">
          <div className="login-component-container37">
            <span className="login-component-text33">E-mail confirmation</span>
            <span className="login-component-text34">
              <span className="">
                We have sent an email to
                <span dangerouslySetInnerHTML={{ __html: ' ' }} />
              </span>
              <span className="login-component-text36">{email}</span>
              <span className="">
                {' '}
                to confirm the validity of your email address. After receiving the email, follow the link provided to complete your registration.
              </span>
            </span>
          </div>
          <div className="login-component-container38">
            <div className="login-component-d24">
              <Link to="/login" className="login-component-navlink1">
                <div className="login-component-container39 btn-purple">
                  <span className="">Login</span>
                </div>
              </Link>
            </div>
          </div>
          {error && (
            <div className="login-component-container40">
              <span className="">{errorMessage}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

LoginComponent.defaultProps = {
  rootClassName: '',
  errorMessage: 'Error message here...',
};

LoginComponent.propTypes = {
  rootClassName: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default LoginComponent;
