const stripe = require('stripe')('sk_test_51Nx2zDDwARJQUc8dZLQ9YWRTgOpsCFhIHc8la6JareZnY0gXYapIAWX2cBvOYy1sc5Q2NCM5nRlrsIzLya6iA6Ix00FV8w7sHF');

async function createCheckoutSession(amount, obj, currency = 'eur') {
    try {
        const session = await stripe.checkout.sessions.create({
            line_items: [
                {
                    price_data: {
                        currency: currency,
                        product_data: {
                            name: 'Traffls - Wallet Charge',
                        },
                        unit_amount: amount,
                    },
                    quantity: 1,
                },
            ],
            mode: 'payment',
            metadata: {
                clientId: obj.uid,  // Add clientId to the metadata
                value: amount,
                transactionId: obj.transactionId
            },
            success_url: 'https://dashboard.traffls.com/charge-wallet', // Replace with your success URL
            cancel_url: 'https://dashboard.traffls.com/charge-wallet',   // Replace with your cancel URL
        });
        return session;
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
}

async function paylink(amount, obj) {
    try {
        const link = await createCheckoutSession(amount, obj);
        return link;
    } catch (error) {
        console.error('Error generating checkout session link:', error);
        throw error;
    }
}

module.exports = {
    paylink,
};
