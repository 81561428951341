import React from 'react'

import PropTypes from 'prop-types'

import './events-details-participant-item.css'
import { useData } from '../contexts/DataContext';

const EventsDetailsParticipantItem = (props) => {
  const { userData } = useData();

  const id = props.id
  const monitorID = props.monitorID
  const event = Object.values(userData.eventMonitors[monitorID].events).find(obj => obj.contacts?.includes(id))
  const openLinkedinProfil = () => {
    window.open(userData.contacts[id]?.profileUrl, '_blank')
  }
  return (
    <div
      className={`events-details-participant-item-monitor-details-trigger-item ${props.rootClassName} `}
    >
      <div className="events-details-participant-item-container">
        <div className="events-details-participant-item-container01">
          <img
            alt="image"
            src={userData.contacts[id]?.imageSrc || "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE0fHx1c2VyfGVufDB8fHx8MTcxOTAwNTAzNnww&amp;ixlib=rb-4.0.3&amp;w=200"}
            className="events-details-participant-item-image"
          />
        </div>
        <div className="events-details-participant-item-container02">
          <span>{userData.contacts[id]?.contactName || '-'}</span>
          <span className="events-details-participant-item-text1">
            {userData.contacts[id]?.companyName}
          </span>
        </div>
      </div>
      <div className="events-details-participant-item-container03">
        <div className="events-details-participant-item-container04">
          <span>{event.name}</span>
        </div>
      </div>
      <div className="events-details-participant-item-container05">
        <div className="events-details-participant-item-container06">
          <div className="events-details-participant-item-container07">
            <span className="events-details-participant-item-text3">
              {userData.contacts[id]?.activity}
            </span>
            <span className="events-details-participant-item-text4"> / </span>
            <span className="events-details-participant-item-text5">
              {userData.contacts[id]?.position}
            </span>
          </div>
        </div>
      </div>
      <div onClick={openLinkedinProfil} className="events-details-participant-item-container08">
        {/* <div className="events-details-participant-item-container09">
          <span>{props.date}</span>
        </div> */}

        <div  className='linkedinBtn'>
          <svg
            viewBox="0 0 877.7142857142857 1024"
            className="linkedin-icon"
          >
            <path d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286c0.571 54.286-40.571 97.714-106.286 97.714v0h-1.143c-63.429 0-104-43.429-104-97.714 0-55.429 42.286-97.714 106.286-97.714 64.571 0 104.571 42.286 105.143 97.714zM877.714 598.857v324.571h-188v-302.857c0-76-27.429-128-95.429-128-52 0-82.857 34.857-96.571 68.571-4.571 12.571-6.286 29.143-6.286 46.286v316h-188c2.286-513.143 0-566.286 0-566.286h188v82.286h-1.143c24.571-38.857 69.143-95.429 170.857-95.429 124 0 216.571 81.143 216.571 254.857z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

EventsDetailsParticipantItem.defaultProps = {
  position: 'Position',
  date: '--.--.----',
  event: 'EventName',
  companyName: 'Company Name',
  activity: 'Activity',
  fullName: 'Contact Full Name',
  rootClassName: '',
}

EventsDetailsParticipantItem.propTypes = {
  position: PropTypes.string,
  date: PropTypes.string,
  event: PropTypes.string,
  companyName: PropTypes.string,
  activity: PropTypes.string,
  fullName: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default EventsDetailsParticipantItem
