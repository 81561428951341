import React from 'react'

import { Helmet } from 'react-helmet'

import MenuBar from '../components/menu-bar'
import TopBar from '../components/top-bar'
import ChannelsGroup from '../components/channels-group'
import ChannelsOverviewList from '../components/channels-overview-list'
import POPUP from '../components/popup'
import './channels.css'

const Channels = (props) => {
  return (
    <div className="channels-container">
      <Helmet>
        <title>Channels - Traffls - Dashboard</title>
        <meta
          name="description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta property="og:title" content="Channels - Traffls - Dashboard" />
        <meta
          property="og:description"
          content="Discover hidden sales leads with AI-powered Traffls. Unlock new opportunities and boost your sales with high-conversion leads. Watch your sales soar!"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d3408dc9-394e-4cac-87b2-a87d40ea4925/0a058cc7-a817-400e-be03-2dfc3027faaa?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="channels-main">
        <MenuBar channels={true} ></MenuBar>
        <div className="channels-content">
          <TopBar lable="Channels"></TopBar>
          <div className="channels-content1">
            <ChannelsGroup></ChannelsGroup>
            <ChannelsOverviewList></ChannelsOverviewList>
          </div>
          <POPUP></POPUP>
        </div>
      </div>
    </div>
  )
}

export default Channels
