import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './dashboard-overview-contacts-item.css'

const DashboardOverviewContactsItem = (props) => {
  const [preference, setPreference] = useState(true)
  const name = props.item?.name|| 'Preference name';
  const count = props.item?.count || '0';
  return (
    <div className="dashboard-overview-contacts-item-dashboard-overview-contacts-item-white border-bottom">
      <span className="dashboard-overview-contacts-item-text">
        {name}
      </span>
      <div className="dashboard-overview-contacts-item-container">
        <div className="dashboard-overview-contacts-item-container1">
          {!preference && (
            <span className="dashboard-overview-contacts-item-text2">
              {count}
            </span>
          )}
          {preference && (
            <span className="dashboard-overview-contacts-item-text2">
              {count}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

DashboardOverviewContactsItem.defaultProps = {
  count: '12',
  preferenceName: 'Preference name',
}

DashboardOverviewContactsItem.propTypes = {
  count: PropTypes.string,
  preferenceName: PropTypes.string,
}

export default DashboardOverviewContactsItem
