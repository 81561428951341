// src/views/HomeRedirect.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useData } from '../contexts/DataContext';
import Loading from '../components/Loading';

const HomeRedirect = () => {
  const { user, isInitializing, isDataLoading } = useData();
  const history = useHistory();

  useEffect(() => {
    if (!isInitializing && !isDataLoading) {
      if (user) {
        history.push('/dashboard');
      } else {
        history.push('/login');
      }
    }
  }, [isInitializing, isDataLoading, user, history]);

  if (isInitializing || isDataLoading) {
    return <Loading />; // Ladeanzeige während der Initialisierung und Datenladung
  }

  return null;
};

export default HomeRedirect;
