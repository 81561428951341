import React from 'react'

import PropTypes from 'prop-types'

import './popup.css'

const POPUP = (props) => {
  return (
    <div className={`popup-popup ${props.rootClassName} `}>
      <div className="popup-container">
        <div className="popup-container1 border-bottom">
          <span>{props.text}</span>
        </div>
      </div>
    </div>
  )
}

POPUP.defaultProps = {
  text: 'Notification',
  rootClassName: '',
}

POPUP.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default POPUP
