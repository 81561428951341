import React from 'react'

import PropTypes from 'prop-types'

import './billing-overview-costs.css'
import { useData } from '../contexts/DataContext';

const BillingOverviewCosts = (props) => {
  const { userData } = useData();
  
  const triggerCount = userData?.displayData?.dashboard?.costsOverview?.triggers?.count || 0;
  const triggerCost = userData?.displayData?.dashboard?.costsOverview?.triggers?.cost || 0;
  const uploadCount = userData?.displayData?.dashboard?.costsOverview?.uploads?.count || 0;
  const uploadCost = userData?.displayData?.dashboard?.costsOverview?.uploads?.cost || 0;
  const preferenceCount =userData?.displayData?.dashboard?.costsOverview?.preferences?.count || 0;
  const preferenceCost = userData?.displayData?.dashboard?.costsOverview?.preferences?.cost || 0;

  return (
    <div
      className={`billing-overview-costs-billing-overview-costs ${props.rootClassName} `}
    >
      <div className="billing-overview-costs-container border-bottom">
        <span className="">Recent cost</span>
      </div>
      <div className="billing-overview-costs-container01">
        <div className="billing-overview-costs-container02 border-bottom">
          <div className="billing-overview-costs-container03">
            <span className="billing-overview-costs-text01">Contacts</span>
            <span className="billing-overview-costs-text02">
              {uploadCount}
            </span>
          </div>
          <div className="billing-overview-costs-container04">
            <span className="billing-overview-costs-text03">Costs incurred</span>
            <div className="billing-overview-costs-container05">
              <span className="billing-overview-costs-text04">
                {uploadCost}
              </span>
              <span className="billing-overview-costs-text05"> €</span>
            </div>
          </div>
        </div>
        <div className="billing-overview-costs-container06 border-bottom">
          <div className="billing-overview-costs-container07">
            <span className="billing-overview-costs-text06">Preferences</span>
            <span className="billing-overview-costs-text07">
              {preferenceCount}
            </span>
          </div>
          <div className="billing-overview-costs-container08">
            <span className="billing-overview-costs-text08">Costs incurred</span>
            <div className="billing-overview-costs-container09">
              <span className="billing-overview-costs-text09">
                {preferenceCost}
              </span>
              <span className="billing-overview-costs-text10"> €</span>
            </div>
          </div>
        </div>
        <div className="billing-overview-costs-container10">
          <div className="billing-overview-costs-container11">
            <span className="billing-overview-costs-text11">Triggers</span>
            <span className="billing-overview-costs-text12">
              {triggerCount}
            </span>
          </div>
          <div className="billing-overview-costs-container12">
            <span className="billing-overview-costs-text13">Costs incurred</span>
            <div className="billing-overview-costs-container13">
              <span className="billing-overview-costs-text14">
                {triggerCost}
              </span>
              <span className="billing-overview-costs-text15"> €</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BillingOverviewCosts.defaultProps = {
  preferencesCount: '163',
  preferencesCost: '1.74',
  triggersCount: '163',
  uploadsCount: '163',
  uploadsCost: '1.74',
  rootClassName: '',
  triggersCost: '1.74',
}

BillingOverviewCosts.propTypes = {
  preferencesCount: PropTypes.string,
  preferencesCost: PropTypes.string,
  triggersCount: PropTypes.string,
  uploadsCount: PropTypes.string,
  uploadsCost: PropTypes.string,
  rootClassName: PropTypes.string,
  triggersCost: PropTypes.string,
}

export default BillingOverviewCosts
