import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './sessiom-cookie-group-item.css';
import { useData } from '../contexts/DataContext';

const SessiomCookieGroupItem = (props) => {
  const { userData } = useData();
  const history = useHistory();

  const id = props.id;
  const cookieGroup = userData?.cookieGroups[id];
  const createdOn = new Date(parseInt(cookieGroup.created)).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' }) || '--.--.----';

  const handleEditClick = () => {
    history.push(`/session-cookie-create-group?id=${id}`);
  };

  return (
    <div className="sessiom-cookie-group-item-cookie-group-item">
      <div className="sessiom-cookie-group-item-container">
        <span>{cookieGroup.name}</span>
        <svg
          viewBox="0 0 1024 1024"
          className="sessiom-cookie-group-item-icon"
          onClick={handleEditClick}
        >
          <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
        </svg>
      </div>
      <div className="sessiom-cookie-group-item-container1">
        <div className="sessiom-cookie-group-item-container2">
          <div className="sessiom-cookie-group-item-container3">
            <span className="sessiom-cookie-group-item-text1">
              Create on: 
            </span>
            <span className="sessiom-cookie-group-item-text2">
              {createdOn}
            </span>
          </div>
          <div className="sessiom-cookie-group-item-container4">
            <span className="sessiom-cookie-group-item-text3">
            Cookies: 
            </span>
            <span className="sessiom-cookie-group-item-text4">
              {cookieGroup.cookies.length}
            </span>
          </div>
        </div>
        <div className="sessiom-cookie-group-item-container5">
          <span className="sessiom-cookie-group-item-text5">Used: </span>
          <span className="sessiom-cookie-group-item-text6">{cookieGroup.used}</span>
        </div>
      </div>
    </div>
  );
};

SessiomCookieGroupItem.defaultProps = {
  text: 'Group Name',
  text1: 'Used: ',
  text2: '296',
  text3: 'Create on:  ',
  text4: '01.01.2024',
  text5: 'Cookies:',
  text6: '111',
};

SessiomCookieGroupItem.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
};

export default SessiomCookieGroupItem;
