import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SessiomCookieGroupItem from './sessiom-cookie-group-item';
import './session-cookie-groups.css';
import { useData } from '../contexts/DataContext';

const SessionCookieGroups = (props) => {
  const { userData } = useData();
  const cookieGroups = userData?.cookieGroups || {};
  const isEmpty = Object.keys(cookieGroups).length === 0;

  return (
    <div className="session-cookie-groups-cookie-groups">
      <div className="session-cookie-groups-container">
        <div className="session-cookie-groups-container1">
          <span className="session-cookie-groups-text">{props.text}</span>
          <Link to="/session-cookie-create-group" className="session-cookie-groups-navlink">
            <div className="session-cookie-groups-container2">
              <svg viewBox="0 0 1024 1024" className="session-cookie-groups-icon">
                <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
              </svg>
              <span className="session-cookie-groups-text1">{props.text1}</span>
            </div>
          </Link>
        </div>
      </div>
      <div className={`session-cookie-groups-container3 ${isEmpty ? 'empty' : ''}`}>
        {Object.keys(cookieGroups).map((groupId) => (
          <SessiomCookieGroupItem id={groupId} key={groupId} />
        ))}
      </div>
    </div>
  );
};

SessionCookieGroups.defaultProps = {
  text: 'Cookie Groups',
  text1: 'Create Group',
};

SessionCookieGroups.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default SessionCookieGroups;
